// import axios from 'axios';
import React, { Component } from 'react';
import API_SERVICE from '../../utils/apiService';
import {
  HOSPITAL_CONFIG,
  LOADER_RED,
  SYNC_TOKEN,
  MYHC_APPTYPE,
  MHVCMARKETPLACE, USERTYPES,
  CALENDAR_PATIENT_LIST
} from '../../utils/constant';
import Moment from 'react-moment';
import moment from "moment";
import LS_SERVICE from '../../utils/localStorage';
import Axios from 'axios';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { DatePicker, Modal, Form, Input, Table } from "antd";
// import { InfinityTable as Table } from 'antd-table-infinity';
import { SearchOutlined} from '@ant-design/icons';
import SearchIcon from '../../assets/images/ic-search.svg';
import { Excel } from "antd-table-saveas-excel";
import ReactToPrint from "react-to-print";
class DashboardComponent extends Component {

  state = {
    slots: [],
    loading: false,
    hospitalsCount: 0,
    appointmentListModal: false,
    booking_list: [],
    total_bookings: 0,
    selected_hospital: null,
    appointment_load: false,
    currentPage: 1,
    filter_start_date: moment().format('YYYY-MM-DD'),
    filter_end_date: moment().format('YYYY-MM-DD'),
    filter_search: '',
    scrollPosition: 0,
    showDownloadPrint: false,
  }

  constructor(props) {
    super(props);

    if (LS_SERVICE.has('nurse_homecare') && LS_SERVICE.get('nurse_homecare')) {
      this.props.history.push('/patient-queue/' + LS_SERVICE.get('slot_hospital_id'));
    }
    else if (LS_SERVICE.get("user_type") == USERTYPES.superadmin || LS_SERVICE.get("user_type") == USERTYPES.admin) {
      this.props.history.push('/template-list')
    }
  }
  

  handleCancel = () => {
    this.setState({ 
      filter_start_date: moment().format('YYYY-MM-DD'),
      filter_end_date: moment().format('YYYY-MM-DD'),
      booking_list: [],
      total_bookings: 0,
      selected_hospital: null,
      appointment_load: false,
      currentPage: 1,
      appointmentListModal: false,
      filter_search: '',
    });
  }

  changeInputText = async (val) => {
    if(val.target.name == 'name'){
      this.setState({
        filter_search: val.target.value
      })
    }
  }

  
  changeDateValue = (val, type) => {
    if(type == 'startDate'){
      this.setState({
        filter_start_date: moment(val).format('YYYY-MM-DD')
      })
    }
    if(type == 'endDate'){
      this.setState({
        filter_end_date: moment(val).format('YYYY-MM-DD')
      })
    }
  }
  
  componentDidMount() {
    this.getStats();

    // if (this.props.history?.location?.state) {
    //   LS_SERVICE.clear();
    //   headerService$.showHeader({ showHeader: 'false' });
    // }

    if(LS_SERVICE.get("user_type") == USERTYPES.nurse) {
      this.props.history.push('/patient-queue/' + LS_SERVICE.get('slot_hospital_id'));
    }

    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  async getStats() {
    this.setState({
      loading: true
    });
    const syncData = await API_SERVICE.post(SYNC_TOKEN, { staff_id: LS_SERVICE.get('staff_id') });
    if (typeof syncData.slots != 'undefined') {
      let hospitalsCount = 0;
      if (syncData.slots.length > 0) {
        hospitalsCount = syncData.slots.length;
      }

      this.setState({
        slots: syncData.slots,
        hospitalsCount: hospitalsCount
      });

      // if (syncData.slots.length > 0) {
      //   for (const hospital of syncData.slots) {
      //     this.showHospitalCard(hospital, true);
      //   }
      // }
    }
    this.setState({
      loading: false
    });
  }

  // handleBasicOrSoap = (e, hospital_id) => {
  //   e.preventDefault();
  //   const PARAMS = {
  //     hospital_id,
  //     speciality_id: LS_SERVICE.get('speciality_id'),
  //     staff_id: LS_SERVICE.get('staff_id')
  //   }

  //   Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
  //   Axios.post(HOSPITAL_CONFIG, qs.stringify(PARAMS))
  //     .then(success => {
  //       headerService$.soapConfig({ soapConfig: success.data.data.opd_layout });
  //       LS_SERVICE.set('soapConfig', success.data.data.opd_layout);
  //       this.props.history.push('patient-queue/' + hospital_id);
  //     })
  //     .catch(error => console.log(error))
  // }

  showHospitalCard(hospital, increaseCount) {
    let status = false;
    if (MYHC_APPTYPE === MHVCMARKETPLACE) {
      if (
        (hospital.appointments.total_opd !== undefined && hospital.appointments.total_opd !== null) ||
        (hospital.appointments.total_vc !== undefined && hospital.appointments.total_vc !== null)
      ) {
        status = true;
      }
    } else {
      if (
        (hospital.appointments.total !== undefined && hospital.appointments.total !== null) ||
        (hospital.appointments.forwarded !== undefined && hospital.appointments.forwarded !== null)
      ) {
        status = true;
      }
    }

    if (status && increaseCount) {
      this.setState({
        hospitalsCount: this.state.hospitalsCount + 1
      });
    }
    return status;
  }

  opdCards() {
    const columns = [
      {
        title: 'S.NO',
        dataIndex: 'sno',
        width:'7%',
      },
      {
        title: 'TYPE',
        dataIndex: 'type',
        width:'10%',
        filters: [
          {
            text: <span>OPD</span>,
            value: '2',
          },
          {
            text: <span>VC</span>,
            value: '1',
          },
        ],
        // onFilter: (value, record) => record.type.startsWith(value),
      },
      {
        title: 'PATIENT',
        dataIndex: 'patient',
        width:'28%',
      },
      {
        title: 'UHID',
        dataIndex: 'uhid',
        width:'12%',
      },
      {
        title: 'DATE',
        dataIndex: 'date',
        width:'13%',
      },
      {
        title: 'TIME',
        dataIndex: 'time',
        width:'10%',
      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        width:'10%',
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
        width:'10%',
        className:'status',
        // filters: [
        //   {
        //     text: <span>Confirmed</span>,
        //     value: 1,
        //   },
        //   {
        //     text: <span>Completed</span>,
        //     value: 5,
        //   },
        //   {
        //     text: <span>Missed</span>,
        //     value: 6,
        //   },
        //   {
        //     text: <span>Canceled</span>,
        //     value: 3,
        //   },
        // ],
      },

    ];

    const { slots } = this.state;
    const {Search} = Input
    const handleClick = () => {
      const excel = new Excel();
      excel
        .addSheet("AppointmentList")
        .addColumns(columns)
        .addDataSource(this.state.booking_list, {
          str2Percent: true
        })
        .saveAs("AppointmentList.xlsx");
    };
    return (
      <>
        {slots.map((slot, index) => (
          <div id="hospital_card" className="col-md-4" key={index}>
              <div className="card hospital-card shadow-box">
                <div className="card-body dashboard-hospital-card">
                  <div className='row'>
                    <div className='col-auto'>
                      <button className='no-style total_appts_button' onClick={e => this.handleAppointmentList(e, slot.id)}>
                        <div className='btn btn-outline-primary cus_pad small text-uppercase'>TOTAL APPTS</div>
                        <div className="text-center pt-1 text-secondary bold_appts">{slot?.appointments?.total ? slot?.appointments?.total : 0}</div>
                      </button>
                    </div>
                    <div className='col text-right'>
                      <h4>{slot.name}</h4>
                      <p>
                        &nbsp;
                        {slot.start_time != null ? <Moment parse="HH:mm A" date={slot.start_time} format="h:mm A" /> : null}
                        {slot.start_time != null && slot.end_time != null ? ' to ' : null}
                        {slot.end_time != null ? <Moment parse="HH:mm A" date={slot.end_time} format="h:mm A" /> : null}
                      </p>
                    </div>
                  </div>
                  <a href="!#" className="nostyle-link " onClick={e => this.handleDashboardNav(e, slot.id)}> </a>
                  <div className="text-center pt-3">
                    {
                      MYHC_APPTYPE === MHVCMARKETPLACE 
                    ? 
                      <div className="row">
                        <div className={slot.is_doctor_homecare_active ? "col-3" : "col-4"}>
                          <div>OPD</div>
                          <div className="col-12 display-2">{slot?.total_worklist_opd !== null ? slot?.total_worklist_opd : 0}</div>
                        </div>
                        <div className={slot.is_doctor_homecare_active ? "col-3" : "col-4"}>
                          <div>VC</div>
                          <div className="col-12 display-2">{slot.total_worklist_vc !== undefined ? slot.total_worklist_vc : 0}</div>
                        </div>
                        {
                          slot.is_doctor_homecare_active 
                        ?
                          <div className="col-3">
                            <div>HomeCare</div>
                            <div className="col-12 display-2">{slot.total_homecare_appointments !== undefined ? slot.total_homecare_appointments : 0}</div>
                          </div>
                        : 
                          null
                        }
                        <div className="col-3">
                        <div>Completed</div>
                          <div className="col-12 display-2">{slot?.total_completed_appointments ? slot?.total_completed_appointments : 0}</div>
                        </div>
                      </div> 
                    : 
                      <div className="row">
                        <div className="col-6">
                          Appointments
                          <div className="col-12 display-2">{slot.appointments.total !== null ? slot.appointments.total : 0}</div>
                        </div>
                        <div className="col-6">
                          Arrived
                          <div className="col-12 display-2">{slot.appointments.forwarded !== undefined ? slot.appointments.forwarded : 0}</div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            {/* </Link> */}
          </div>
        ))}

        {/* ##########  Appointment List  Modal ######## */}
        <Modal
          title='Appointment List'
          visible={this.state.appointmentListModal}
          onCancel={() => this.handleCancel()}
          footer={false}
          width="1080px"
          className='appointment_modal'
        >
          <Form
            className='emrfrm '
          >
            <div className='row d-flex align-items-center'>
              {/* <div className='col-6'>
                <div class="form-group hasdata mb-0">
                  <input name="name" type="text" id="search_by" class="form-control" placeholder="Patient Name/Mobile/UHID" maxlength="100" autocomplete="off" onChange={this.changeInputText}/>
                  <img src={SearchIcon} alt="icon" className="dashboard_search_image" />
                  <label class="required" for="search_by">Patient Name/Mobile/UHID</label>
                </div>
              </div> */}
              <div className='col-2'>
                <div className='antInputItem hasdata'>
                  <Form.Item
                    name="start_date"
                    className='mb-0 dashboard_popupdate'
                  >
                    <DatePicker
                      name="start_date"
                      format="DD MMM, YYYY"
                      defaultValue={moment()}
                      placeholder='Start Date'
                      className='mb-0'
                      style={{
                        width: 100 + "%",
                        height: 44 + "px",
                      }}
                      onChange={date => this.changeDateValue(date, 'startDate')}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='col-2'>
                <div className='antInputItem hasdata'>
                  <Form.Item
                    name="end_date"
                    className='mb-0 dashboard_popupdate'
                  >
                    <DatePicker
                      name="end_date"
                      format="DD MMM, YYYY"
                      defaultValue={moment()}
                      placeholder='End Date'
                      className='mb-0'
                      style={{
                        width: 100 + "%",
                        height: 44 + "px",
                      }}
                      onChange={date => this.changeDateValue(date, 'endDate')}
                    />
                  </Form.Item>
                </div>      
              </div>
              <div className='col-2 text-right'>
              <button className="btn btn-outline-primary px-4 py-2 small text-uppercase" onClick={() => this.searchAppointment()}>SEARCH</button>
              </div>
              
            </div>
            <div className='mt-3 mb-3 px-0 container appoint_list_container'>
              {/* <Table 
              ref={el => (this.componentRef = el)} 
              columns={columns} 
              dataSource={this.state.booking_list} 
              loading={this.state.appointment_load} 
              onChange={(pagination, filter, sorter) => this.onChangePage(pagination, filter, sorter)}
              pageSize={10}
              loadingIndicator={this.loadMoreContent()}
              scroll={{ y: 390 }}
              className="moreload"
              /> */}
              <Table 
              ref={el => (this.componentRef = el)} 
              columns={columns} 
              dataSource={this.state.booking_list} 
              loading={this.state.appointment_load} 
              pagination = {false}
              // pagination={{ current:this.state.currentPage, total:this.state.total_bookings, position: ['bottom','right'] }} 
              onChange={(pagination, filter, sorter) => this.onChangePage(pagination, filter, sorter)}
              />


            </div>
            {/* {this.state.showDownloadPrint? */}
            {this.state.booking_list?.length > 0?
            <div className='row pt-4 '>
              <div className='col-12 text-center'>
                 <a className="btn btn-outline-secondary px-3 small text-uppercase mr-2" onClick={handleClick}>DOWNLOAD</a>
                 <ReactToPrint
                    trigger={() => (
                      <a className="btn btn-primary px-3 small text-uppercase ml-2" href="#">
                        PRINT
                      </a>
                    )}
                    content={() => this.componentRef}
                  />
              </div>
            </div>
            : null}
          </Form>
        </Modal>
      </>
    )
  }

  handleScroll = (evt) => {
    evt.preventDefault();

    let {paginationData, scrollPosition, currentPage} = this.state;
    let scrollTop = document.querySelector('div.ant-table-body')?.scrollTop;
    let current_page = paginationData?.current_page + 1;
    let total_pages = paginationData?.total_pages;

    if(scrollPosition < scrollTop && currentPage < current_page && currentPage < total_pages){
      this.setState({ scrollPosition: scrollTop });
      var pagination = {current: current_page};
      var filter = {}
      var sorter = {}
      this.onChangePage(pagination, filter, sorter)
    }

    if(currentPage == total_pages){
      this.setState({showDownloadPrint: true});
    }
  }

  searchAppointment(){
    this.setState({ scrollPosition: 0 });
    var pagination = {current: 1};
    var filter = {}
    var sorter = {}
    this.onChangePage(pagination, filter, sorter)
  }

  async onChangePage(pagination, filter, sorter){
    if(this.state.selected_hospital){
      pagination = (pagination.current == undefined)? {current: 1} : pagination;

      await this.setState({
        appointment_load: {indicator: LOADER_RED},
        currentPage: pagination.current
      })
      const PARAMS = {
        from: this.state.filter_start_date,
        to: this.state.filter_end_date,
        hospital_id: this.state.selected_hospital,
        // page: pagination.current,
        page: 1,
        booking_status: 0
      }
      // if(extras.filter_search && extras.filter_search.trim() != ''){
      //   PARAMS.filter_search = this.state.filter_search
      // }

 
      if(filter?.status?.length > 0){
        PARAMS.booking_status = filter?.status
      }

      PARAMS.consult_type = [];
      if(filter?.type?.length > 0){
        PARAMS.consult_type = filter?.type
      }

      var total_pages = 0;
      var success = await Axios.post(CALENDAR_PATIENT_LIST, qs.stringify(PARAMS));
      // .then(success => {
        // var data = (pagination.current == 1)? [] : this.state.booking_list;
        var data = []
        // var i= (pagination.current == 1)? 1 : this.state.booking_list.length + 1;
        var i = 1;
        for (var booking_data of success.data.appointments) {
          console.log(booking_data)
          data.push({
            key: i,
            sno: i,
            type: booking_data.consult_type == 1 ? 'VC' : 'OPD',
            patient: booking_data?.patient?.full_name,
            uhid: booking_data?.patient?.uhid ? booking_data?.patient?.uhid : '-',
            date: booking_data.booking_date ? moment(booking_data.booking_date).format('D MMM, YYYY') : '-',
            time: booking_data.booking_time ? moment(booking_data.booking_date+' '+booking_data.booking_time).format('h:mm A') : '-', //'10:20 AM',
            mobile: booking_data?.patient?.mobile_no ? booking_data?.patient?.mobile_no : '-',
            // status: booking_data?.booking_status_code == 5 ? <span className='text-success'>{booking_data.booking_status}</span> : (booking_data?.booking_status_code == 3 ? <span className='text-danger'>{booking_data.booking_status}</span> : booking_data.booking_status)
            status: booking_data.booking_status
          })
          i++;
        }
        if(success.data?.pagination?.total_pages > 1){
          var k=2;
          var next_booking_data = data;
          while(k <= success.data?.pagination?.total_pages){
            PARAMS.page = k;
            var next_booking = await Axios.post(CALENDAR_PATIENT_LIST, qs.stringify(PARAMS));
            for (var booking_data of next_booking.data.appointments) {
              next_booking_data.push({
                key: i,
                sno: i,
                type: booking_data.consult_type == 1 ? 'VC' : 'OPD',
                patient: booking_data?.patient?.full_name,
                uhid: booking_data?.patient?.uhid ? booking_data?.patient?.uhid : '-',
                date: booking_data.booking_date ? moment(booking_data.booking_date).format('D MMM, YYYY') : '-',
                time: booking_data.booking_time ? moment(booking_data.booking_date+' '+booking_data.booking_time).format('h:mm A') : '-', //'10:20 AM',
                mobile: booking_data?.patient?.mobile_no ? booking_data?.patient?.mobile_no : '-',
                // status: booking_data?.booking_status_code == 5 ? <span className='text-success'>{booking_data.booking_status}</span> : (booking_data?.booking_status_code == 3 ? <span className='text-danger'>{booking_data.booking_status}</span> : booking_data.booking_status)
                status: booking_data.booking_status
              })
              i++;
            }
            k++
          }
          console.log(next_booking_data);
          await this.setState({
            booking_list: next_booking_data,
            paginationData: success.data?.pagination,
            total_bookings: success.data?.pagination?.total || data.length,
            appointment_load: false,
          })
        }
        else{
          await this.setState({
            booking_list: data,
            paginationData: success.data?.pagination,
            total_bookings: success.data?.pagination?.total || data.length,
            appointment_load: false,
          })
        }
      // })
      // .catch(error => console.log(error))
    }
  }

  noHospitals() {
    return (
      <>
        {this.state.loading ? <div className="col-md-12 text-center">
          {LOADER_RED}
        </div> : <div className="col-md-12">
          <a href="#" className="nostyle-link">
            <div className="card hospital-card shadow-box">
              <div className="card-body">
                <div className="text-center">
                  <h4>No hospitals associated with {LS_SERVICE.get('user_type') == USERTYPES.nurse ? 'nurse' : 'doctor'}</h4>
                </div>
              </div>
            </div>
          </a>
        </div>}
      </>
    )
  }

  handleDashboardNav = (e, hid) => {
    e.preventDefault();
    LS_SERVICE.delete('activeTab');
    this.props.history.push(`/patient-queue/${hid}`);
  }

  handleAppointmentList = async (e, hid) => {
    this.setState({
      appointmentListModal: true,
      appointment_load: {indicator: LOADER_RED},
      scrollPosition: 0
    })

    const PARAMS = {
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      hospital_id: hid,
      booking_status: 0,
      page: 1,
    }

    var success = await Axios.post(CALENDAR_PATIENT_LIST, qs.stringify(PARAMS))
    // .then(success => {
      var data = [];
      var i=1;
      for (var booking_data of success.data.appointments) {
        data.push({
          key: i,
          sno: i,
          type: booking_data.consult_type == 1 ? 'VC' : 'OPD',
          patient: booking_data?.patient?.full_name,
          uhid: booking_data?.patient?.uhid ? booking_data?.patient?.uhid : '-',
          date: booking_data.booking_date ? moment(booking_data.booking_date).format('D MMM, YYYY') : '-',
          time: booking_data.booking_time ? moment(booking_data.booking_date+' '+booking_data.booking_time).format('h:mm A') : '-',
          mobile: booking_data?.patient?.mobile_no ? booking_data?.patient?.mobile_no : '-',
          // status: booking_data?.booking_status_code == 5 ? <span className='text-success'>{booking_data.booking_status}</span> : (booking_data?.booking_status_code == 3 ? <span className='text-danger'>{booking_data.booking_status}</span> : booking_data.booking_status)
          status: booking_data.booking_status
        })
        i++;
      }
      if(success.data?.pagination?.total_pages > 1){
        var k=2;
        var next_booking_data = data;
        while(k <= success.data?.pagination?.total_pages){
          PARAMS.page = k;
          var next_booking = await Axios.post(CALENDAR_PATIENT_LIST, qs.stringify(PARAMS));
          for (var booking_data of next_booking.data.appointments) {
            next_booking_data.push({
              key: i,
              sno: i,
              type: booking_data.consult_type == 1 ? 'VC' : 'OPD',
              patient: booking_data?.patient?.full_name,
              uhid: booking_data?.patient?.uhid ? booking_data?.patient?.uhid : '-',
              date: booking_data.booking_date ? moment(booking_data.booking_date).format('D MMM, YYYY') : '-',
              time: booking_data.booking_time ? moment(booking_data.booking_date+' '+booking_data.booking_time).format('h:mm A') : '-', //'10:20 AM',
              mobile: booking_data?.patient?.mobile_no ? booking_data?.patient?.mobile_no : '-',
              // status: booking_data?.booking_status_code == 5 ? <span className='text-success'>{booking_data.booking_status}</span> : (booking_data?.booking_status_code == 3 ? <span className='text-danger'>{booking_data.booking_status}</span> : booking_data.booking_status)
              status: booking_data.booking_status
            })
            i++;
          }
          k++
        }
        await this.setState({
          booking_list: next_booking_data,
          total_bookings: success.data?.pagination?.total || data.length,
          selected_hospital: hid,
          appointment_load: false,
          currentPage: 1
        })
      }
      else{
        await this.setState({
          booking_list: data,
          total_bookings: success.data?.pagination?.total || data.length,
          selected_hospital: hid,
          appointment_load: false,
          currentPage: 1
        })
      }
    // })
    // .catch(error => console.log(error))
  }

  loadMoreContent = () => (
    <div
      style={{
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 20,
        border: '1px solid #e8e8e8',
      }}
    >
    {LOADER_RED}
    </div>
  );

  render() {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-11">
            <div className="emr-dashboard mt-2" id="dashboard_data">

              <section className="section">
                {this.state.hospitalsCount > 0 ? <h2 className="header mb-2">Appointments</h2> : null}

                <div className="row">
                  {this.state.hospitalsCount > 0 && !this.state.loading ? this.opdCards() : this.noHospitals()}
                </div>
              </section>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DashboardComponent;