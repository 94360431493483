import React, { Component, createRef } from 'react';
import Axios from 'axios';
import qs from 'qs';
import {
  HANDLEVALIDANTMESSAGES, HOMECARE_DETAIL, HOMECARE_STORE_TEST_NOTES, HOSPITALDEFAULTS, LOADER_BLACK, OBJECTIVE_GETALL,
  SEPARATEUPLOADEDDOCSNEW, LOADER, BMI_CALCULATE, HOMECARE_SEND_PRESCRIPTION, VITALS_GRAPH, OBJECTIVE_VITAL_STORE, HOMECARE_REFERENCE_TYPE, USERTYPES, LOADER_RED, HOMECARE_SAVE_INTERNAL_NOTES, PDF_CONFIG_VITALS_GRAPH, REFERENCE_TYPE, DISABLED_FUTURE_DATES
} from '../../../utils/constant';
// import icJpg from '../../../assets/images/ic-jpg.png';
// import icPdf from '../../../assets/images/ic-pdf.png';
import AsideLeft from '../../../components/aside/asideleft';
import LS_SERVICE from '../../../utils/localStorage';
import { Modal, Carousel, Collapse, Select, Button, Form, Input, Checkbox, notification, Badge, DatePicker, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Vitals from '../../../components/vitals/vitals';
import { jQueryLabels, FixedCta, AntSelectLabels, AntClearLabels, AntDisabledLabels, onlynumbers } from '../../../utils/jQueryLabels';
import Chart from '../../../components/chart/chart';
import ChartPastVisit from '../../../components/chart/chart-past-visit';
import { speechService, speechTextService } from '../../../utils/rxjs-sharing';
import MyHcSpeechRecognition from '../../speech-to-text/speech-recognition';

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const notes_prev_common = {
  count: 0,
  date: "",
  notes: "",
  notes_by: "",
  notes_by_name: ""
}
class HomecareDetailComponent extends Component {

  constructor(props) {
    super(props)
    this.ENDCALL = "";
    this.carouselRef = createRef();
    this.vitalFormRef = createRef();
  }

  state = {
    patient: null,
    is_called_patient: this.props.location.state?.isCallToday || 0,
    is_doctor: true,
    reference_id: null,
    patient_id: null,
    hospital_id: null,
    appointment_status: null,
    appointment_details: [],
    patient_docments: [],
    patient_note_doctor: null,
    notes_nurse_prev: notes_prev_common,
    notes_nurse: "",
    notes_doctor_prev: notes_prev_common,
    notes_doctor: "",
    medicines_tests_prev: notes_prev_common,
    medicines_tests: "",
    internal_notes: "",
    details: null,
    constants: null,
    homecareVitals: null,
    patientHeight: null,
    patientWeight: null,
    visitVitals: [],
    visitVitalsFormatted: [],
    dataLoaded: false,
    vitalsType: '0',
    vitalGroups: [],
    selectedVitalGroups: [],
    displayVitals: [],
    vitalNotInRange: [],
    choosePrescriptionModal: false,
    sendPrescriptionModal: false,
    callRatingModal: false,
    send_progress_notes_nurse: 0,
    send_progress_notes_doctor: 0,
    send_patient_vitals: 0,
    send_notes_for_doctor: 0,
    send_medicine_test: 0,
    QBEndCall: false,
    isFormProcessing: false,
    isSendPrescriptionProcessing: false,
    chartData: {},
    vitalsRange: {},
    chartGraphSettings: '',
    chartDataLoaded: false,
    chartsTab: '2',
    chartsType: 'all',
    chartHeading: 'Vitals Trend',
    chartsTabSet: false,
    spin_loading: false,
    defaultPanelOpened: ['1'],
    speech: {
      identifier: null,
      state: false
    },
    range: [],
    bloodSugarChartData: null,
    vitalsRangeWithId: null
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {

    const { match: { params } } = nextProps;
    const { reference_id, hospital_id, patient_id } = params;

    if (prevState.reference_id !== null && reference_id !== prevState.reference_id) {
      return {
        patient_id: patient_id,
        reference_id: reference_id,
        hospital_id: hospital_id
      }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.reference_id !== this.state.reference_id) {
      this.componentDidMountFn();
    }
  }

  componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

    this.componentDidMountFn();
    jQueryLabels();
    FixedCta();
    onlynumbers();
  }

  componentWillUnmount() {
    if (this.ENDCALL !== "") {
      clearInterval(this.ENDCALL);
    }
  }

  componentDidMountFn = () => {
    const { match: { params } } = this.props;
    const { reference_id, patient_id, hospital_id } = params;

    this.setState({
      dataLoaded: false
    })

    if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
      this.setState({
        defaultPanelOpened: ['3'],
        is_doctor: false
      })
    }

    if (this.ENDCALL !== "") {
      clearInterval(this.ENDCALL);
    }

    let PARAMS = [];

    const homeDetailUri = Axios.get(HOMECARE_DETAIL({ booking_id: reference_id, is_called_patient: this.state.is_called_patient }));
    const getAllDataUri = Axios.get(OBJECTIVE_GETALL({ patient_id }));
    const vitalGraphUri = Axios.get(VITALS_GRAPH({ patient_id }));
    const vitalPDFVitalGraphUri = Axios.get(PDF_CONFIG_VITALS_GRAPH({ reference_type: HOMECARE_REFERENCE_TYPE, reference_id }));

    PARAMS = [homeDetailUri, getAllDataUri, vitalGraphUri];

    if (LS_SERVICE.get('user_type') != USERTYPES.nurse) {
      PARAMS.push(vitalPDFVitalGraphUri);
    }

    Axios.all([...PARAMS])
      .then(success => {
        const { appointment } = success[0].data;
        const { patient, patient_docments, patient_note_doctor } = appointment;
        const { data } = success[1].data;
        const { details, constants } = data;
        const dataGraph = success[2].data;
        let vitalPDFVitalGraphData;
        if (LS_SERVICE.get('user_type') != USERTYPES.nurse) {
          vitalPDFVitalGraphData = success[3].data;
        }

        const docs = SEPARATEUPLOADEDDOCSNEW(patient_docments);
        patient.age = moment().diff(patient.dob, 'years');

        const homecareVitals = constants ? (constants.vital_groups['VG003'] ? constants.vital_groups['VG003'].vital_sign : constants.vital_details) : null;

        if (LS_SERVICE.get('user_type') != USERTYPES.nurse) {
          this.setState({
            chartGraphSettings: vitalPDFVitalGraphData.data.details !== null ? vitalPDFVitalGraphData.data.details.graph_settings : '',
          })
        }
        
        this.setState({
          patient,
          reference_id,
          patient_id,
          hospital_id,
          appointment_status: appointment.booking_status_code,
          appointment_details: appointment,
          patient_docments: docs,
          patient_note_doctor,
          notes_doctor_prev: appointment.notes_by_doctor !== null ? { ...appointment.notes_by_doctor } : notes_prev_common,
          notes_nurse_prev: appointment.notes_by_nurse !== null ? { ...appointment.notes_by_nurse } : notes_prev_common,
          medicines_tests_prev: appointment.medicine_test_advice !== null ? { ...appointment.medicine_test_advice } : notes_prev_common,
          notes_doctor: LS_SERVICE.get("notes_doctor_" + patient_id + "_" + reference_id) !== null ? LS_SERVICE.get("notes_doctor_" + patient_id + "_" + reference_id) : '',
          notes_nurse: LS_SERVICE.get("notes_nurse_" + patient_id + "_" + reference_id) !== null ? LS_SERVICE.get("notes_nurse_" + patient_id + "_" + reference_id) : '',
          medicines_tests: LS_SERVICE.get("medicines_tests_" + patient_id + "_" + reference_id) !== null ? LS_SERVICE.get("medicines_tests_" + patient_id + "_" + reference_id) : '',
          details,
          visitVitals: Object.values(details.visitVitals),
          constants,
          homecareVitals,
          chartData: dataGraph.details,
          bloodSugarChartData: dataGraph.blood_sugar_data,
          vitalsRange: Object.values(dataGraph.constants.range),
          vitalsRangeWithId: dataGraph.constants.range,
          dataLoaded: true,
          chartDataLoaded: true,
        }, this.makeVitalsData)
      })

    this.subscription = speechTextService.status().subscribe(data => {
      if (this.state.speech.state) {
        if (this.state.speech.identifier === 'notes-doctor') {
          this.setState({ notes_doctor: data.text })
          // , () => { this.handleConfirmBtnStatus() });
          LS_SERVICE.set("notes_doctor_" + patient_id + "_" + reference_id, data.text);
        }
        if (this.state.speech.identifier === 'medicines-tests') {
          this.setState({ medicines_tests: data.text })
          // , () => { this.handleConfirmBtnStatus() });
          LS_SERVICE.set("medicines_tests_" + patient_id + "_" + reference_id, data.text);
        }
        if (this.state.speech.identifier === 'notes-nurse') {
          this.setState({ notes_nurse: data.text })
          // , () => { this.handleConfirmBtnStatus() });
          LS_SERVICE.set("notes_nurse_" + patient_id + "_" + reference_id, data.text);
        }
        if (this.state.speech.identifier === 'internal-notes') {
          this.setState({ internal_notes: data.text })
          // , () => { this.handleConfirmBtnStatus() });
          LS_SERVICE.set("internal_notes_" + patient_id + "_" + reference_id, data.text);
        }
        // document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
      }
    });
  }

  handleDateRangeGraphData = async (reset = false) => {
    const { patient_id, range } = this.state;

    const PARAMS = {
      patient_id,
      start_date: !reset ? moment(range[0]._d).format('YYYY-MM-DD HH:mm:ss') : undefined,
      end_date: !reset ? moment(range[1]._d).format('YYYY-MM-DD HH:mm:ss') : undefined
    }

    const vitalGraphRangePromise = await Axios.get(VITALS_GRAPH(PARAMS))

    if (vitalGraphRangePromise.data.status === "success") {

      const { data } = vitalGraphRangePromise;

      this.setState({
        chartDataLoaded: false,
        chartData: data.details,
        bloodSugarChartData: data.blood_sugar_data,
        vitalsRange: Object.values(data.constants.range),
      }, () => this.setState({
        chartDataLoaded: true,
      }))
    }

  }

  handleObjectiveGraphData = () => {

    const { patient_id } = this.state;

    const getAllDataUri = Axios.get(OBJECTIVE_GETALL({ patient_id }));
    const vitalGraphUri = Axios.get(VITALS_GRAPH({ patient_id }))

    Axios.all([getAllDataUri, vitalGraphUri])
      .then(success => {

        const { data } = success[0].data;
        const { details, constants } = data;
        const dataGraph = success[1].data;

        this.setState({
          details,
          visitVitals: Object.values(details.visitVitals),
          constants,
          chartData: dataGraph.details,
          bloodSugarChartData: dataGraph.blood_sugar_data,
          vitalsRange: Object.values(dataGraph.constants.range),
          vitalsRangeWithId: dataGraph.constants.range,
          dataLoaded: true,
          chartDataLoaded: true,
        }, () => {
          this.makeVitalsData();
        })
      })
  }

  makeVitalsData = _ => {
    let vitalDetails = [];

    let { patient, patientHeight, patientWeight } = this.state;

    let vital_data = {}
    let allVitalIds = [];
    this.state.homecareVitals.map((v, k) => {
      allVitalIds.push(v.id);
      vitalDetails[v.id] = [];
      vital_data[v.id] = null;
    });

    vitalDetails['date'] = [];
    vitalDetails['source'] = [];
    vitalDetails['update_date'] = [];
    vitalDetails['source_name'] = [];
    allVitalIds.push('date');
    allVitalIds.push('source');
    allVitalIds.push('update_date');
    allVitalIds.push('source_name');

    if (Object.keys(this.state.visitVitals).length > 0) {
      let index = 0;
      let index2 = 0;
      if (index <= 3) {
        this.state.visitVitals.map((temp_vitals, k) => {
          if (index2 < 3) {
            // Object.keys(temp_vitals).forEach(j => {
            allVitalIds.forEach(j => {
              if (vitalDetails[j]) vitalDetails[j][index] = temp_vitals[j] ?? '';
              if (j == 'V001' && patientHeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientHeight = temp_vitals[j];
              if (j == 'V002' && patientWeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientWeight = temp_vitals[j];
            });
            index2++
          }
          index++;
        });
      }
    }

    let vitals_data = {};
    let newVitalEmptyArray = vitalDetails['date'] ? Array.from({ length: vitalDetails['date'].length }, i => '') : [];
    this.state.homecareVitals.map((v, k) => {
      vitals_data[v.id] = vitalDetails[v.id] && vitalDetails[v.id].length > 0 ? vitalDetails[v.id] : newVitalEmptyArray;
    });
    vitals_data.date = vitalDetails['date'];
    vitals_data.source = vitalDetails['source'];
    vitals_data.update_date = vitalDetails['update_date'];
    vitals_data.source_name = vitalDetails['source_name'];

    //vitals groups
    let vitalGroupData = [];
    const doctorSpeciality = LS_SERVICE.get('speciality_id');
    let allVital = null;
    for (const vitalGroup of Object.values(this.state.constants.vital_groups)) {
      if (vitalGroup.speciality_id == doctorSpeciality) {
        var vitalSignIds = [];
        for (const vitalSign of Object.values(vitalGroup.vital_sign)) {
          vitalSignIds.push(vitalSign.vital_id);
        }
        vitalGroup.vitalSignIds = vitalSignIds.toString().replace(/(^,)|(,$)/g, "");
        vitalGroupData.push(vitalGroup);
      }
      if (vitalGroup.code == 'VG003') {
        var vitalSignIds2 = Object.values(vitalGroup.vital_sign).map(vs => vs.vital_id);
        vitalGroup.vitalSignIds = vitalSignIds2.toString().replace(/(^,)|(,$)/g, "");
        allVital = vitalGroup;
      }
    }
    if (vitalGroupData.length == 0)
      vitalGroupData.push(allVital);

    this.setState({
      selectedVitalGroups: vitalGroupData,
      vitalsType: vitalGroupData[0].id,
      displayVitals: vitalGroupData[0].vitalSignIds.split(','),
      patientHeight,
      patientWeight,
      ...vital_data,
      V001: patientHeight,
      V002: patientWeight,
      visitVitalsFormatted: vitals_data,
      vitals_data_formatted: true
    })
  }

  handleModalPopup = (e, popupName, index) => {
    if (e !== null) e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    }, () => {
      const { viewDocumentModal, vitalModal } = this.state;

      if (viewDocumentModal) {
        this.carouselRef.current.goTo(index);
      }

      if (!vitalModal) {
        this.state.homecareVitals.map((v, k) => {
          this.setState({ [v.id]: null });
        });

        if (this.vitalFormRef.current !== null) {
          this.vitalFormRef.current.resetFields();
        }

      } else {
        let { patientHeight, patientWeight, patient } = this.state;

        if (patient.age > HOSPITALDEFAULTS.age) {
          if (this.vitalFormRef.current !== null) {

            this.vitalFormRef.current.setFieldsValue({
              V001: patientHeight,
              V002: patientWeight
            });
            this.handleBmiCalculate();
          }
        }
        AntSelectLabels();
      }
    })
  }

  handleOnChange = e => {
    const { name, value } = e.target;
    const { patient_id, reference_id } = this.state;
// console.log('name>>>', name);
// console.log('value>>>', value);
    if (name === 'notes_doctor' || name === 'notes_nurse' || name === 'medicines_tests')
      LS_SERVICE.set(name + "_" + patient_id + "_" + reference_id, value);

    this.setState({
      [name]: value
    })
  }

  handleSave = (e, type) => {
    e !== null && e.preventDefault();
    this.setState({ isFormProcessing: true });

    const { notes_doctor, notes_nurse, medicines_tests, is_doctor, reference_id, patient_id } = this.state;
    const notesData = is_doctor ? notes_doctor : notes_nurse;

    const PARAMS = {
      booking_id: reference_id,
    }
    //console.log("notesData>>", notesData);

    if (type === 'storetestnotes') {
      PARAMS.notes_by = is_doctor ? 3 : 2; // 3 if doctor logged in 2 when nurse and nothing for medicine and test advised
      PARAMS.type = 'test-notes';
      PARAMS.notes_to_patient = notesData;
    } else {
      PARAMS.type = 'medicine-notes';
      PARAMS.medicine_advice = medicines_tests;
    }

    Axios.post(HOMECARE_STORE_TEST_NOTES, PARAMS)
      .then(success => {

        const { data } = success.data;
        const { notes_doctor_prev, notes_nurse_prev, medicines_tests_prev } = this.state;

        if (type === "storetestnotes") {
          if (is_doctor) {
            Object.assign(notes_doctor_prev, data);
          } else {
            Object.assign(notes_nurse_prev, data);
          }
        } else {
          Object.assign(medicines_tests_prev, data);
        }

        this.setState({
          notes_doctor_prev,
          notes_nurse_prev,
          medicines_tests_prev,
          notes_doctor: "",
          notes_nurse: "",
          medicines_tests: "",
          isFormProcessing: false,
          added_progress_data: true,
        })
        
        if (is_doctor) {
          LS_SERVICE.delete("notes_doctor_" + patient_id + "_" + reference_id);
        } else {
          LS_SERVICE.delete("notes_nurse_" + patient_id + "_" + reference_id);
        }
      })
  }

  handleInternalNotesSave = (e) => {
    e.preventDefault();
    this.setState({ isFormProcessing: true });

    const { internal_notes, patient_id, reference_id } = this.state;

    const PARAMS = {
      patient_id,
      booking_id: reference_id,
      internal_notes
    }

    Axios.post(HOMECARE_SAVE_INTERNAL_NOTES, PARAMS)
      .then(success => {

        this.setState({
          internal_notes: "",
          isFormProcessing: false
        })

        notification.success({
          message: success.data.message,
          placement: 'topRight'
        })
      })
  }

  handleOnChangeNumbers = (event, min, max) => {
    let { name, value } = event.currentTarget;
    let vitalNotInRange = this.state.vitalNotInRange;
    value = value.replace(name !== 'V004' ? /\D/ : /[^0-9.]/g, "");
    var index = vitalNotInRange.indexOf(name);
    if (parseFloat(value) < min || parseFloat(value) > max) {
      index == -1 && vitalNotInRange.push(name);
    } else {
      index != -1 && vitalNotInRange.splice(index, 1);
    }

    this.setState({
      [name]: value,
      vitalNotInRange,
    }, () => {
      this.vitalFormRef.current.setFieldsValue({
        [name]: value
      })
      this.handleBmiCalculate();
    })
  }

  handleBmiCalculate = () => {
    let { V001, V002, patientHeight, patientWeight } = this.state;

    V001 = V001 !== null ? V001 : patientHeight;
    V002 = V002 !== null ? V002 : patientWeight;

    if (V001 > 0 && V002 > 0) {
      let val = BMI_CALCULATE(V001, V002);
      this.setState({
        V003: val
      }, () => { AntSelectLabels() })

      this.vitalFormRef.current.setFieldsValue({
        V003: val
      })

      AntSelectLabels();
    } else {
      this.setState({
        V003: ''
      }, () => { AntSelectLabels() })

      this.vitalFormRef.current.setFieldsValue({
        V003: ''
      })

      AntSelectLabels();
    }
  }

  handleVitalSelect = (val, prop) => {
    let displayVitals = prop.datavitals.split(',');
    this.setState({

      vitalsType: val,
      displayVitals: displayVitals
    })
    AntSelectLabels();
  }

  handlePrescriptionCheckbox = e => {
    const { name, checked } = e.target;

    this.setState({
      [name]: checked ? 1 : 0
    })
  }

  completeAppointment = _ => {

    this.setState({
      isFormProcessing: true,
      isSendPrescriptionProcessing: true
    })

    const { medicines_tests, send_medicine_test, send_progress_notes_doctor, send_progress_notes_nurse,
      send_patient_vitals, send_notes_for_doctor, is_doctor, reference_id, patient_id, hospital_id } = this.state;

    const booking_id = reference_id;

    if (this.ENDCALL !== "") {
      clearInterval(this.ENDCALL);
      this.ENDCALL = "";
    }

    const PARAMS = {
      booking_id,
      hospital_id,
      patient_id,
      medicine_test_advice: medicines_tests,
      has_to_send_medicine_advice: send_medicine_test,
      has_to_send_progress_notes: send_progress_notes_doctor,
      has_to_send_patient_vital: send_patient_vitals,
      has_to_send_notes_for_doctor: send_notes_for_doctor,
      has_to_send_progress_nurse_notes: send_progress_notes_nurse
    }

    Axios
      .post(HOMECARE_SEND_PRESCRIPTION(), PARAMS)
      .then((Response) => {

        LS_SERVICE.delete(`medicines_tests_${patient_id}_${reference_id}`);
        LS_SERVICE.delete(`notes_doctor_${patient_id}_${reference_id}`);
        LS_SERVICE.delete(`notes_nurse_${patient_id}_${reference_id}`);

        this.setState({
          sendPrescriptionModal: false,
          isFormProcessing: false,
          isSendPrescriptionProcessing: false
        }, () => {
          if (this.state.callInitialized) {
            this.handleModalPopup(null, 'callRatingModal');
          } else {
            notification.success({
              message: 'Prescription Sent Successfully!',
              placement: 'topRight'
            })
          }

          if (is_doctor && medicines_tests !== "") {
            this.handleSave(null, 'storetestmedicineadvice');
          }
        });
      })
  }

  handleCompleteAppointmentClick = _ => {
    this.setState({
      choosePrescriptionModal: false,
      sendPrescriptionModal: true
    }, () => {
      const isEndCall = document.querySelector('.doctor-video').className.includes('hidden');
      this.setState({
        QBEndCall: isEndCall
      })
    })
  }

  endCallAndCompleteAppointment = (e) => {
    // const { reference_id } = this.state;
    // $(e.target).find('span.loader-span').addClass('loader-active');
    // endCall();
    this.ENDCALL = setInterval(() => {
      const isEndCall = document.querySelector('.doctor-video').className.includes('hidden') ? false : true;
      if (isEndCall) {
        this.completeAppointment();
      }
    }, 100);
  }

  checkAppointmentForm = () => {
    const { medicines_tests, added_progress_data, is_doctor } = this.state;
    return is_doctor ? ((medicines_tests.length > 0 || added_progress_data) ? false : true) : (added_progress_data ? false : true);
  }

  handleGraphClick = (e, type = '2') => {

    // if (this.state.range.length > 0)
    //   this.handleDateRangeGraphData(true);

    this.setState({
      chartDataLoaded: false,
      chartsTab: type
    }, () => this.setState({ chartDataLoaded: true }))
  }

  isCallActive = _ => {
    const elem = document.querySelector('.doctor-video');
    return (elem !== null && elem !== undefined) ? elem.className.includes('hidden') ? false : true : false;
  }

  handleVitalModalButtonDisabled = _ => {
    if (this.state.constants && this.state.homecareVitals) {
      var vitalFieldsCount = this.state.homecareVitals.length;
      var emptyCount = 0;
      this.state.homecareVitals.map((v, k) => {
        if (parseFloat(this.state[v.id]) == 0) emptyCount++;
      });

      if (emptyCount > 0)
        return true;
      if (this.state.V005 != null && this.state.V005 != '' && (this.state.V006 == '' || this.state.V006 == null))
        return true;
      if (this.state.V006 != null && this.state.V006 != '' && (this.state.V005 == '' || this.state.V005 == null))
        return true;
      if (this.state.vitalNotInRange.length > 0)
        return true;
      return false;
    }
    else
      return false;
  }

  handleVitalAddSubmit = async _ => {
    // const { V001, V002, V003, V004, V005, V006, V007, V008, V009, V010, V011 } = this.vitalFormRef.current.getFieldValue();

    if (this.handleVitalModalButtonDisabled()) return;

    // const { V001, V002, V003, V004, V005, V006, V007, V008, V009, V010, V011 } = this.state;
    let vitalsData = this.vitalFormRef.current.getFieldsValue(true);

    // if (Object.keys(vitalsData).includes("bp")) {
    //     delete vitalsData.bp;
    //     vitalsData = { ...vitalsData, V005: this.vitalFormRef.current.getFieldValue("V005"), V006: this.vitalFormRef.current.getFieldValue("V006") }
    // }

    const { patient_id, reference_id } = this.state;

    const PARAMS = {
      vitals_data: vitalsData,
      reference_type: HOMECARE_REFERENCE_TYPE,
      reference_id,
      patient_id
    }

    this.setState({
      spin_loading: !this.state.spin_loading
    })

    Axios.post(OBJECTIVE_VITAL_STORE, qs.stringify(PARAMS))
      .then(success => {
        if (success.status) {
          this.handleObjectiveGraphData();
          // const { data } = vitalAddPromise;
          // notification.success({
          //     message: data.message,
          //     placement: 'topRight'
          // })
          this.setState({
            spin_loading: !this.state.spin_loading
          }, () => {
            this.handleModalPopup(null, 'vitalModal');
          })
        }
      })
  }


  handleVitalModalClear = _ => {
    this.state.homecareVitals.map((v, k) => {
      this.setState({ [v.id]: null });
    });

    this.vitalFormRef.current.resetFields();

    const { patientHeight, patientWeight, patient } = this.state;

    if (patient.age > HOSPITALDEFAULTS.age) {
      if (this.vitalFormRef.current !== null) {
        this.vitalFormRef.current.setFieldsValue({
          V001: patientHeight,
          V002: patientWeight
        });
        this.handleBmiCalculate();
      }
    }

    AntClearLabels();
  }

  handleProgressNav = _ => {
    const { hospital_id, patient_id, reference_id } = this.state;

    this.props.history.push({
      pathname: `/patient/homecare/progress-notes/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${reference_id}`
    })
  }

  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'notes-doctor') {
      initialText = this.state.notes_doctor != undefined ? this.state.notes_doctor : '';
    }
    if (info.identifier === 'medicines-tests') {
      initialText = this.state.medicines_tests != undefined ? this.state.medicines_tests : '';
    }
    if (info.identifier === 'notes-nurse') {
      initialText = this.state.notes_nurse != undefined ? this.state.notes_nurse : '';
    }
    if (info.identifier === 'internal-notes') {
      initialText = this.state.internal_notes != undefined ? this.state.internal_notes : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
        }

      });
    } else {
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
          this.handleRestartMic(info, initialText)
        }

      });
    }
  }

  handleRestartMic = (info, initialText) => {
    // debugger
    this.setState({
      speech: {
        identifier: info.identifier,
        state: true,
        initialText: initialText
      }
    }, () => {
      // console.log("State in init:",this.state.speech)

      if (!this.state.speech.state) {
        this.setState((prevState) => ({
          checkForEditsTrainingData: {
            ...this.state.checkForEditsTrainingData,
            [info.identifier]: false
          }
        }))
      }

      speechService.init(this.state.speech);
    });
  }

  chartStateUpdate = data => {
    this.setState({
      chartGraphSettings: data
    })
  }

  // handleOnFocus = (e) => {
  //     // console.log("Previous Identifier Value:", this.state.speech.identifier)
  //     // console.log("Present Identifier Value:",e.target.id)
  //     var identifierValue = this.state.speech.identifier === e.target.id ? e.target.id : this.state.speech.identifier
  //     this.setState(prevState => ({
  //         speech: {
  //             ...prevState.speech,
  //             identifier: identifierValue,
  //             state: false,
  //             initialText: identifierValue === "hpi" ? this.state.hpi : identifierValue === "chief-complaints" ? this.state.chief_complaints : this.state.review_of_systems
  //         },
  //         checkForEditsTrainingData: {
  //             ...prevState.checkForEditsTrainingData,
  //             [e.target.id]: false
  //         }
  //     }), () => {
  //         speechService.init(this.state.speech)
  //         // this.initSpeech({identifier: e.target.id})
  //     })

  // }

  handleRangeChange = dates => {
    // let range = [];
    // if (dates !== null)
    //   range = [...dates]
    this.setState({
      range: dates !== null ? dates : []
    }, () => {
      this.handleDateRangeGraphData(dates == null)
    })
  }

  render() {

    const { dataLoaded, patient_note_doctor, patient_docments, formModeEdit, isFormProcessing, isSendPrescriptionProcessing, patient_id, reference_id, hospital_id } = this.state;

    return (
      <div>

        <AsideLeft isHomecare={true} key={+reference_id} />

        {dataLoaded && (

          <div className="content-wrapper" style={{ paddingBottom: '80px' }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-8">
                  <div className="row">
                    {dataLoaded ? (
                      <div className="col-12 mb-3" id="patient_notes_docs">
                        <div className="card bg-grey rounded-0">
                          <div className="card-body">

                            <h6 className="text-uppercase text-dark" id="notes_for_doctor_head">Notes For Doctor</h6>
                            <p className="text-dark" id="notes_for_doctor_note">{patient_note_doctor || 'No Notes'}</p>

                            <h6 className="text-uppercase text-dark" id="docs_for_doctor_head">Documents uploaded by the patient</h6>
                            <figure className="mb-0" id="docs_for_doctor_docs">
                              {
                                patient_docments?.length > 0 ? (
                                  patient_docments?.map((file, index) => (
                                    <a href="#" className="show-rx-modal" key={index} onClick={e => this.handleModalPopup(e, 'viewDocumentModal', index)}>
                                      {/* <img className="mr-2" alt='Doc IImg' src={file.type === 'img' ? icJpg : icPdf} /> */}
                                      <i className={`mr-2 icon-${file.type === 'img' ? 'jpg' : 'pdf'}`}></i>
                                    </a>
                                  ))
                                ) : 'No Documents'
                              }
                            </figure>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="col-12">
                      <div className="card rounded-0 cardbg table-full">
                        <div className="card-header card-flex">
                          <h6 className="float-left text-uppercase m-0">Patient Vitals</h6>
                          <button type="button" className="btn top-chart-btn btn-sm mr-3"
                            onClick={(e) => this.handleModalPopup(e, 'chartModal', { type: 'all', name: 'Trend Graphs' })}>
                            <i className="icon-chart"></i>
                          </button>
                          <Link to={{
                            pathname: `/patient/homecare/vitals/${hospital_id}/${patient_id}/${HOMECARE_REFERENCE_TYPE}/${reference_id}`
                          }}
                            className="btn btn-link btn-sm textis-primary h6 font-weight-bold mb-0 p-0 d-flex align-items-center"
                          >
                            View All
                                                </Link>
                          <div className="vital-right ml-auto d-flex align-content-center">
                            <Badge className="mr-2 vr-badge" color={'#ffe6e8'} text={'Vitals entered by Patient'} />
                            {this.state.appointment_status !== 2 ? (
                              <button type="button" className="float-md-right btn btn-outline-primary text-uppercase btn-sm"
                                disabled={this.state.appointment_status === 2}
                                onClick={e => this.handleModalPopup(e, 'vitalModal')}>
                                + Vitals
                              </button>
                            ) : null}
                          </div>
                        </div>
                        {this.state.vitals_data_formatted > 0 ? (
                          <Vitals
                            dataLoaded={this.state.vitals_data_formatted}
                            constants={this.state.constants}
                            visitVitalsFormatted={this.state.visitVitalsFormatted}
                            handleModalPopupStateLift={this.handleModalPopup}
                            homecareView={true}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="row">
                    <div className="col-12 mb-3">

                      <MyHcSpeechRecognition />

                      <Collapse
                        accordion
                        defaultActiveKey={this.state.defaultPanelOpened}
                        expandIconPosition={'ldft'}
                        className="notes-wrapper"
                      >
                        <Panel header="PROGRESS NOTES: DOCTOR" key="1" className="notes-panel micwrap" extra={
                          (this.state.is_doctor && this.state.appointment_status !== 2) && (
                            <Link
                              onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.initSpeech({ identifier: 'notes-doctor' }) }}
                              className={(this.state.speech.identifier === 'notes-doctor' ? this.state.speech.state : '') ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>

                          )}>
                          <div className="p-0 card-body">
                            {(this.state.is_doctor && this.state.appointment_status !== 2) && (
                              <div className="patient-textwrapper">
                                <textarea
                                  tabIndex={1}
                                  rows={5}
                                  cols={20}
                                  placeholder="EX : weakness for 2-3 days"
                                  name="notes_doctor"
                                  value={this.state.notes_doctor}
                                  onChange={this.handleOnChange}
                                />
                                <button className="btn btn-outline-secondary btn-sm bg-white"
                                  disabled={this.state.notes_doctor.length === 0 || (this.state.notes_doctor.length > 0 && isFormProcessing)}
                                  onClick={e => this.handleSave(e, 'storetestnotes')}>
                                  SAVE {this.state.notes_doctor.length > 0 && isFormProcessing && LOADER_BLACK}
                                </button>
                              </div>
                            )}
                            <div className="patient-lastdatacard card">
                              {this.state.notes_doctor_prev && this.state.notes_doctor_prev.notes_by != 'Nurse' && this.state.notes_doctor_prev.count > 0 ? (
                                <div className="p-2 card-body">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-top d-flex justify-content-between">
                                      <div className="plc-common plc-left">{this.state.notes_doctor_prev.notes_by_name}</div>
                                      <div className="plc-common plc-right">
                                        {moment(this.state.notes_doctor_prev.date).format('D MMM Y | h:mm A')}
                                      </div>
                                    </div>
                                    <pre className="pl-common pl-center">
                                      {this.state.notes_doctor_prev.notes}
                                    </pre>
                                    {this.state.notes_doctor_prev.count >= 1 && (
                                      <div className="pl-common pl-bottom" onClick={this.handleProgressNav}>
                                        <span className="view-all">View All</span>
                                      </div>)}
                                  </div>
                                </div>
                              ) : (
                                <div className="p-2">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-center">
                                      No Notes
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Panel>
                        <Panel header="MEDICINES &amp; TESTS ADVISED" key="2" className="notes-panel micwrap" extra={
                          (this.state.is_doctor && this.state.appointment_status !== 2) && (
                            <Link
                              onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.initSpeech({ identifier: 'medicines-tests' }) }}
                              className={(this.state.speech.identifier === 'medicines-tests' ? this.state.speech.state : '') ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>

                          )}>
                          <div className="p-0 card-body">
                            {(this.state.is_doctor && this.state.appointment_status !== 2) && (
                              <div className="patient-textwrapper">
                                <textarea
                                  tabIndex={1}
                                  rows={5}
                                  cols={20}
                                  placeholder="EX : weakness for 2-3 days"
                                  name="medicines_tests"
                                  value={this.state.medicines_tests}
                                  onChange={this.handleOnChange}
                                />
                              </div>
                            )}
                            <div className="patient-lastdatacard card">
                              {this.state.medicines_tests_prev && this.state.medicines_tests_prev.count > 0 ? (
                                <div className="p-2 card-body">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-top d-flex justify-content-between">
                                      <div className="plc-common plc-left">{this.state.medicines_tests_prev.notes_by_name}</div>
                                      <div className="plc-common plc-right">
                                        {moment(this.state.medicines_tests_prev.date).format('D MMM Y | h:mm A')}
                                      </div>
                                    </div>
                                    <pre className="pl-common pl-center">
                                      {this.state.medicines_tests_prev.notes}
                                    </pre>
                                  </div>
                                </div>
                              ) : (
                                <div className="p-2">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-center">
                                      No medicines &amp; tests advised
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Panel>
                        <Panel header="PROGRESS NOTES: NURSE" key="3" className="notes-panel micwrap" extra={
                          (!this.state.is_doctor && this.state.appointment_status !== 2) && (
                            <Link
                              onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.initSpeech({ identifier: 'notes-nurse' }) }}
                              className={(this.state.speech.identifier === 'notes-nurse' ? this.state.speech.state : '') ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>

                          )}>
                          <div className="p-0 card-body">
                            {(!this.state.is_doctor && this.state.appointment_status !== 2) && (
                              <div className="patient-textwrapper">
                                <textarea
                                  tabIndex={1}
                                  rows={5}
                                  cols={20}
                                  placeholder="EX : weakness for 2-3 days"
                                  name="notes_nurse"
                                  value={this.state.notes_nurse}
                                  onChange={this.handleOnChange}
                                />
                                <button className="btn btn-outline-secondary btn-sm bg-white"
                                  disabled={this.state.notes_nurse.length === 0 || (this.state.notes_nurse.length > 0 && isFormProcessing)}
                                  onClick={e => this.handleSave(e, 'storetestnotes')}>
                                  SAVE {this.state.notes_nurse.length > 0 && isFormProcessing && LOADER_BLACK}
                                </button>
                              </div>
                            )}
                            <div className="patient-lastdatacard card">
                              {this.state.notes_nurse_prev && this.state.notes_nurse_prev.count > 0 ? (
                                <div className="p-2 card-body">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-top d-flex justify-content-between">
                                      <div className="plc-common plc-left">{this.state.notes_nurse_prev.notes_by_name}</div>
                                      <div className="plc-common plc-right">
                                        {moment(this.state.notes_nurse_prev.date).format('D MMM Y | h:mm A')}
                                      </div>
                                    </div>
                                    <pre className="pl-common pl-center">
                                      {this.state.notes_nurse_prev.notes}
                                    </pre>
                                    {this.state.notes_nurse_prev.count >= 1 && (
                                      <div className="pl-common pl-bottom" onClick={this.handleProgressNav}>
                                        <span className="view-all">View All</span>
                                      </div>)}
                                  </div>
                                </div>
                              ) : (
                                <div className="p-2">
                                  <div className="patient-lastnotest d-flex flex-column">
                                    <div className="pl-common pl-center">
                                      No Notes
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Panel>
                        {this.state.appointment_status !== 2 && (
                          <Panel header="INTERNAL NOTES" key="4" className="notes-panel micwrap" extra={
                            (this.state.appointment_status !== 2) && (
                              <Link
                                onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.initSpeech({ identifier: 'internal-notes' }) }}
                                className={(this.state.speech.identifier === 'internal-notes' ? this.state.speech.state : '') ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                            )
                          }>
                            <div className="p-0 card-body">
                              {(this.state.appointment_status !== 2) && (
                                <div className="patient-textwrapper">
                                  <textarea
                                    tabIndex={1}
                                    rows={5}
                                    cols={20}
                                    placeholder="EX : weakness for 2-3 days"
                                    name="internal_notes"
                                    value={this.state.internal_notes}
                                    onChange={this.handleOnChange}
                                  />
                                  <button className="btn btn-outline-secondary btn-sm bg-white"
                                    disabled={this.state.internal_notes.length === 0 || (this.state.internal_notes.length > 0 && isFormProcessing)}
                                    onClick={this.handleInternalNotesSave}>
                                    SAVE {this.state.internal_notes.length > 0 && isFormProcessing && LOADER_BLACK}
                                  </button>
                                </div>
                              )}
                              {/* <div className="patient-lastdatacard card">
                                {this.state.medicines_tests_prev && this.state.medicines_tests_prev.count > 0 ? (
                                    <div className="p-2 card-body">
                                        <div className="patient-lastnotest d-flex flex-column">
                                            <div className="pl-common pl-top d-flex justify-content-between">
                                                <div className="plc-common plc-left">{this.state.medicines_tests_prev.notes_by_name}</div>
                                                <div className="plc-common plc-right">
                                                    {moment(this.state.medicines_tests_prev.date).format('D MMM Y | h:mm A')}
                                                </div>
                                            </div>
                                            <pre className="pl-common pl-center">
                                                {this.state.medicines_tests_prev.notes}
                                            </pre>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="p-2">
                                        <div className="patient-lastnotest d-flex flex-column">
                                            <div className="pl-common pl-center">
                                                No Internal Notes
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div> */}
                            </div>
                          </Panel>
                        )}
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
              {(this.state.is_doctor && this.state.appointment_status !== 2) ?
                <div className="fixed-cta">
                  <button className="btn btn-primary px-4"
                    disabled={this.checkAppointmentForm()}
                    onClick={e => this.handleModalPopup(e, 'choosePrescriptionModal')}>
                    SEND PRESCRIPTION
                  </button>
                </div>
                : null}
            </div>
          </div>
        )}

        {!dataLoaded && (
          <div className="col-12">
            <div className="text-center">
              {LOADER_RED}
            </div>
          </div>
        )}

        {/** Document Viewer */}
        <Modal
          title={patient_docments?.length === 1 ? "Document" : "Documents"}
          visible={this.state.viewDocumentModal}
          footer={false}
          width={850}
          onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
          style={{ top: 20 }}
        >
          <div className="pdf_slider">
            {
              patient_docments?.length > 0 ? (
                <Carousel arrows dots={false} ref={this.carouselRef}>
                  {
                    patient_docments?.map((file, index) => (
                      file.type === 'img' ?
                        <figure key={index}><img className="mr-3" src={file.src} alt='uploaded Doc' /></figure>
                        :
                        <embed key={index} className="mr-3" src={file.src + "#toolbar=0&scrollbar=0&pagemode=none"} />
                    ))
                  }
                </Carousel>
              ) : null
            }
          </div>
        </Modal>

        {/* || VITAL MODAL */}
        <Modal
          title={`${formModeEdit ? 'Edit ' : 'Add '} Vitals`}
          visible={this.state.vitalModal}
          onCancel={(e) => this.handleModalPopup(e, 'vitalModal')}
          style={{ top: 40 }}
          footer={[
            <div className="text-center">
              <Button form="vitalForm" type="outline-secondary"
                onClick={this.handleVitalModalClear}
                htmlType="button" className="btn btn-outline-secondary cursor-pointer">
                CLEAR
              </Button>
              <Button form="vitalForm" type="primary" htmlType="submit" className="btn btn-primary text-uppercase ml-2"
                disabled={this.handleVitalModalButtonDisabled() || this.state.spin_loading}>
                CONFIRM{this.state.spin_loading ? LOADER : ''}
              </Button>
            </div>
          ]}
          width="910px"
          bodyStyle={{ paddingBottom: '10px' }}
          className="emrfrm"
        >

          <Form
            id="vitalForm"
            className="add-vitals"
            ref={this.vitalFormRef}
            validateMessages={HANDLEVALIDANTMESSAGES}
            onFinish={this.handleVitalAddSubmit}
            layout="vertical"
          >
            <div className="row">
              <div className="col-4">

                <Form.Item
                  name="vitalType"
                >
                  <span className="d-none">{this.state.vitalsType}</span>
                  <div className="form-group mb-1 hasdata">
                    <Select name="vitalsType" id="vitalsType" value={this.state.vitalsType} defaultValue={this.state.vitalsType} onChange={this.handleVitalSelect}>
                      {this.state?.selectedVitalGroups?.map((vitalGroup, index) => (
                        <Option key={index} value={vitalGroup.id} datavitals={vitalGroup.vitalSignIds}>{vitalGroup.long_description}</Option>
                      ))}
                    </Select>
                    <label htmlFor="vitalsType">Vital Group</label>
                  </div>
                </Form.Item>

              </div>
            </div>
            <div className="row">
              {this.state.constants && this.state.homecareVitals ? this.state.homecareVitals.map((vital, idx) => {
                const tabIndx = idx + 1;
                if (vital.id == 'V006') return (null)
                else if (vital.id == 'V005')
                  return (<div className="col-4 antInputItem multi-col" key={idx}>
                    <Form.Item name="bp" label="Blood Pressure"
                      style={this.state.vitals_data_formatted && !this.state?.displayVitals.includes("5") ? { display: 'none' } : ''}
                      rules={[{
                        required: vital.required,
                        type: 'number',
                        min: vital.min,
                        max: vital.max,
                        transform: function (value) {
                          return value === "" || value === undefined ? value : +value
                        }
                      }]}>
                      <div className="multi-input-col-inner">
                        <div className="ant-input-wrapper ant-input-group">
                          <div className="ant-input">
                            <Input name="V006" data-vitalid="6" tabIndex={tabIndx} autoComplete="off" maxLength={vital.maxlength} min={vital.min} max={vital.max}
                              value={this.state.V006} onChange={(e) => { this.handleOnChangeNumbers(e, vital.min, vital.max) }} />
                            <Input name="V005" data-vitalid="5" tabIndex={+tabIndx + 1} autoComplete="off" maxLength={vital.maxlength} min={vital.min} max={vital.max}
                              className="multi" value={this.state.V005} onChange={(e) => { this.handleOnChangeNumbers(e, vital.min, vital.max) }} />
                          </div>
                          <span className="ant-input-group-addon">mmHg</span>
                        </div>
                      </div>
                    </Form.Item>
                  </div>)
                else
                  return (
                    <div className="col-4 antInputItem" key={idx}>
                      <Form.Item name={vital.id} label={vital.display_name}
                        style={this.state.vitals_data_formatted && !this.state?.displayVitals.includes("" + vital?.vital_id) ? { display: 'none' } : ''}
                        rules={[{
                          required: vital.required,
                          type: 'number',
                          min: vital.min,
                          max: vital.max,
                          transform: function (value) {
                            return value === "" || value === undefined ? value : +value
                          }
                        }]}>

                        <Input addonAfter={vital.display_value} name={vital.id} autoComplete="off" maxLength={vital.maxlength} data-vitalid={vital.vital_id} tabIndex={tabIndx}
                          value={this.state[vital.id]} onChange={(e) => { this.handleOnChangeNumbers(e, vital.min, vital.max) }} disabled={vital.id == 'V003'} />
                      </Form.Item>
                    </div>
                  )
              }, this) : (null)}

            </div>
          </Form>
        </Modal>

        {/** choose Prescription */}
        <Modal
          title={'Choose Prescription'}
          visible={this.state.choosePrescriptionModal}
          footer={false}
          onCancel={e => this.handleModalPopup(e, 'choosePrescriptionModal')}
          style={{ top: 20 }}
        >

          <div className="content">
            <p>Please select the sections you would like to send in the prescription</p>
            {this.state.is_doctor ? (
              <>
                <div className="p-0 my-2">
                  <Checkbox
                    onClick={this.handlePrescriptionCheckbox}
                    value={this.state.send_notes_for_doctor}
                    checked={this.state.send_notes_for_doctor}
                    name="send_notes_for_doctor">Notes From Patient
                  </Checkbox>
                </div>
                <div className="p-0 my-2">
                  <Checkbox
                    onClick={this.handlePrescriptionCheckbox}
                    value={this.state.send_progress_notes_doctor}
                    checked={this.state.send_progress_notes_doctor}
                    name="send_progress_notes_doctor">Progress Notes: Doctor
                  </Checkbox>
                </div>
                <div className="p-0 my-2">
                  <Checkbox
                    onClick={this.handlePrescriptionCheckbox}
                    value={this.state.send_medicine_test}
                    checked={this.state.send_medicine_test}
                    name="send_medicine_test">Medicines &amp; Tests Advised
                  </Checkbox>
                </div>
              </>
            ) : ""}
            <div className="p-0 my-2">
              {/*{!this.state.is_doctor ? (
                  <Checkbox
                      onClick={this.handlePrescriptionCheckbox}
                      value={this.state.send_progress_notes_doctor}
                      checked={this.state.send_progress_notes_doctor}
                      name="send_progress_notes_doctor">Progress Notes: Nurse
                  </Checkbox>
                            ) : (
                  <Checkbox
                      onClick={this.handlePrescriptionCheckbox}
                      value={this.state.send_progress_notes_nurse}
                      checked={this.state.send_progress_notes_nurse}
                      name="send_progress_notes_nurse">Progress Notes: Nurse
                  </Checkbox>
              )}*/}
              <Checkbox
                onClick={this.handlePrescriptionCheckbox}
                value={this.state.send_progress_notes_nurse}
                checked={this.state.send_progress_notes_nurse}
                name="send_progress_notes_nurse">Progress Notes: Nurse
              </Checkbox>
            </div>
            <div className="p-0 my-2">
              <Checkbox
                onClick={this.handlePrescriptionCheckbox}
                value={this.state.send_patient_vitals}
                checked={this.state.send_patient_vitals}
                name="send_patient_vitals">Patient Vitals
              </Checkbox>
            </div>
          </div>

          <div className="text-center mt-3">
            <button className="btn btn-primary font-weight-bold"
              onClick={this.handleCompleteAppointmentClick}>
              CONFIRM
            </button>
          </div>
        </Modal>

        {/** Send Prescription  */}
        <Modal
          title={''}
          visible={this.state.sendPrescriptionModal}
          footer={false}
          onCancel={e => this.handleModalPopup(e, 'sendPrescriptionModal')}
          style={{ top: 20 }}
        >
          <div className="popup">
            <div className="content mt-2 ml-3 mr-3">
              <p className="doc-popup-text text-center ml-5 mr-5">
                Once the prescription is sent to the patient, no further editing of the prescription will be possible.
              </p>
            </div>
            {this.isCallActive() || !this.state.QBEndCall ?
              (<div className="pr-3 button text-center">
                <button
                  className="btn btn-outline-dark cancel-btn button-in mr-2"
                  onClick={this.completeAppointment}
                >
                  SEND PRESCRIPTION AND CONTINUE WITH CALL{(isFormProcessing && isSendPrescriptionProcessing) && LOADER_BLACK}
                </button>
                <button
                  className="btn btn-primary mt-3 mb-3 button-in"
                  onClick={this.endCallAndCompleteAppointment}
                >
                  SEND PRESCRIPTION AND END CALL{(isFormProcessing && isSendPrescriptionProcessing) && LOADER}
                </button>

              </div>)
              : this.state.QBEndCall ?
                (<div className="pr-3 button text-center">
                  <button
                    className="btn btn-primary mt-3 mb-3 button-in"
                    onClick={this.completeAppointment}
                  >
                    {" "}SEND PRESCRIPTION TO PATIENT{(isFormProcessing && isSendPrescriptionProcessing) && LOADER}
                  </button>
                </div>) : null
            }
          </div>

        </Modal>

        {/* || CHART MODAL */}
        {this.state.chartModal?
        <Modal
          title={this.state.chartHeading}
          visible={this.state.chartModal}
          onCancel={(e) => this.handleModalPopup(e, 'chartModal')}
          style={{ top: '40px', maxWidth: '90%' }}
          width="100%"
          className="all_vital"
          bodyStyle={{ maxHeight: '700px', overflowY: 'auto' }}
          footer={false}
        >

          <div className="col wrap_chart_filter d-flex justify-content-around align-items-center">
            <ul className="chart_filter">
              <li>
                <button type="button" className={`btn btn-default chart-day ${this.state.chartsTab === '0' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '0')}>DAY</button>
              </li>
              <li>
                <button type="button" className={`btn btn-default chart-week ${this.state.chartsTab === '1' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '1')}>WEEK</button>
              </li>
              <li>
                <button type="button" className={`btn btn-default chart-month ${this.state.chartsTab === '2' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '2')}>MONTH</button>
              </li>
              <li>
                <button type="button" className={`btn btn-default chart-year ${this.state.chartsTab === '3' ? 'active' : ''}`}
                  onClick={(e) => this.handleGraphClick(e, '3')}>YEAR</button>
              </li>
            </ul>

            <RangePicker
              allowClear={true}
              value={this.state.range}
              onChange={this.handleRangeChange}
              disabledDate={DISABLED_FUTURE_DATES}
              format="DD/MM/YYYY"
            />
          </div>

          {this.state.chartDataLoaded && (
            <Chart
              chartData={this.state.chartData}
              bloodSugarChartData={this.state.bloodSugarChartData}
              vitalsRangeWithId={this.state.vitalsRangeWithId}
              dataLoaded={this.state.chartDataLoaded}
              chartsType={this.state.chartsType}
              reference_id={this.state.reference_id}
              chartGraphSetting={this.state.chartGraphSettings}
              chartsTab={this.state.chartsTab}
              constants={this.state.constants}
              checkBoxDisable={this.state.appointment_status === 2 || (LS_SERVICE.get('user_type') === USERTYPES.nurse)}
              updateData={this.chartStateUpdate}
              showVitalType={'homecare'}
            />
          )}

        </Modal>
        : null }
      </div>
    )
  }
}

export default HomecareDetailComponent
