import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from "react-moment";
import { GET_VITALS_RANGE_CLASS } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Popover } from 'antd';

class KeyFindings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: props.dataLoaded,
      constants: props.constants,
      KeyFindingsConstants: Object.values(props.constants.findingGroupData),
      visitKeyFindingsFormatted: props.visitKeyFindingsFormatted ?? [],
      keyFindingsOrder: props.keyFindingsOrder,
      newKeyFindingsOrder: []
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.visitKeyFindingsFormatted !== prevState.visitKeyFindingsFormatted)
      return { visitKeyFindingsFormatted: nextProps.visitKeyFindingsFormatted }
    if (nextProps.keyFindingsOrder !== prevState.keyFindingsOrder)
      return { keyFindingsOrder: nextProps.keyFindingsOrder }
    return null;
  }
  componentDidMount (){
    this.updateKeyFindingsOrder();
  }
  componentDidUpdate(prevProps, prevState) {
    if ((this.state.keyFindingsOrder != null && this.state.keyFindingsOrder != prevState.keyFindingsOrder) || (this.state.visitKeyFindingsFormatted != null && this.state.visitKeyFindingsFormatted != prevState.visitKeyFindingsFormatted)) {
      this.updateKeyFindingsOrder();
    }
  }

  updateKeyFindingsOrder = async () => {
    let new_key_findings_order = [];
    this.state.keyFindingsOrder.map((fo, k2) => {
      let obj = {display_order: fo.display_order, finding_group_id: fo.finding_group_id, vital_sign: []};
      let at_least_one_av = false;
      let findex = this.state.KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
      if(findex !== -1){
        fo.vital_sign.map((v, k) => {
          let obj_vital_sign = {...v};
          let findex2 = this.state.KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
          if(findex2 !== -1){
            let vital = this.state.KeyFindingsConstants[findex].vital_sign[findex2];
            let keyfindingsavailable = false;
            this.state.visitKeyFindingsFormatted[vital.id]?.map((val, i) => {
              if (val && val != "") {
                keyfindingsavailable = true;
                at_least_one_av = true;
              }
            })
            obj_vital_sign.value_available = keyfindingsavailable;
            obj.vital_sign.push(obj_vital_sign);
          }
        });
      }
      obj.one_value_available = at_least_one_av;
      new_key_findings_order.push(obj);
    });
    await this.setState({
      newKeyFindingsOrder: new_key_findings_order
    })
  }

  getSourceDisplayName(source, date, update_date) {
    let sourceName = source[0].toUpperCase() + source.substring(1);
    let time = moment(update_date).format('DD MMM, h:mm A');
    return source == 'patient' && date && update_date && date != update_date ? sourceName + ' | ' + time : sourceName;
  }

  render() {
    let one_group_avail = false;
    this.state.newKeyFindingsOrder.forEach((data) => {
      if(!one_group_avail){
        one_group_avail = data.one_value_available;
      }
    });
    return (
      <>
      {this.state.dataLoaded ? (
        <div className="card-body vitals">
          <div className="row">
            <div className="col-12 table-responsive" style={{ padding: "0px" }}>
              <table className="table">
                <tbody>
                  {this.state.newKeyFindingsOrder.length && one_group_avail ? 
                    <tr>
                      <td className="text-bold"></td>
                        {this.state?.visitKeyFindingsFormatted?.date.map((v, i) => (
                          <td key={i} className={`pl-0 pr-0 ${this.state.visitKeyFindingsFormatted.source[i] === 'patient' && 'bgis-pink'}`} style={{ maxWidth: "250px" }}>
                            <div className="row">
                              <div className="col-12 text-center vital-source">
                                {this.state.visitKeyFindingsFormatted?.source_name[i] !== "" ? (
                                  <Popover
                                    className="ml-1"
                                    content={
                                      this.state.visitKeyFindingsFormatted?.source_name[i]
                                    }
                                  >
                                    {this.getSourceDisplayName(this.state.visitKeyFindingsFormatted.source[i], v, this.state.visitKeyFindingsFormatted.update_date[i])}
                                  </Popover>
                                ) : (
                                  this.getSourceDisplayName(this.state.visitKeyFindingsFormatted.source[i], v, this.state.visitKeyFindingsFormatted.update_date[i])
                                )}
                              </div>
                              <div className="col-12 text-center vital-time">
                                <Moment parse="YYYY-MM-DDTHH:mm:ss.SSSSZ" format="DD MMM, yyyy | h:mm A" date={v} />
                              </div>
                            </div>
                          </td>
                        ))}
                      <td className="text-center text-bold" style={{width: '132px'}}>
                        Reference Range
                      </td>
                      <td className="text-center text-bold">
                        Trend Graph
                      </td>
                    </tr>
                  : null}
                  
                    {this.state.newKeyFindingsOrder.map((fo, k2) => {
                      let findex = this.state.KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
                      if(findex !== -1){
                        if(fo.one_value_available){
                          return (
                            <React.Fragment key={k2}>
                              <tr>
                                <td className="text-bold">{this.state.KeyFindingsConstants[findex].name}</td>
                                {this.state?.visitKeyFindingsFormatted?.date.map((v, i) => (
                                  <td key={i}></td>
                                ))}
                                <td></td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn chart-btn button-chart-individual"
                                    onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModal', { type: 'finding_group', id: fo.finding_group_id, name: 'Key Findings Trend Graphs' })}
                                  >
                                    <i className="icon-chart"></i>
                                  </button>
                                </td>
                              </tr>
                              {fo.vital_sign.map((v, k) => {
                                let findex2 = this.state.KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
                                if(findex2 !== -1){
                                  let vital = this.state.KeyFindingsConstants[findex].vital_sign[findex2];
                                  if(v.value_available){
                                    return (
                                      <tr key={v.vital_id}>
                                        <td>{vital.display_name} {vital.display_value ? '(' + vital.display_value + ')' : ''}</td>

                                        {this.state.visitKeyFindingsFormatted[vital.id]?.map((val, i) => {
                                          if (val) return (<td key={i} className={`text-center ${GET_VITALS_RANGE_CLASS(val, this.state.constants.vitals_range[vital.id])} ${this.state.visitKeyFindingsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>{val}</td>)
                                          else return (<td key={i} className={`text-center ${this.state.visitKeyFindingsFormatted.source[i] === 'patient' && 'bgis-pink'}`}>-</td>);
                                        })}
                                        <td>{this.state.constants.vitals_range[vital.id].normal.min + '-' + this.state.constants.vitals_range[vital.id].normal.max}</td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn chart-btn button-chart-individual"
                                            onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModal', { type: 'individual', id: vital.id, name: 'Key Findings Trend Graph' })}
                                          >
                                            <i className="icon-chart"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  }
                                }
                              })}
                            </React.Fragment>
                          );
                        }
                      }
                    })}
                    {/* <tr>
                    <td className="text-bold">Glycemic Indicators</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Blood Sugar Fasting (mg/dI)</td>
                    <td className="text-center text-warning">150</td>
                    <td className="text-center text-warning">120</td>
                    <td className="text-center text-warning">117</td>
                    <td className="text-center text-warning">115</td>
                    <td className="text-center text-warning">119</td>
                    <td className="text-center text-warning">
                      80-100
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Random Blood Sugar Prandial (mg/dI)</td>
                    <td className="text-center text-danger">250</td>
                    <td className="text-center text-danger">300</td>
                    <td className="text-center text-danger">290</td>
                    <td className="text-center text-danger">240</td>
                    <td className="text-center text-danger">270</td>
                    <td className="text-center text-danger">
                      100-140
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Random Blood Sugar (mg/dI)</td>
                    <td className="text-center text-danger">190</td>
                    <td className="text-center text-danger">130</td>
                    <td className="text-center text-danger">131</td>
                    <td className="text-center text-danger">125</td>
                    <td className="text-center text-danger">129</td>
                    <td className="text-center text-danger">
                      80-120
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Insulin Unit Taken(Unit)</td>
                    <td className="text-center">4</td>
                    <td className="text-center">6</td>
                    <td className="text-center">7</td>
                    <td className="text-center">5</td>
                    <td className="text-center">4</td>
                    <td className="text-center">-</td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>HbA1c</td>
                    <td></td>
                    <td className="text-center text-danger">6.9</td>
                    <td className="text-center text-danger">6.7</td>
                    <td className="text-center text-danger">6.5</td>
                    <td className="text-center text-danger">6.6</td>
                    <td className="text-center text-danger">
                      5.7-6.4
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-bold">Lipid Profile</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Cholesterol(mg/dI)</td>
                    <td></td>
                    <td className="text-center text-success">200</td>
                    <td className="text-center text-success">190</td>
                    <td className="text-center text-success">170</td>
                    <td className="text-center text-success">130</td>
                    <td className="text-center text-success">
                      125-200
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Triglycerides (mg/dL)</td>
                    <td></td>
                    <td className="text-center text-success">130</td>
                    <td className="text-center text-success">128</td>
                    <td className="text-center text-success">135</td>
                    <td className="text-center text-success">135</td>
                    <td className="text-center text-success">
                      0-150
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>LDL(mg/dI)</td>
                    <td className="text-center text-success"></td>
                    <td className="text-center text-success">120</td>
                    <td className="text-center text-success">118</td>
                    <td className="text-center text-success">115</td>
                    <td className="text-center text-success">115</td>
                    <td className="text-center text-success">
                      0-100
                                    </td>

                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td> HDL(mg/dI)</td>
                    <td className="text-center text-success"></td>
                    <td className="text-center text-success">50</td>
                    <td className="text-center text-success">48</td>
                    <td className="text-center text-success">45</td>
                    <td className="text-center text-success">44</td>
                    <td className="text-center text-success">0-40</td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-bold">Thyroid Profile</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>TSH(mIU/L)</td>
                    <td className="text-center text-success"></td>
                    <td className="text-center text-success">2</td>
                    <td className="text-center text-success">3</td>
                    <td className="text-center text-success">3</td>
                    <td className="text-center text-success">2</td>
                    <td className="text-center text-success">
                      0.4-4
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>T3(ng/dI)</td>
                    <td></td>
                    <td className="text-center text-success">200</td>
                    <td className="text-center text-success">190</td>
                    <td className="text-center text-success">180</td>
                    <td className="text-center text-success">150</td>
                    <td className="text-center text-success">
                      100-200
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>T4(mcg/dI)</td>
                    <td></td>
                    <td className="text-center text-success">12</td>
                    <td className="text-center text-success">11</td>
                    <td className="text-center text-success">10</td>
                    <td className="text-center text-success">8</td>
                    <td className="text-center text-success">5-12</td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-bold"> Liver Profile</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td> SGOT(U/L)</td>
                    <td></td>
                    <td className="text-center text-success">30</td>
                    <td className="text-center text-success">29</td>
                    <td className="text-center text-success">32</td>
                    <td className="text-center text-success">40</td>
                    <td className="text-center text-success">5-50</td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>SGPT (U/L)</td>
                    <td></td>
                    <td className="text-center text-success">40</td>
                    <td className="text-center text-success">39</td>
                    <td className="text-center text-success">45</td>
                    <td className="text-center text-success">50</td>
                    <td className="text-center text-success">7-56</td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>Alkaline Phosphatase(IU/L)</td>
                    <td></td>
                    <td className="text-center text-success">100</td>
                    <td className="text-center text-success">99</td>
                    <td className="text-center text-success">120</td>
                    <td className="text-center text-success">125</td>
                    <td className="text-center text-success">
                      44-147
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-bold">GGT (IU/L)</td>
                    <td></td>
                    <td className="text-center text-success">20</td>
                    <td className="text-center text-success">18</td>
                    <td className="text-center text-success">25</td>
                    <td className="text-center text-success">30</td>
                    <td className="text-center text-success">9-48</td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td> Bilirubin (mg/per dI)</td>
                    <td></td>
                    <td className="text-center text-success">1.2</td>
                    <td className="text-center text-success">1.1</td>
                    <td className="text-center text-success">1.2</td>
                    <td className="text-center text-success">1.1</td>
                    <td className="text-center text-success">
                      0-1.2
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-bold"> Renal Profile</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td> Urea (mg/per dI)</td>
                    <td></td>
                    <td className="text-center text-success">10</td>
                    <td className="text-center text-success">11</td>
                    <td className="text-center text-success">3</td>
                    <td className="text-center text-success">15</td>
                    <td className="text-center text-success">7-20</td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td> Creatinine (mg/per dI)</td>
                    <td></td>
                    <td className="text-center text-success">1.0</td>
                    <td className="text-center text-success">1.1</td>
                    <td className="text-center text-success">1.0</td>
                    <td className="text-center text-success">1.2</td>
                    <td className="text-center text-success">
                      0.9-1.3
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td> BUN (mg/per dI)</td>
                    <td></td>
                    <td className="text-center text-success">10</td>
                    <td className="text-center text-success">9</td>
                    <td className="text-center text-success">12</td>
                    <td className="text-center text-success">13</td>
                    <td className="text-center text-success">7-20</td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td> GFR (mL/min/1.73m2)</td>
                    <td></td>
                    <td className="text-center text-success">70</td>
                    <td className="text-center text-success">68</td>
                    <td className="text-center text-success">72</td>
                    <td className="text-center text-success">80</td>
                    <td className="text-center text-success">
                      60-90
                                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn chart-btn button-chart-individual"
                      >
                        <i className="icon-chart"></i>
                      </button>
                    </td>
                  </tr>
                    */}
                </tbody>
              </table>
            </div>
          </div>
        </div>) : ""}
      </>
    );
  }
}

export default KeyFindings;
