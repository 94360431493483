import React, { Component, Fragment } from 'react';
import {
    LAB_TEST_SEARCH, DRUGS_CONSTANT, LAB_TEST_FAVOURITE, LAB_TEST_CONSTANTS, REFERENCE_TYPE, DELETE_TEST,
    PLAN_TEST_ADVICED_STORE, PLAN_TEST_ADVICED_LIST, LOADER_BLACK
} from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { jQueryLabels, AntSelectLabels, ClearAntList } from '../../utils/jQueryLabels';
import { Form, Modal, Input, Button, Select, AutoComplete } from 'antd';
const { Option } = Select;
class TestTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testData: [],
            viewType: 'full', //template, full
            constants: [],
            protocol_start_date: moment(new Date()).format('D MMM, YYYY')
        }
        this.test_name = React.createRef()
        this.flag = 1
    }

    componentDidMount() {
        axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
        jQueryLabels();
    }

    componentDidUpdate(prevProps, prevState) {

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            testData: nextProps.testData,
            viewType: nextProps.viewType,
            constants: nextProps.constants
        }
    }

    optionReqViewLoad() {
        if (!this.state.TestConstants || this.state.TestConstants.length === 0) {
            return (<Option value={0}><span>No Result Found</span></Option>)
        }
        return this.state.TestConstants.map((obj, i) => {
            return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
            )
        })
    }
    optionPriorityLoad() {
        if (!this.state.DRUGSConstants.drugUrgency || this.state.DRUGSConstants.drugUrgency.length === 0) {
            return (<Option value={0}><span>No Result Found</span></Option>)
        }
        return this.state.DRUGSConstants.drugUrgency.map((obj, i) => {
            return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
            )
        })
    }


    handleModalPopup = (e, popup) => {
        if (e !== null)
            e.preventDefault();

        this.setState({
            [popup]: !this.state[popup]
        }, () => {
            const { testDuplicateModal, retestApproved } = this.state;
            if (!testDuplicateModal && !retestApproved) {
                this.Formclear();
            }
        })
    }

    updateStartDate = (start_data, this_test_id) => {
      var test_data = []
      start_data = moment(start_data).format('YYYY-MM-DD');
      for(var test of this.state.testData){
        if(test.test_id == this_test_id){
          test_data.push(test)
        }
        else{
          test_data.push(test)
        }
      }
      this.props.updateData(test_data, start_data, this_test_id)
    }

    handleEditDelete = (e, modal, data, action, idx) => {

        if (this.props.isPracticing === false) {
            this.props.practiceModal('isPracticingModal');
            return;
        }

        this.props.handlePopUp(e, modal, data, action, idx);
    }

    render() {
        return (
            <Fragment>
                {this.state.testData && this.state.testData.length > 0 ?
                    <>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th width={30 + '%'}>TEST NAME/PROCEDURE</th>
                                        <th>PRIORITY</th>
                                        <th>INSTRUCTIONS</th>
                                        {this.props.eventBased == true ? <th width="180px">DUE FROM</th> : null}
                                        {this.props.showAction ? <th width={100}>ACTION</th> : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.testData.map((data, i) => {
                                        var deleteIndex = this.state.viewType == 'visit-full' ? data.id : i;
                                        var protocol_start_date = moment(data.start_date).format('DD MMM, YYYY');
                                        var custom_bgcolor;
                                        var show_original_due = false;
                                        if(this.props.alreadyPrescribedTests?.includes(data.test_id)){
                                          custom_bgcolor = '#dddbdb'
                                        }
                                        else if(moment(data.start_date_protocol).diff(moment(), 'days') < 0){
                                          custom_bgcolor = '#f7eaea'
                                          show_original_due = true;
                                        }
                                        else if(moment(data.start_date).diff(moment(), 'days') == 0){
                                          custom_bgcolor = '#dcedf4'
                                        }
                                        return (
                                            <tr key={i} style={{backgroundColor: this.state.viewType == 'copy-to-visit' && this.props.eventBased == true && this.props.pateintInfo.gender == 'Female' && LS_SERVICE.get('isObsGynaeDoctor') == 1 && LS_SERVICE.get('isObsGynaePatient') == true && data?.event > 0 && custom_bgcolor ? custom_bgcolor : null}}>
                                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.test_name}</td>
                                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{this.state.constants.drugUrgency.map((data1, i) => data1.id === data.urgency ? data1.description : null)}</td>
                                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.instructions !== '' ? data.instructions : '-'}</td>
                                                {(this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.props.eventBased == true ? 
                                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.event !== null ? this.state.constants.drugEvent.map((data1, i) => data1.id === data.event ? data1.description : null) : ''} {data.event == 1 || data.event == 2 ? data.lmp_event_duration : null} {data.event == 1 || data.event == 2 ? data.lmp_event_duration_type : null}</td>
                                                : null}
                                                {this.state.viewType === 'copy-to-visit' && this.props.eventBased == true ? 
                                                  <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                                    {
                                                      data?.event == 4
                                                    ?
                                                      <>
                                                        <div className="form-group hasdata">
                                                        <DatePicker
                                                          id="protocol_start_date"
                                                          name="protocol_start_date"
                                                          defaultPickerValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                          defaultValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                          value={moment(protocol_start_date).format('D MMM, YYYY')}
                                                          minDate={moment().toDate()}
                                                          className="form-control datetimepicker"
                                                          onChange={(e) => { this.updateStartDate(e, data.test_id) }}
                                                        />
                                                        <label htmlFor="key_finding_date_time">Custom Date</label>
                                                        </div>
                                                      </>
                                                    :
                                                      <>
                                                        {
                                                          moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                                        ?
                                                          '-'
                                                        : 
                                                          <>
                                                            {moment(data.start_date).format('D MMM, YYYY')}
                                                            <span style={{fontSize: "12px"}}>
                                                              {
                                                                show_original_due == true
                                                              ?
                                                                <>
                                                                  <br/>
                                                                  (Due from {moment(data.start_date_protocol).format('D MMM, YYYY')})
                                                                </>
                                                              :
                                                                null
                                                              }
                                                            </span>
                                                          </>
                                                        }
                                                      </>
                                                    }
                                                  </td>
                                                : null}
                                                {this.state.viewType === 'soap-view' && this.props.eventBased == true ? 
                                                  <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                                    {
                                                      moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                                    ?
                                                      '-'
                                                    : 
                                                      moment(data.start_date).format('D MMM, YYYY')
                                                    }
                                                  </td>
                                                : null}
                                                {this.props.showAction ?
                                                    <td>
                                                        <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', data, 'deleteTest', deleteIndex)}><i className="icon_delete"></i></a>
                                                        {this.props.showSelectCheck ?
                                                            <span className="select-diagnosis select-check">
                                                                <i data-checkSelect={i} data-type={'test'} onClick={(e) => this.props.updateSelection(i, 'testData')}
                                                                    className={data.selected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                                                            </span>
                                                            : null}

                                                    </td>
                                                    : null}
                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                    : null}

            </Fragment>
        );
    }
}

export default TestTable;