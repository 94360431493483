import React, { Component, Fragment } from "react";
import { Button, Modal, Checkbox, Select, Spin, notification } from "antd";
import axios from "axios";
import {
    TEMPLATE_FILTER,
    DOCTOR_AGENT_LIST,
    ALL_DOCTOR_AGENT_LIST,
    HOSPITAL_AGENT_LIST,
    SPECIALITY_AGENT_LIST,
    ALL_SPECIALITY_DOCTOR_AGENT_LIST,
    ALL_SPECIALITY_AGENT_LIST,
    TEMPLATE_SEARCH,
    DELETE_TEMPLATE,
    FAVORITE_TEMPLATE,
    VIEW_TEMPLATE,
    LOADER_RED,
    SAVE_TEMPLATELIST,
    USERTYPES,
    PARENT_TEMPLATE_ACTIVATION,
    SUB_TEMPLATE_ACTIVATION,
    DIAGNOSIS_DO_NOT_PRINT
} from "../../utils/constant";
import LS_SERVICE from "../../utils/localStorage";
import { Link } from "react-router-dom";
import qs from "qs";
// import './template.scss';
import "antd/dist/antd.css";
import { NavLink } from "react-router-dom";
import { jQueryLabels } from "../../utils/jQueryLabels";
import MedicineTable from "../../components/drugs/medicine-table";
import InstructionTable from "../../components/instructions/instructions-table";
import SupportingMaterialTable from "../../components/supporting-material/supporting-material-table";

const { Option } = Select;

class TemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewNo: 0,
            templateList: [],
            templateView: [],
            isModalVisible: false,
            templateId: "",
            $isTestFavCheckedVal: [],
            selectedTemplates: [], //superadmin
            activeTemplates: [],
            mapToHospital: [],
            hospitalList: [],
            specialityList: [],
            isDataFetching: false,
            isDataLoaded: false,
            doctorList: [],
            hospital_name: "",
            owner_id: [],
            speciality_id: [],
            hospital_id: "",
            doctor_id: [],
            template_name: "",
            saveButton: true,
            isSuperAdmin:
                LS_SERVICE.get("user_type") == USERTYPES.superadmin
                    ? true
                    : false,
            isDoctor:
                LS_SERVICE.get("user_type") != USERTYPES.admin &&
                    LS_SERVICE.get("user_type") != USERTYPES.superadmin
                    ? true
                    : false,
            isAdmin:
                LS_SERVICE.get("user_type") == USERTYPES.admin ? true : false,
            login_id:
                LS_SERVICE.get("user_type") == USERTYPES.admin
                    ? LS_SERVICE.get("admin_hospital_id")
                    : LS_SERVICE.get("staff_id"),
            hospital_master: "N",
        };

        this.TemplateOnFilter = this.TemplateOnFilter.bind(this);
        this.is_favoriteArr = [];
    }
    $drugDosage = [];
    $drugFrequency = [];
    $drugRoute = [];
    showModal = () => {
        this.setState({ isModalVisible: true });
    };
    handleOk = () => {
        this.setState({ isModalVisible: false });
        this.TemplateOndelete(this.state.templateId);
    };
    handleCancel = () => {
        this.setState({ isModalVisible: false });
    };

    TemplateOnListLoad = () => {
        axios.defaults.headers.common["Authorization"] =
            LS_SERVICE.get("token");

        axios
            .post(
                TEMPLATE_SEARCH,
                qs.stringify({
                    query: "",
                    doctor_Id: LS_SERVICE.get("staff_id"),
                    hospital_id: LS_SERVICE.get("slot_hospital_id"),
                    speciality_id: LS_SERVICE.get("speciality_id"),
                    list: "all",
                })
            )
            .then((success) => {
                this.is_favoriteArr = [];
                if (
                    success.data.status &&
                    success.data.message !== "Not Found!"
                ) {
                    success.data.data.templates.forEach((data, i) => {
                        this.is_favoriteArr.push(data.is_favorite);
                    });
                    this.setState({
                        templateList: success.data.data.templates,
                        $isTestFavCheckedVal: this.is_favoriteArr,
                    });
                } else {
                    this.setState({ templateList: success.data });
                }
            })
            .catch((err) => console.log(err));
    };

    saveButtonDisableHandler() {
        if (
            this.state?.mapToHospital?.length > 0 &&
            this.state?.selectedTemplates?.length > 0
        ) {
            this.setState({
                saveButton: false,
            });
        } else {
            this.setState({
                saveButton: true,
            });
        }
    }

    // Activate / Deactivate Template - Parent Level
    ParentTemplateActivation = async (e) => {
        const { value, checked } = e.target;
        //console.log("ParentTemplateActivation : ", value);

        this.setState({ ["active_for_" + value]: checked });
        await axios
            .post(
                PARENT_TEMPLATE_ACTIVATION,
                qs.stringify({
                    template_id: value,
                })
            )
            .then((success) => {
                notification.destroy();
                notification.success({
                    message: success.data.message,
                    placement: "topRight",
                });
            })
            .catch((err) => console.log(err));
    };

    // Activate / Deactivate Template - Sub Level
    SubTemplateActivation = async (e) => {
        const { value, checked } = e.target;
        this.setState({ ["subactive_for_" + value]: checked });
        await axios
            .post(
                SUB_TEMPLATE_ACTIVATION,
                qs.stringify({
                    template_id: value,
                    active: checked == true ? "Y" : "N",
                })
            )
            .then((success) => {
                notification.destroy();
                notification.success({
                    message: success.data.message,
                    placement: "topRight",
                });
            })
            .catch((err) => console.log(err));
    };

    TemplateOnFilter = (e) => {
        e.preventDefault();
        this.setState({ template_name: e.target.value }, () => {
            this.templateFilterHandler();
        });
    };
    TemplateOndelete(val) {
        axios
            .post(
                DELETE_TEMPLATE,
                qs.stringify({
                    template_id: parseInt(val),
                })
            )
            .then((success) => {
                this.templateFilterHandler();
            })
            .catch((err) => console.log(err));
    }
    TemplateOnFavourite(val, index) {
        if (this.is_favoriteArr[index]) {
            this.is_favoriteArr[index] = 0;
        } else {
            this.is_favoriteArr[index] = 1;
        }
        this.setState({ $isTestFavCheckedVal: this.is_favoriteArr });
        axios
            .post(
                FAVORITE_TEMPLATE,
                qs.stringify({
                    template_id: parseInt(val),
                })
            )
            .then((success) => {
                this.setState(
                    { $isTestFavCheckedVal: this.is_favoriteArr },
                    () => {
                        this.templateFilterHandler();
                    }
                );
            })
            .catch((err) => {
                console.log(err);
                if (!this.is_favoriteArr[index]) {
                    this.is_favoriteArr[index] = 1;
                } else {
                    this.is_favoriteArr[index] = 0;
                }
                this.setState({ $isTestFavCheckedVal: this.is_favoriteArr });
            });
    }
    TemplateOnView = (templateId) => {
        axios
            .post(
                VIEW_TEMPLATE,
                qs.stringify({
                    id: templateId,
                })
            )
            .then((success) => {
                this.setState({ templateView: success.data.data });
            })
            .catch((err) => console.log(err));
    };

    templateViewDiagnosis() {
        if (
            !this.state.templateView.diagnosisList ||
            this.state.templateView.diagnosisList.length === 0
        ) {
            return null;
        }
        return (
            <Fragment>
                <h4 className="page-title mt-4">Assessment</h4>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th width={40 + "%"}>DIAGNOSIS</th>
                            <th width="100">ICD 10</th>
                            <th width="300">NOTES</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.templateView.diagnosisList.map((val, i) => {
                            return (
                                <tr key={i}>
                                    <td>{val.description}</td>
                                    <td>{val.icd_code ? val.icd_code : ""}</td>
                                    <td>
                                        {val.diagnosis_notes
                                            ? val.diagnosis_notes
                                            : ""}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Fragment>
        );
    }
    templateViewInstruction() {
        if (
            !this.state.templateView.templateInstruction ||
            this.state.templateView.templateInstruction.length === 0
        ) {
            return null;
        }
        return (
            <Fragment>
                <h4 className="page-title mt-4">Instructions</h4>
                <div className="row mt-2">
                    <div className="col-10">
                        <ul className="custom-list list-success">
                            <InstructionTable
                                instructionsData={
                                    this.state.templateView.templateInstruction
                                }
                                viewType={"full"}
                                constants={null}
                            />
                        </ul>
                    </div>
                </div>
            </Fragment>
        );
    }

    templateViewMaterial() {
        let materialData = [];
        if (
            !this.state.templateView.supportingMaterial ||
            this.state.templateView.supportingMaterial.length === 0
        ) {
            return null;
        }else{
            this.state.templateView.supportingMaterial.map(file => {
                let data = {
                    id: file.id,
                    templateId: file.template_id,
                    fileUrl: file.file_url,
                    fileName: file.file_name
                }
                materialData.push(data);
            })
            return (
                // <Fragment>
                //     <h4 className="page-title mt-4">Supporting Material</h4>
                //     <div className="row mt-2">
                //         <div className="col-10">
                //                 <SupportingMaterialTable
                //                     supportingMaterialData={materialData}
                //                     viewType={'full'}
                //                     hospitalId={this.state.hospital_id}
                //                     constants={this.state.constants} />
                //         </div>
                //     </div>
                // </Fragment>
                <></>
            );
        } 
    }

    templateViewtests() {
        if (
            !this.state.templateView.testList ||
            this.state.templateView.testList.length === 0
        ) {
            return null;
        }
        return (
            <Fragment>
                <h4 className="page-title mt-4">Tests</h4>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            {" "}
                            <th width={40 + "%"}>TEST NAME</th>
                            <th>INSTRUCTIONS</th>
                            {this.state.templateView?.template?.isEventBased && this.state.templateView?.template?.isEventBased == 'Y' ? <th>DUE FROM</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.templateView.testList.map((val, i) => {
                            return (
                                <tr key={i}>
                                    <td>{val.test_name}</td>
                                    <td>
                                        {val.instructions
                                            ? val.instructions
                                            : ""}
                                    </td>
                                    {this.state.templateView?.template?.isEventBased && this.state.templateView?.template?.isEventBased == 'Y' ?
                                        <td>{val.event !== null ? this.state.templateView.constants.drugEvent.map((data1, i) => data1.id === val.event ? data1.description : null) : ''} {val.event == 1 || val.event == 2 ? val.lmp_event_duration : null} {val.event == 1 || val.event == 2 ? val.lmp_event_duration_type : null}</td>
                                        : null}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Fragment>
        );
    }
    templateViewDrugs() {
        if (
            !this.state.templateView.drugList ||
            this.state.templateView.drugList.length === 0
        ) {
            return null;
        }
        return (
            <Fragment>
                <h4 className="page-title">Medicines</h4>
                <MedicineTable
                    drugsData={this.state.templateView.drugList}
                    viewType={"list-visit"}
                    eventBased={this.state.templateView?.template?.isEventBased && this.state.templateView?.template?.isEventBased == 'Y' ? true : false}
                    showAction={false}
                    showSelectCheck={false}
                    constants={this.state.templateView.constants}
                />
            </Fragment>
        );
    }

    selectTemplates = (e) => {
        const selectedValue = e.target.value;
        const checkedTemplate = e.target.checked;
        const selectedTemplateId = this.state.selectedTemplates || [];

        if (checkedTemplate) {
            selectedTemplateId.push(selectedValue);
        } else {
            const index = selectedTemplateId.indexOf(selectedValue);
            if (index > -1) {
                selectedTemplateId.splice(index, 1);
            }
        }
        this.setState({
            selectedTemplates: selectedTemplateId,
        });
        this.saveButtonDisableHandler();
    };

    activateTemplatesHandler = (e) => {
        const selectedValue = e.target.value;
        const uncheckedTemplate = e.target.checked;
        const deactivatedTemplateId =
            this.state.activeTemplates.indexOf(selectedValue);
        if (!uncheckedTemplate && deactivatedTemplateId === -1) {
            this.setState({
                activeTemplates: [...this.state.activeTemplates, selectedValue],
            });
        }

        if (uncheckedTemplate && deactivatedTemplateId === -1) {
            this.setState({
                activeTemplates: this.state.activeTemplates.splice(
                    deactivatedTemplateId,
                    1
                ),
            });
        }

        //console.log("this.state.activeTemplates: ", this.state.activeTemplates);
    };

    //hospital list
    // hospitalList = async (search) => {
    //     let PARAMS = { hospital_id: 0 };

    //     if (this.state.isAdmin) {
    //         PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
    //     }
    //     console.log("type :", PARAMS);
    //     await axios
    //         .post(HOSPITAL_AGENT_LIST, qs.stringify(PARAMS))
    //         .then((res) => {
    //             var hospital_list = [];
    //             if (this.state.isAdmin) {
    //                 for (var hospital of res.data.data.results) {
    //                     if (
    //                         hospital.hospital_id ==
    //                         LS_SERVICE.get("admin_hospital_id")
    //                     ) {
    //                         hospital_list.push(hospital);
    //                     }
    //                 }
    //             } else {
    //                 hospital_list = res.data.data.results;
    //             }

    //             this.setState({
    //                 hospitalList: res.data.status ? hospital_list : [],
    //             });
    //         });
    // };

    hospitalList = async () => {
        let PARAMS = {};
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
                hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }
        await axios.post(HOSPITAL_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
            this.setState({
                hospitalList: res?.data?.data?.results ? res?.data?.data?.results : [],
            });
        });
    };


    getActiveHospitalIds() {
        if (this.state.hospitalList.length > 0) {
            this.state.hospitalList.map((template) => {
                this.state.activeTemplates.push(template.template_id);
            });
        }
    }

    checkHospitalFilter(template) {
        //console.log("template :", template);
        // let hospital_ids = [];
        // if (template && template?.hospital_id) {
        //     hospital_ids = template?.hospital_id.split(",").map(id => {
        //         this.state.hospitalList()
        //     });
        //     console.log("hospital_ids :", hospital_ids);
        //     return hospital_ids.includes((this.state.hospital_id + "").trim());
        // }
        // return false;

        if (template && template?.hospital_id) {
            const hospital_ids = template?.hospital_id.split(",");
            const arr = hospital_ids.filter((val) => val);
            if (arr.length > 0) {
                return true;
            }
        }
        return false;
    }

    checkActiveHospitalFilter(template) {
        if (template && template?.deactivatedHospitalId) {
            const hospital_ids = template?.deactivatedHospitalId.split(",");
            const arr = hospital_ids.filter((val) => val);
            if (arr.length > 0) {
                return false;
            }
        }
        return true;
    }

    showTemplateData() {
        if (this.state.templateList && this.state.templateList.length > 0) {
            return this.state.templateList.map((template, i) => {
                var hospital = [];
                var deactivatehospital = [];
                var hospital_array = template?.hospital_id?.split(",").map((x) => +x) || [];
                var deactivatehospital_array = template?.deactivatedHospitalId?.split(",").map((x) => +x) || [];
                if (hospital_array && hospital_array.length > 0) {
                    this.state?.hospitalList?.find(function (
                        this_hospital,
                        index
                    ) {
                        for (var hosp of hospital_array) {
                            if (this_hospital.hospital_id == hosp) {
                                if (deactivatehospital_array.includes(hosp)) {
                                    deactivatehospital.push(this_hospital.name);
                                } else {
                                    hospital.push(this_hospital.name);
                                }
                            }
                        }
                    });
                    if (hospital_array[0] == -1) { hospital.push("MHC Hospitals") }
                }
                if (hospital?.length == 0) {
                    hospital.push("MHC Hospitals")
                }

                var doctor = [];
                var doctor_array = [];
                if (template?.doctor_id != null && template?.doctor_id != "") {
                    doctor_array = template?.doctor_id?.split(",").map((x) => +x);
                }

                if (doctor_array && doctor_array.length > 0) {
                    this.state?.allDoctorList?.find(function (this_doctor, index) {
                        for (var hosp of doctor_array) {
                            if (this_doctor.staff_id == hosp) {
                                doctor.push(this_doctor.first_name);
                            }
                        }
                    });
                }

                var speciality = [];
                var speciality_array = [];
                if (template?.speciality_id != null && template?.speciality_id != "") {
                    speciality_array = template?.speciality_id?.split(",").map((x) => +x);
                }
                if (speciality_array && speciality_array.length > 0) {
                    this.state?.allSpecialityList?.find(function (
                        this_speciality,
                        index
                    ) {
                        for (var hosp of speciality_array) {
                            if (this_speciality.speciality_id == hosp) {
                                speciality.push(this_speciality.name);
                            }
                        }
                    });
                }
                if (speciality.length == 0) {
                    speciality.push('All Specialities');
                }

                //console.log("template id: ", template.template_id);
                return template.name !== "" && template.name !== null ? (
                    <Fragment key={i}>
                        <tr>
                            <td>{template.name}</td>
                            <td>
                                {template.diagnosis_data.map((icd, f) => {
                                    icd.description = ((DIAGNOSIS_DO_NOT_PRINT.includes(icd.diagnosis_id) == true || !icd.icd_code || icd.icd_code == "" || icd.icd_code == null) && icd?.diagnosis_notes != '')? icd?.diagnosis_notes : icd.description;
                                    return (
                                        <div className="row" key={f}>
                                            <div className="col-lg-12">{icd.description}</div>
                                        </div>
                                    )
                                })}
                            </td>
                            <td>
                                {/* {template.diagnosis_icd_code} */}
                                {template.diagnosis_data.map((icd, f) => {
                                    return (
                                        <div className="row" key={f}>
                                            <div className="col-lg-12 text-secondary">{icd.icd_code}</div>
                                        </div>
                                    )
                                })}
                            </td>
                            {this.state.isSuperAdmin && (hospital?.length > 0 || deactivatehospital?.length > 0 ? (
                                <td>
                                    <ul>
                                        {hospital?.length > 0 ? (
                                            hospital.map((hosp, i) => {
                                                return <li>{hosp}</li>
                                            })
                                        )
                                            : null}
                                        {deactivatehospital?.length > 0 ? (
                                            deactivatehospital.map((dhosp, i) => {
                                                return <li><p style={{ color: "#b2b5b8" }}>{dhosp}</p></li>
                                            })
                                        )
                                            : null}
                                    </ul>
                                </td>
                            ) : (
                                <td style={{ textAlign: "center" }}>-</td>
                            ))}
                            {(this.state.isSuperAdmin || this.state.isAdmin) &&
                                (speciality && speciality != "" ? (
                                    <td>
                                        <ul>
                                            {speciality?.length > 0 ? (
                                                speciality.map((sp, i) => {
                                                    return <li>{sp}</li>
                                                })
                                            )
                                                : null}
                                        </ul>
                                    </td>
                                ) : (
                                    <td style={{ textAlign: "center" }}>-</td>
                                ))}

                            {(this.state.isSuperAdmin || this.state.isAdmin) &&
                                (doctor && doctor != "" ? (
                                    <td>
                                        <ul>
                                            {doctor?.length > 0 ? (
                                                doctor.map((doc, i) => {
                                                    return <li>{doc}</li>
                                                })
                                            )
                                                : null}
                                        </ul>
                                    </td>
                                ) : (
                                    <td style={{ textAlign: "center" }}>-</td>
                                ))}

                            <td style={{ textAlign: "center" }}>
                                <Button
                                    type="link p-0"
                                    className="nostyle-link view_order_set"
                                    onClick={() =>
                                        this.openView(template.template_id)
                                    }
                                >
                                    <i className="icon_view"></i>
                                </Button>
                            </td>
                            {this.state.isDoctor && (
                                <td style={{ textAlign: "center" }}>
                                    <Button
                                        type="link p-0"
                                        className="nostyle-link"
                                        onClick={(e) =>
                                            this.TemplateOnFavourite(
                                                template.template_id,
                                                i
                                            )
                                        }
                                    >
                                        <i
                                            /* className={
                                                this.state.$isTestFavCheckedVal[
                                                    i
                                                ] === 1
                                                    ? "icon_star favourite"
                                                    : "icon_star star"
                                            } */
                                            className={
                                                template.list_data === 1
                                                    ? "icon_star favourite"
                                                    : "icon_star star"
                                            }
                                        ></i>
                                    </Button>
                                </td>
                            )}
                            {this.state.isSuperAdmin && (
                                <td style={{ textAlign: "center" }}>
                                    <Checkbox
                                        // checked={true}
                                        onChange={this.ParentTemplateActivation}
                                        checked={
                                            this.state[
                                            "active_for_" +
                                            template.template_id
                                            ]
                                        }
                                        name={
                                            "active_for_" + template.template_id
                                        }
                                        value={template.template_id}
                                    ></Checkbox>
                                </td>
                            )}

                            {this.state.isAdmin && (
                                <td style={{ textAlign: "center" }}>
                                    <Checkbox
                                        // checked={true}
                                        onChange={this.SubTemplateActivation}
                                        checked={
                                            this.state[
                                            "subactive_for_" +
                                            template.template_id
                                            ]
                                        }
                                        name={
                                            "subactive_for_" +
                                            template.template_id
                                        }
                                        value={template.template_id}
                                    ></Checkbox>
                                </td>
                            )}
                            {this.state.isSuperAdmin && (
                                <td style={{ textAlign: "center" }}>
                                    <Checkbox
                                        disabled={template.own_master == 'Y'}
                                        value={template.template_id}
                                        onChange={this.selectTemplates}
                                    ></Checkbox>
                                </td>
                            )}
                            <td style={{ textAlign: "center" }}>
                                <Link
                                    to={{
                                        pathname:
                                            "/template/edit/" +
                                            template.template_id,
                                        state: { template },
                                    }}
                                    className="nostyle-link mr-2"
                                >
                                    <i className="icon-edit"></i>
                                </Link>
                                {this.state.isDoctor && (
                                    <Button
                                        type="link p-0"
                                        className="nostyle-link data-delete"
                                        onClick={() => {
                                            this.setState({
                                                templateId:
                                                    template.template_id,
                                            });
                                            this.showModal();
                                        }}
                                    >
                                        <i className="icon_delete"></i>
                                    </Button>
                                )}
                            </td>
                        </tr>
                        <tr
                            className="view_details"
                            style={
                                this.state.viewNo === template.template_id
                                    ? { display: "table-row" }
                                    : { display: "none" }
                            }
                        >
                            <td colSpan="12" className="inner_table">
                                <div className="text-center">
                                    {this.state.templateView.length === 0
                                        ? LOADER_RED
                                        : null}
                                </div>
                                {this.templateViewDiagnosis()}
                                {this.templateViewDrugs()}
                                {this.templateViewtests()}
                                {this.templateViewInstruction()}
                                {this.templateViewMaterial()}
                            </td>
                        </tr>
                    </Fragment>
                ) : null;
            });
        } else {
            return null;
        }
    }

    templateRowLoad() {
        // if (!this.state.templateList || this.state.templateList.length === 0) {
        //     return null;
        // }
        if (
            this.state.templateList.message !== undefined ||
            this.state.templateList.message === "Not Found!"
        ) {
            return (
                <table className="table table-default">
                    <thead>
                        <tr>
                            <th>CARE PROTOCOL NAME</th>
                            <th>DIAGNOSIS</th>
                            <th width="80">ICD 10</th>
                            {this.state.isSuperAdmin && (
                                <th width="200">HOSPITAL</th>
                            )}
                            {(this.state.isSuperAdmin ||
                                this.state.isAdmin) && (
                                    <th width="200">SPECIALITY</th>
                                )}
                            {(this.state.isSuperAdmin ||
                                this.state.isAdmin) && (
                                    <th width="200">DOCTOR</th>
                                )}
                            <th width="60">View</th>
                            {this.state.isDoctor && (
                                <th width="60">Favourite</th>
                            )}
                            {(this.state.isSuperAdmin ||
                                this.state.isAdmin) && (
                                    <th width="60">Active</th>
                                )}
                            {this.state.isSuperAdmin && (
                                <th width="60">SELECT</th>
                            )}
                            <th width="100">Action</th>

                        </tr>
                    </thead>
                    <tbody id="template-list">
                        <tr>
                            <td
                                colSpan={this.state.isSuperAdmin ? "10" : this.state.isAdmin ? "8" : "6"}
                            >
                                No Records Found.
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
        return (
            <table className="table table-default">
                <thead>
                    <tr>
                        <th>CARE PROTOCOL NAME</th>
                        <th>DIAGNOSIS</th>
                        <th width="80">ICD 10</th>
                        {(this.state.isSuperAdmin) && (
                            <th width="200">HOSPITAL</th>
                        )}
                        {(this.state.isSuperAdmin || this.state.isAdmin) && (
                            <th width="200">SPECIALITY</th>
                        )}
                        {(this.state.isSuperAdmin || this.state.isAdmin) && (
                            <th width="200">DOCTOR</th>
                        )}
                        <th width="60">View</th>
                        {this.state.isDoctor && (
                            <th width="60">Favourite</th>
                        )}
                        {(this.state.isSuperAdmin || this.state.isAdmin) && (
                            <th width="60">Active</th>
                        )}
                        {this.state.isSuperAdmin && <th width="60">SELECT</th>}
                        <th width="100">Action</th>
                    </tr>
                </thead>
                <tbody id="template-list">{this.showLoader()}</tbody>
            </table>
        );
    }

    openView(no) {
        if (this.state.viewNo === no) {
            this.setState({ viewNo: 0, templateView: [] });
        } else {
            this.setState({ viewNo: no, templateView: [] });
            this.TemplateOnView(no);
        }
    }
    async componentDidMount() {
        axios.defaults.headers.common["Authorization"] =
            LS_SERVICE.get("token");

        // if (this.state.isAdmin || this.state.isSuperAdmin) {
        //     this.specialityList();
        //     this.doctorList();
        //     this.hospitalList();
        // }
        if (LS_SERVICE.get("user_type") == USERTYPES.superadmin || LS_SERVICE.get("user_type") == USERTYPES.admin) {
            await this.hospitalList();
            await this.specialityList();
            await this.doctorList();
        }
        if (LS_SERVICE.get("user_type") == USERTYPES.doctor) {
            await this.setState({
                hospital_id: LS_SERVICE.get("slot_hospital_id"),
                speciality_id: LS_SERVICE.get("speciality_id"),
            });
        }
        this.allSpecialityList();
        this.allDoctorList();

        jQueryLabels();
        this.templateFilterHandler();
    }

    //speciality list
    // specialityList = () => {
    //     let PARAMS = { hospital_id: 0 };
    //     if (this.state.isAdmin) {
    //         PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
    //     }
    //     axios.post(SPECIALITY_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
    //         this.setState({
    //             specialityList: res.data.status ? res.data.data.results : [],
    //         });
    //     });
    // };

    specialityList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
                hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }

        if (this.state.hospital_id == -1) {
            await axios.post(ALL_SPECIALITY_AGENT_LIST).then((res) => {
                this.setState({
                    specialityList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else {
            if (this.state.hospital_id) {
                PARAMS = {
                    hospital_id: this.state.hospital_id
                };
                await axios.post(SPECIALITY_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                    this.setState({
                        specialityList: res.data.status ? res.data.data.results : [],
                    });
                });
            }
            else {
                this.setState({
                    specialityList: [],
                });
            }
        }
    };

    allSpecialityList = async () => {
        var PARAMS = {};
        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await axios.post(SPECIALITY_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                    allSpecialityList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else {
            await axios.post(ALL_SPECIALITY_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                    allSpecialityList: res.data.status ? res.data.data.results : [],
                });
            });
        }
    };

    selectSpecialityList() {
        if (this.state.specialityList) {
            return this.state.specialityList.map((obj, i) => {
                return (
                    <Option key={i} value={obj.speciality_id}>
                        {obj.name}
                    </Option>
                );
            });
        }
    }
    // selectSpecialityChange = (val) => {
    //     this.setState({ speciality_id: val }, () => {
    //     });
    // };

    selectSpecialityChange = async (val) => {
        await this.setState({
            speciality_id: val ? val : "",
        })
        if (this.state.speciality_id) {
            var final_doctor = [];
            var final_owner = [];
            await this.doctorList();
            var doctor_array = this.state.doctorList;
            if (doctor_array && doctor_array.length > 0) {
                if (this.state.doctor_id && this.state.doctor_id != "") {
                    var DoctorDefaultValue = this.state.doctor_id?.split(',');
                    for (var doc of DoctorDefaultValue) {
                        if (doctor_array.some(el => el.staff_id === doc)) {
                            final_doctor.push(doc)
                        }
                    }
                }
                if (this.state.owner_id && this.state.owner_id != "") {
                    var OwnerDefaultValue = this.state.owner_id?.split(',');
                    for (var own of OwnerDefaultValue) {
                        if (doctor_array.some(el => el.staff_id === own)) {
                            final_owner.push(doc)
                        }
                    }
                }
            }
            await this.setState({
                doctor_id: final_doctor.join(),
                owner_id: final_owner.join(),
            });
        }
        else {
            await this.setState({
                doctor_id: "",
                owner_id: "",
                doctorList: [],
            });
        }
        this.templateFilterHandler();
    };

    //doctor list
    // doctorList = (search) => {
    //     const PARAMS = { search };
    //     axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
    //         this.setState({
    //             doctorList: res.data.status ? res.data.data.results : [],
    //         });
    //     });
    // };

    doctorList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
            await this.setState({
                hospital_id: LS_SERVICE.get("admin_hospital_id"),
            });
        }

        if (this.state.hospital_id == -1 && this.state.speciality_id) {
            PARAMS = {
                speciality_id: this.state.speciality_id
            }
            await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                var final_doctor = [];
                for (var doc of res?.data?.data?.results) {
                    final_doctor.push(doc)
                }
                this.setState({
                    doctorList: final_doctor,
                });
            });
        }
        else if (this.state.hospital_id && this.state.speciality_id) {
            PARAMS = {
                hospital_id: this.state.hospital_id,
                speciality_id: this.state.speciality_id
            };

            await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                this.setState({
                    doctorList: res.data.status ? res.data.data.results : [],
                });
            });
        }
        else if (this.state.hospital_id && !this.state.speciality_id) {
            if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
                if (this.state.hospital_id == -1) {
                    await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                        this.setState({
                            doctorList: res?.data?.data?.results || [],
                        });
                    });
                }
                else {
                    PARAMS = { hospital_id: this.state.hospital_id };
                    await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                        this.setState({
                            doctorList: res?.data?.data?.results || [],
                        });
                    });
                }
            }
            else {
                PARAMS = { hospital_id: this.state.hospital_id };
                await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
                    this.setState({
                        doctorList: res?.data?.data?.results || [],
                    });
                });
            }
        }
        // else if(!this.state.hospital_id && !this.state.speciality_id && this.state.doctor_id){
        //     if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
        //         await axios.post(DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
        //             var final_doctor = [];
        //             for(var doc of res?.data?.data?.results){
        //                 if(doc.staff_id == this.state.doctor_id) {
        //                     final_doctor.push(doc)
        //                 }
        //             }
        //             this.setState({
        //                 doctorList: final_doctor,
        //             });
        //         });
        //     }
        // }
        else {
            this.setState({
                doctorList: [],
            });
        }
        var final_doctor = [];
        var final_owner = [];
        var doctor_array = this.state.doctorList;
        if (doctor_array && doctor_array.length > 0) {
            console.log(this.state.doctor_id);

            var DoctorDefaultValue = this.state.doctor_id;
            for (var doc of DoctorDefaultValue) {
                if (doctor_array.some(el => el.staff_id === doc)) {
                    final_doctor.push(doc)
                }
            }
            var OwnerDefaultValue = this.state.owner_id;
            for (var own of OwnerDefaultValue) {
                if (doctor_array.some(el => el.staff_id === own)) {
                    final_owner.push(own)
                }
            }
        }
        await this.setState({
            doctor_id: final_doctor.join(),
            DoctorDefaultValue: final_doctor,
            owner_id: final_owner.join(),
            OwnerDefaultValue: final_owner
        });
    };

    allDoctorList = async () => {
        var PARAMS = {};

        if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
            PARAMS = { hospital_id: LS_SERVICE.get("admin_hospital_id") };
        }

        await axios.post(ALL_DOCTOR_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
            this.setState({
                allDoctorList: res.data.status ? res.data.data.results : [],
            });
        });
    };

    selectDoctorList() {
        if (this.state.doctorList) {
            return this.state.doctorList.map((obj, i) => {
                if (!("last_name" in obj)) {
                    return (
                        <Option dataid={obj} key={i} value={obj.staff_id}>
                            {obj.first_name} {obj.woodlands_mobile ? '(' + obj.woodlands_mobile + ')' : null}
                        </Option>
                    );
                }
                else {
                    return (
                        <Option key={i} value={obj.staff_id}>
                            Dr. {obj.first_name} {obj.last_name} {obj.woodlands_mobile ? '(' + obj.woodlands_mobile + ')' : null}
                        </Option>
                    );
                }
            });
        }
    }
    selectDoctorChange = (val) => {
        this.setState({ doctor_id: val }, () => {
            this.templateFilterHandler();
        });
    };
    //owner change
    selectOwnerChange = (val) => {
        this.setState({ owner_id: val }, () => {
            this.templateFilterHandler();
        });
    };

    //hospital list
    // hospitalList = (search) => {
    //     const PARAMS = { search };
    //     axios.post(HOSPITAL_AGENT_LIST, qs.stringify(PARAMS)).then((res) => {
    //         this.setState({
    //             hospitalList: res.data.status ? res.data.data.results : [],
    //         });
    //     });
    // };

    selectHospitalList() {
        if (this.state.hospitalList) {
            return this.state.hospitalList.map((obj, i) => {
                return (
                    <Option key={i} value={obj.hospital_id} own_master={obj.own_master}>
                        {obj.name}
                    </Option>
                );
            });
        } else {
            return null;
        }
    }
    selectHospitalListMap() {
        if (this.state.hospitalList) {
            return this.state.hospitalList.map((obj, i) => {
                return (
                    obj.own_master == 'N' ?
                        <Option key={i} value={obj.hospital_id} own_master={obj.own_master}>
                            {obj.name}
                        </Option>
                        : null
                );
            });
        } else {
            return null;
        }
    }
    // selectHospitalChange = async (val) => {
    //     await this.setState({
    //         hospital_id: val ? val : "",
    //     });
    //     var allowSave = true;
    //     if (val == "") allowSave = false;
    //     if (val && val.length == 0) allowSave = false;
    //     this.setState({ disableConfirmBtn: !allowSave });
    // };

    selectHospitalChange = async (val, props) => {
        await this.setState({
            hospital_id: val ? val : "",
            hospital_master: props ? props.own_master : 'N'
        });
        // if(this.state.hospital_id && this.state.hospital_id == -1){

        // }
        // else{
        //     await this.setState({
        //         speciality_id: "",
        //         doctor_id: "",
        //         owner_id: "",
        //         specialityList: [],
        //         doctorList: [],
        //     });
        // }

        var final_speciality = [];
        await this.specialityList();
        var speciality_array = this.state.specialityList
        if (speciality_array && speciality_array.length > 0) {
            if (this.state.speciality_id && this.state.speciality_id != "") {
                var SpecialityDefaultValue = this.state?.speciality_id?.split(',');
                for (var sp of SpecialityDefaultValue) {
                    if (speciality_array.some(el => el.speciality_id === sp)) {
                        final_speciality.push(sp)
                    }
                }
            }
        }
        await this.setState({
            speciality_id: final_speciality.join(),
        });

        var final_doctor = [];
        var final_owner = [];
        await this.doctorList();
        var doctor_array = this.state.doctorList;
        if (doctor_array && doctor_array.length > 0) {
            if (this.state.doctor_id && this.state.doctor_id != "") {
                var DoctorDefaultValue = this.state.doctor_id?.split(',');
                for (var doc of DoctorDefaultValue) {
                    if (doctor_array.some(el => el.staff_id === doc)) {
                        final_doctor.push(doc)
                    }
                }
            }
            if (this.state.owner_id && this.state.owner_id != "") {
                var OwnerDefaultValue = this.state.owner_id?.split(',');;
                for (var own of OwnerDefaultValue) {
                    if (doctor_array.some(el => el.staff_id === own)) {
                        final_owner.push(doc)
                    }
                }
            }
        }
        await this.setState({
            doctor_id: final_doctor.join(),
            owner_id: final_owner.join(),
        });

        this.templateFilterHandler();
    };

    selectMapToHospitalChange = async (val) => {
        await this.setState({
            mapToHospital: val ? val : "",
        });
        var allowSave = true;
        if (val == "") allowSave = false;
        if (val && val.length == 0) allowSave = false;
        this.setState({ disableConfirmBtn: !allowSave });
        this.saveButtonDisableHandler();
    };

    templateFilterHandler() {
        this.setState({ isDataFetching: true, isDataLoaded: false });
        let params = {
            hospital_id: this.state.hospital_id,
            owner_id: this.state.owner_id,
            doctor_id: this.state.doctor_id,
            speciality_id: this.state.speciality_id,
            template_name: this.state.template_name,
            logined_id: this.state.login_id,
            login_role: this.getUserRole(),
            hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
        };
        if (this.state.isSuperAdmin) {
            params.hospital_master = this.state.hospital_master
        }
        axios
            .post(
                TEMPLATE_FILTER,
                qs.stringify(params)
            )
            .then((success) => {
                if (
                    success.data.status &&
                    success.data.message !== "Not Found!"
                ) {
                    success.data.data.templates.forEach((data, i) => {
                        this.is_favoriteArr.push(data.list_data);
                    });
                    this.setState({
                        templateList: success.data.data.templates,
                        $isTestFavCheckedVal: this.is_favoriteArr,
                    });

                    success.data.data.templates.forEach((template, i) => {
                        this.is_favoriteArr.push(template.is_favorite);

                        if (this.state.isSuperAdmin) {
                            this.setState({
                                ["active_for_" + template.template_id]:
                                    template.active == "Y" ? true : false,
                            });
                        } else if (this.state.isAdmin) {
                            if (
                                template.deactivatedHospitalId &&
                                template.deactivatedHospitalId != ""
                            ) {
                                var deactivatedHospitals =
                                    template.deactivatedHospitalId
                                        .split(",")
                                        .map((x) => +x) || [];

                                if (
                                    deactivatedHospitals.includes(
                                        LS_SERVICE.get("admin_hospital_id")
                                    )
                                ) {
                                    this.setState({
                                        ["subactive_for_" +
                                            template.template_id]: false,
                                    });
                                } else {
                                    this.setState({
                                        ["subactive_for_" +
                                            template.template_id]: true,
                                    });
                                }
                            } else {
                                this.setState({
                                    ["subactive_for_" +
                                        template.template_id]: true,
                                });
                            }
                        }
                    });
                    // this.getActiveHospitalIds();
                } else {
                    this.setState({ templateList: success.data });
                }
                this.setState({ isDataFetching: false, isDataLoaded: true });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isDataFetching: false, isDataLoaded: true });
            });
    }

    getUserRole() {
        let role = "";

        if (this.state.isAdmin) {
            role = "admin";
        } else if (this.state.isSuperAdmin) {
            role = "superadmin";
        } else {
            role = "doctor";
        }
        return role;
    }

    templateSaveHandler(e) {
        e.preventDefault();

        this.setState({ isDataFetching: true, isDataLoaded: false });
        axios
            .post(
                SAVE_TEMPLATELIST,
                qs.stringify({
                    selectedTemplates: this.state.selectedTemplates,
                    activeTemplates: this.state.activeTemplates,
                    map_to_hospital_id: this.state.mapToHospital,
                })
            )
            .then((success) => {
                //console.log("saved successfully.");
                this.setState({ isDataFetching: false, isDataLoaded: true });
                this.templateFilterHandler();
                notification.success({
                    message: "Care protocol mapped to the selected hospital",
                    placement: "topRight",
                });
                //this.setState({ selectedTemplates: [] });
                //this.setState({ activeTemplates: [] });
                this.setState({ mapToHospital: [] });
            })
            .catch((err) => {
                console.log(err);
                this.setState({ isDataFetching: false, isDataLoaded: true });
                //this.setState({ selectedTemplates: [] });
                //this.setState({ activeTemplates: [] });
                //this.setState({ map_to_hospital_id: "" });
                this.templateFilterHandler();
                this.setState({ mapToHospital: [] });
            });
    }

    showLoader() {
        let colSpan = 11;
        if (this.state.isAdmin) {
            colSpan = 8;
        } else if (this.state.doctor) {
            colSpan = 6;
        }

        if (this.state.isDataFetching && !this.state.isDataLoaded) {
            return (
                <tr>
                    <td colSpan={colSpan}>
                        <div className="text-center">{LOADER_RED}</div>
                        {/* No Records Found. */}
                    </td>
                </tr>
            );
        } else {
            // return this.templateRowLoad();
            return this.showTemplateData();
        }
    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row" id="data-preloader">
                        {this.state.templateList.length > 0 ||
                            this.state.templateList.status ? (
                            <>
                                <div className="col-lg-12 emrfrm">
                                    <div className="px-2">
                                        {(this.state.isAdmin ||
                                            this.state.isSuperAdmin) && (
                                                <>
                                                    <h1 className="page-title mb-3">
                                                        Care Protocol
                                                    </h1>
                                                    <div className="row my-3 careprotocol__filterContainer">
                                                        <div className="col-12 col-md-3">
                                                            <div className="form-group hasdata">
                                                                <Select
                                                                    id="hospital__id"
                                                                    placeholder="Hospital"
                                                                    disabled={LS_SERVICE.get("user_type") == USERTYPES.admin ? true : false}
                                                                    name="hospital__id"
                                                                    style={{
                                                                        width:
                                                                            100 +
                                                                            "%",
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .selectHospitalChange
                                                                    }
                                                                    className="remove_search_icon"
                                                                    allowClear
                                                                    defaultValue={
                                                                        this.state
                                                                            .hospital__id
                                                                    }
                                                                    filterOption={(
                                                                        input,
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            option.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(
                                                                                    input
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                ) >=
                                                                            0
                                                                        );
                                                                    }}
                                                                    notFoundContent={
                                                                        this.state
                                                                            .spinner ? (
                                                                            <Spin size="small" />
                                                                        ) : (
                                                                            <div>
                                                                                No
                                                                                Result
                                                                                Found
                                                                            </div>
                                                                        )
                                                                    }
                                                                >
                                                                    <Option dataid={{ hospital_id: -1 }} value={-1} key={-1} own_master='N'>
                                                                        MHC Hospitals
                                                                    </Option>
                                                                    {this.selectHospitalList()}
                                                                </Select>
                                                                <label htmlFor="hospital__id">
                                                                    {" "}
                                                                    Hospital
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-3 ">
                                                            <div className="form-group hasdata">
                                                                <Select
                                                                    showSearch
                                                                    id="speciality_name"
                                                                    placeholder="Speciality"
                                                                    name="speciality_name"
                                                                    style={{
                                                                        width:
                                                                            100 +
                                                                            "%",
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .selectSpecialityChange
                                                                    }
                                                                    className="drug-input Priority"
                                                                    mode="multiple"
                                                                    /*   value={[1, 2, 3, 4]} */
                                                                    allowClear
                                                                    filterOption={(
                                                                        input,
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            option.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(
                                                                                    input
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                ) >=
                                                                            0
                                                                        );
                                                                    }}
                                                                    notFoundContent={
                                                                        this.state
                                                                            .spinner ? (
                                                                            <Spin size="small" />
                                                                        ) : (
                                                                            <div>
                                                                                No
                                                                                Result
                                                                                Found
                                                                            </div>
                                                                        )
                                                                    }
                                                                >
                                                                    {this.selectSpecialityList()}
                                                                </Select>
                                                                <label htmlFor="speciality_name">
                                                                    Speciality
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-3">
                                                            <div className="form-group hasdata">
                                                                <Select
                                                                    showSearch
                                                                    id="doctor_name"
                                                                    placeholder="Doctor's Name"
                                                                    name="doctor_name"
                                                                    style={{
                                                                        width:
                                                                            100 +
                                                                            "%",
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .selectDoctorChange
                                                                    }
                                                                    mode="multiple"
                                                                    allowClear
                                                                    filterOption={(
                                                                        input,
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            option.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(
                                                                                    input
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                ) >=
                                                                            0
                                                                        );
                                                                    }}
                                                                    notFoundContent={
                                                                        this.state
                                                                            .spinner ? (
                                                                            <Spin size="small" />
                                                                        ) : (
                                                                            <div>
                                                                                No
                                                                                Result
                                                                                Found
                                                                            </div>
                                                                        )
                                                                    }
                                                                >
                                                                    {this.selectDoctorList()}
                                                                </Select>
                                                                <label htmlFor="doctor_name">
                                                                    Doctor's Name
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-3">
                                                            <div className="form-group hasdata">
                                                                <Select
                                                                    showSearch
                                                                    id="owner_name"
                                                                    placeholder="Owner"
                                                                    name="owner_name"
                                                                    style={{
                                                                        width:
                                                                            100 +
                                                                            "%",
                                                                    }}
                                                                    onChange={
                                                                        this
                                                                            .selectOwnerChange
                                                                    }
                                                                    mode="multiple"
                                                                    allowClear
                                                                    filterOption={(
                                                                        input,
                                                                        option
                                                                    ) => {
                                                                        return (
                                                                            option.children
                                                                                .toString()
                                                                                .toLowerCase()
                                                                                .indexOf(
                                                                                    input
                                                                                        .toString()
                                                                                        .toLowerCase()
                                                                                ) >=
                                                                            0
                                                                        );
                                                                    }}
                                                                    notFoundContent={
                                                                        this.state
                                                                            .spinner ? (
                                                                            <Spin size="small" />
                                                                        ) : (
                                                                            <div>
                                                                                No
                                                                                Result
                                                                                Found
                                                                            </div>
                                                                        )
                                                                    }
                                                                >
                                                                    {this.selectDoctorList()}
                                                                </Select>
                                                                <label htmlFor="owner_name">
                                                                    Owner
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        <div className="form-group search-parent">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="search-template"
                                                placeholder="Search Care Protocol"
                                                onChange={this.TemplateOnFilter}
                                            />
                                            <label htmlFor="search-template">
                                                Search Care Protocol
                                            </label>
                                        </div>

                                        <div
                                            className="mt-3 table-responsive"
                                            id="order-set-tab"
                                        >
                                            {/* {this.showLoader()} */}
                                            {this.templateRowLoad()}
                                        </div>
                                        <div className="mt-5">&nbsp;</div>
                                    </div>

                                    <div
                                        className="row pl-2"
                                        style={{ marginBottom: "80px" }}
                                    >
                                        <div className="col-md-4">
                                            {this.state.isSuperAdmin && (
                                                <div className="form-group hasdata">
                                                    <Select
                                                        showSearch
                                                        mode="multiple"
                                                        id="careprotocol__map__toHospital"
                                                        name="careprotocol__map__toHospital"
                                                        style={{
                                                            width: 100 + "%",
                                                        }}
                                                        onChange={
                                                            this
                                                                .selectMapToHospitalChange
                                                        }
                                                        defaultValue={
                                                            this.state
                                                                .mapToHospital
                                                        }
                                                        value={
                                                            this.state
                                                                .mapToHospital
                                                        }
                                                        allowClear
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) => {
                                                            return (
                                                                option.children
                                                                    .toString()
                                                                    .toLowerCase()
                                                                    .indexOf(
                                                                        input
                                                                            .toString()
                                                                            .toLowerCase()
                                                                    ) >= 0
                                                            );
                                                        }}
                                                        notFoundContent={
                                                            this.state
                                                                .spinner ? (
                                                                <Spin size="small" />
                                                            ) : (
                                                                <div>
                                                                    No Result
                                                                    Found
                                                                </div>
                                                            )
                                                        }
                                                    >
                                                        {this.selectHospitalListMap()}
                                                    </Select>
                                                    <label htmlFor="careprotocol__map__toHospital">
                                                        Map TO Hospital
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className="col-md-8"
                                            style={{ textAlign: "right" }}
                                        >
                                            {this.state.isSuperAdmin && (
                                                <div className="form-group">
                                                    <Button
                                                        disabled={
                                                            this.state
                                                                .saveButton
                                                        }
                                                        className="btn btn-primary px-3"
                                                        onClick={(e) =>
                                                            this.templateSaveHandler(
                                                                e
                                                            )
                                                        }
                                                    >
                                                        Save
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            textAlign: "center",
                                            width: "100%",
                                            position: "fixed",
                                            bottom: "0",
                                            left: "0",
                                            backgroundColor: "white",
                                            padding: "12px 0",
                                            zIndex: 750,
                                        }}
                                    >
                                        <NavLink
                                            to="/create-template"
                                            className="btn btn-primary"
                                        >
                                            CREATE CARE PROTOCOL
                                        </NavLink>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="col-12">
                                <div className="text-center">{LOADER_RED}</div>
                            </div>
                        )}
                    </div>
                </div>

                <Modal
                    id="modalConfirm"
                    className="templateModal"
                    title={false}
                    visible={this.state.isModalVisible}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    closable={false}
                    footer={false}
                >
                    <div className="row mb-3">
                        <h6 className="col-12 text-center">
                            Are you sure you want to delete?
                        </h6>
                    </div>
                    <div className="modal-footer justify-content-center emrfrm pb-0">
                        <button
                            type="button"
                            className="btn btn-outline-secondary px-5  mr-2"
                            onClick={() => this.handleCancel()}
                        >
                            CANCEL
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary px-5"
                            onClick={() => this.handleOk()}
                        >
                            DELETE
                        </button>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}
export default TemplateList;
