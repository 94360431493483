import React, { Component } from 'react';
import { Select, Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import { Fragment } from 'react';
import { jQueryLabels, AntSelectLabels, ClearAntList, ClearSelectSelectionItem } from '../../utils/jQueryLabels';
import { REFERENCE_TYPE, TEMPLATE_SEARCH } from '../../utils/constant';
import AsideLeft from '../../components/aside/asideleft';
const { Option } = Select;
class TemplateSelect extends Component {

  constructor(props) {
    super(props)
    this.state = {
      templateList: [],
      patient: '',
      templateSelectModal: false,
      cfmDisabled: false,
      templateid: '',
      diagnosis_id: ''
    }
    this.templateselectRef = React.createRef()
  }
  componentDidMount() {
    window.addEventListener('load', (event) => {
      jQueryLabels();
    });
    jQueryLabels();
    this.getPatientData();
    this.TemplateOnListLoad()
    this.templateselectRef.current.focus();
  }
  showtemplateSelectModal = () => { this.setState({ templateSelectModal: true }); };
  templateSelectOk = () => {
    this.setState({ templateSelectModal: false });
  };
  templateSelectCancel = (e) => { this.setState({ templateSelectModal: false }); };
  getPatientData = () => {
    const patientData =  {
      hospital_id: this.props.match.params.hospital_id,
      patient_id: this.props.match.params.patient_id,
      qms_token_id: this.props.match.params.reference_id,
    };

    const patient = (this.props.history.location.state && this.props.history.location.state != null) ? this.props.history.location.state.patient : patientData;
    
    this.setState({
      patient: patient
    })
  }
  onSubmit = () => {
    const { templateid, patient } = this.state;
    const { hospital_id, qms_token_id, patient_id } = patient;
    if (templateid === '') {
      this.showtemplateSelectModal()
    }
    else {
      this.props.history.push({
        pathname: `/template/select/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}/${templateid}`,
        state: { patient }
      });
    }
  }
  optionDiagnosisLoad = () => {
    if (!this.state.templateList || this.state.templateList.length === 0) {
      return null
    }
    if (this.state.templateList.message !== undefined || this.state.templateList.message === "Not Found!") {
      return null
    }
    var template = []
    var fav_template = []
    for(var thistemp of this.state.templateList){
      if((!thistemp?.is_favorite) || thistemp?.is_favorite == null || thistemp?.is_favorite == undefined){
        thistemp.is_favorite = '0';
      }
      var fav_by_doctor = thistemp?.is_favorite?.split(',')?.map(Number);
      if(fav_by_doctor.includes(LS_SERVICE.get('staff_id'))){
        thistemp.is_favorite_status = 1;
        fav_template.push(thistemp)
      }
      else{
        thistemp.is_favorite_status = 0;
        template.push(thistemp)
      }
    }
    template = fav_template.concat(template);
    return template.map((obj, i) => {
      return (<Option key={i} value={obj.template_id} diagnosis_data={obj.diagnosis_data[0]} fav={obj.is_favorite} title={obj.name} description={obj.diagnosis_data[0].description}>
        <div className="row">
          <div className="col-lg-10"><div className="template-S1">{obj.diagnosis_data[0].description}</div>
            <div className="small template-S2">{obj.name}</div>
          </div>
          <div className="col-lg-2 text-right">
            <span className="template-S3">{obj.diagnosis_data[0].icd_code}</span>
            <i className={obj.is_favorite_status == 1 ? "icon_star ml-2 mr-2 mt-2 favourite" : "icon_star ml-2 mr-2 mt-2"}></i>
          </div>
        </div>
      </Option>
      )
    })
  }
  TemplateOnListLoad = () => {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    axios.post(TEMPLATE_SEARCH, qs.stringify({
      query: '',
      doctor_Id: LS_SERVICE.get('staff_id'),
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
      // list: 'all'
    })).then(success => {
      this.is_favoriteArr = [];
      if (success.data.status && success.data.message !== "Not Found!") {
        success.data.data.templates.forEach((data, i) => {
          this.is_favoriteArr.push(data.is_favorite);
        })
        let arr = success.data.data.templates
        this.setState({ templateList: arr, $isTestFavCheckedVal: this.is_favoriteArr }, () => { this.optionDiagnosisLoad() });
      }
      else {
        this.setState({ templateList: [] }, () => { this.optionDiagnosisLoad() });
      }
    }).catch(err => console.log(err))
  }
  onDiagnosiSelectChange = (value, props) => {
    this.setState({ templateid: value, diagnosis_id: '' })
    AntSelectLabels();
  }
  onDiagnosiSearch = (val) => {
    axios.post(TEMPLATE_SEARCH, qs.stringify({
      query: val,
      doctor_Id: LS_SERVICE.get('staff_id'),
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      list: 'all',
      hospital_master: LS_SERVICE.get('slot_hospital_master') ?? 'N',
    }))
      .then(success => {
        if (typeof success.data.data != 'undefined') {
          this.setState({ templateList: success.data.data.templates }, () => { this.optionDiagnosisLoad() });
        }
        else {
          this.setState({ templateList: [] }, () => { this.optionDiagnosisLoad() });
        }
      }).catch(err => console.log(err))
  }
  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  }
  render() {
    const { patient } = this.state;
    return (
      <Fragment>
        {patient !== undefined && patient.patient_id !== undefined && patient.patient_id !== null ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="float-left">
                      <h1 className="page-title">From Care Protocol</h1>
                    </div>
                    <div className="float-right">
                      <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</button>
                    </div>
                  </div>
                </div>
                <div className="template-form emrfrm mt-2">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">

                        <Select
                          id="template-select"
                          name="templateSelect"
                          style={{ width: 100 + '%' }}
                          showSearch
                          defaultOpen={true}
                          ref={this.templateselectRef}
                          optionFilterProp="description"
                          onChange={this.onDiagnosiSelectChange}
                          onSearch={this.onDiagnosiSearch}
                          className="select-drugs"
                          value={this.state.templateid}
                          filterOption={false}
                        // filterOption={(input, option) => {
                        //     return option.description.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                        // }}
                        >
                          {this.optionDiagnosisLoad()}

                        </Select>
                        <label className="required" htmlFor="diagnosis">Search Care Protocol</label>
                      </div>
                      <div className="fixed-cta">
                        <Button className="btn btn-primary px-5 text-uppercase" disabled={this.cfmDisabled} onClick={() => this.onSubmit()} id="confirm-template">CONFIRM</Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Please select a template"
          visible={this.state.templateSelectModal}
          onCancel={(e) => this.templateSelectCancel()}
          footer={false}
          closable={false}
        >
          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.templateSelectOk(e)}>OK</button>
          </div>
        </Modal>
      </Fragment>
    )
  }
}
export default TemplateSelect;