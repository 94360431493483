import React from "react";
import Axios from "axios";
import qs from 'qs';
import { Collapse, Checkbox, notification, Radio, Button, Form, Input, Modal } from "antd";
import LS_SERVICE from "../../../utils/localStorage";
import "./casesummary.scss";
import AsideLeft from "../../../components/aside/asideleft";
import {
  LOADER_RED,
  HC_GOBACK,
  HOMECARE_SEND_CASE_SUMMARY,
  HOMECARE_GET_CASE_SUMMARY,
  HOMECARE_DRAFT_CASE_SUMMARY,
  LOADER,
  HANDLEVALIDANTMESSAGES
} from "../../../utils/constant";
import { Link } from "react-router-dom";
import { speechService, speechTextService } from '../../../utils/rxjs-sharing';
import MyHcSpeechRecognition from '../../speech-to-text/speech-recognition';

const { TextArea } = Input;
const { Panel } = Collapse;
class CaseSummary extends React.Component {

  state = {
    isLoading: false,
    dataLoaded: false,
    booking_id: null,
    patient_id: null,
    hospital_id: null,
    advice: '',
    summary: '',
    send_vitals: 0,
    send_allergies: 0,
    send_conditions: 0,
    appointment_status: null,
    duration: 0,
    days: 0,
    speech: {
      identifier: null,
      state: false
    },
    reasonModal: false,
    reasonCompleteEarlyValue: 0,
    reasonAllowBool: false,
    confirmSubmitModal: false
  };

  constructor(props) {
    super(props);

    this.summaryRef = React.createRef();
    this.adviceRef = React.createRef();
    this.reasonEarlyFormRef = React.createRef();

    this.reasonCompleteEarly = [
      {
        value: 0,
        reason: 'Patient was not satisfied with services'
      }, {
        value: 1,
        reason: 'Patient is advised to get admitted'
      }, {
        value: 2,
        reason: 'Patient has chosen a different package'
      }, {
        value: 3,
        reason: 'Other'
      }
    ]
  }

  componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");

    const { match: { params } } = this.props;
    const { reference_id, patient_id, hospital_id } = params;

    this.setState({
      hospital_id,
      patient_id,
      booking_id: reference_id
    }, () => {
      this.getCaseSummary();
    })

    this.subscription = speechTextService.status().subscribe(data => {
      if (this.state.speech.state) {
        if (this.state.speech.identifier === 'summary') {
          this.setState({ summary: data.text })
          // , () => { this.handleConfirmBtnStatus() });
          // LS_SERVICE.set("notes_doctor_" + patient_id + "_" + reference_id, data.text);
        }
        if (this.state.speech.identifier === 'advice') {
          this.setState({ advice: data.text })
          // , () => { this.handleConfirmBtnStatus() });
          // LS_SERVICE.set("medicines_tests_" + patient_id + "_" + reference_id, data.text);
        }
        // if (this.state.speech.identifier === 'notes-nurse') {
        //     this.setState({ notes_nurse: data.text })
        //     // , () => { this.handleConfirmBtnStatus() });
        //     // LS_SERVICE.set("notes_nurse_" + patient_id + "_" + reference_id, data.text);
        // }
        // if (this.state.speech.identifier === 'internal-notes') {
        //     this.setState({ internal_notes: data.text })
        //     // , () => { this.handleConfirmBtnStatus() });
        //     // LS_SERVICE.set("internal_notes_" + patient_id + "_" + reference_id, data.text);
        // }
        // document.querySelector("#" + this.state.speech.identifier).style.height = document.querySelector("#" + this.state.speech.identifier).scrollHeight + "px";
      }
    });
  }

  getCaseSummary = () => {
    const { patient_id, booking_id } = this.state;

    Axios.get(HOMECARE_GET_CASE_SUMMARY({ booking_id, patient_id }))
      .then(success => {
        const { data } = success.data;

        if (data !== null) {
          const { advice, summary } = data;
          this.setState({
            advice,
            summary,
          })
        }
      })
  }

  handleOnChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    })
  }

  handleOnClick = e => {
    const { name, checked } = e.target;

    this.setState({
      [name]: checked ? 1 : 0
    })
  }

  handleModalPopup = (e, popup, cancelButton = false) => {
    e != null && e.preventDefault();

    this.setState({
      [popup]: !this.state[popup]
    }, () => {
      const { reasonModal } = this.state;

      if (cancelButton) {
        if (!reasonModal) {
          this.setState({
            reasonAllowBool: false
          })
        }
      }
    })
  }

  handleCaseSubmit = (e, isCompleted = 0, confirmSubmit = false) => {
    e !== null && e.preventDefault();

    const { patient_id, booking_id, hospital_id, advice, summary, send_allergies, send_conditions, send_vitals, duration, days, reasonAllowBool, reasonCompleteEarlyValue } = this.state;

    const PARAMS = {
      booking_id,
      patient_id,
      hospital_id,
      send_vitals,
      send_allergies,
      send_conditions,
      summary,
      advice,
      complete_appointment: isCompleted,
      remarks: (days < duration) ? (reasonCompleteEarlyValue !== 3 ? this.reasonCompleteEarly[reasonCompleteEarlyValue].reason : this.reasonEarlyFormRef.current.getFieldsValue(true).reason) : ''
    }

    if (days < duration && !reasonAllowBool) {
      this.setState({ reasonAllowBool: true });
      this.handleModalPopup(null, 'reasonModal');
      return;
    } else if (!confirmSubmit) {
      if (days < duration) this.handleModalPopup(null, 'reasonModal');
      this.handleModalPopup(null, 'confirmSubmitModal');
      return;
    }

    this.setState({
      isLoading: !this.state.isLoading,
      spin_loading: true
    });

    Axios.post(HOMECARE_SEND_CASE_SUMMARY, PARAMS)
      .then(success => {
        const { data } = success;

        notification.success({
          message: data.message,
          placement: 'topRight'
        })

        if (isCompleted) {
          this.props.history.push({
            pathname: `/patient-queue/${+hospital_id}`,
          })
          return;
        }
      })
      .finally(_ => {
        this.setState({
          isLoading: !this.state.isLoading,
          reasonAllowBool: false,
          spin_loading: false,
          reasonModal: false,
          confirmSubmitModal: false
        })
      })
  }

  handleCaseDraft = (e) => {
    e.preventDefault();

    this.setState({ isLoading: !this.state.isLoading })

    const { patient_id, booking_id, advice, summary } = this.state;

    const PARAMS = {
      booking_id,
      patient_id,
      summary,
      advice,
    }

    Axios.post(HOMECARE_DRAFT_CASE_SUMMARY, PARAMS)
      .then(success => {
        const { data } = success;
        this.setState({ isLoading: !this.state.isLoading })

        notification.success({
          message: data.message,
          placement: 'topRight'
        })
      })
  }

  handleSubmitDisabled = _ => {
    const { summary,
      advice } = this.state;

    if (summary.trim() !== '' && advice.trim() !== '') return false;
    return true;
  }

  initSpeech(info) {
    let initialText = '';
    if (info.identifier === 'summary') {
      initialText = this.state.summary != undefined ? this.state.summary : '';
    }
    if (info.identifier === 'advice') {
      initialText = this.state.advice != undefined ? this.state.advice : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
        }

      });
    } else {
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);

        if (!this.state.speech.state) {
          this.setState((prevState) => ({
            checkForEditsTrainingData: {
              ...this.state.checkForEditsTrainingData,
              [info.identifier]: false
            }
          }))
          this.handleRestartMic(info, initialText)
        }

      });
    }
  }

  handleRestartMic = (info, initialText) => {
    // debugger
    this.setState({
      speech: {
        identifier: info.identifier,
        state: true,
        initialText: initialText
      }
    }, () => {
      // console.log("State in init:",this.state.speech)

      if (!this.state.speech.state) {
        this.setState((prevState) => ({
          checkForEditsTrainingData: {
            ...this.state.checkForEditsTrainingData,
            [info.identifier]: false
          }
        }))
      }

      speechService.init(this.state.speech);
    });
  }

  handleTextAreaHeight = _ => {
    const { appointment_status } = this.state;

    if (appointment_status === 2) {
      this.summaryRef.current.parentElement.style.height = '50px';
      this.summaryRef.current.parentElement.style.height = this.summaryRef.current.scrollHeight + 'px';
      this.adviceRef.current.parentElement.style.height = '50px';
      this.adviceRef.current.parentElement.style.height = this.adviceRef.current.scrollHeight + 'px';
    }
  }

  handleHomecareDetails = data => {
    this.setState({
      appointment_status: data.appointment.booking_status_code,
      duration: data.appointment.test.duration,
      days: data.appointment.test.days,
      dataLoaded: true
    }, () => this.handleTextAreaHeight())
  }

  handleAntRadioChange = e => {
    // debugger;
    this.setState({
      reasonCompleteEarlyValue: e.target.value,
    });
  };

  render() {

    const { dataLoaded } = this.state;

    return (
      <>
        <AsideLeft isHomecare={true} getHomecareDetails={this.handleHomecareDetails} />

        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="float-left">
                  <h1 className="page-title mb-0">Homecare Case Summary</h1>
                </div>
                <div className="float-right">
                  <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => HC_GOBACK(e, this.props, this.state)}>BACK TO PATIENT</button>
                </div>
              </div>
            </div>
          </div>

          {dataLoaded && (
            <>
              <div className="row" style={{ paddingBottom: '100px' }}>
                <div className="col-12">

                  <div className="collapse-wrapper">
                    <MyHcSpeechRecognition />

                    <Collapse defaultActiveKey={['1']}

                      className="notes-wrapper collapse-disabled"
                    >
                      <Panel header="CASE SUMMARY" key="1" className="accordianstyle panelcol notes-panel micwrap" extra={
                        this.state.appointment_status !== 2 && (
                          <Link
                            to="" onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.initSpeech({ identifier: 'summary' }) }}
                            className={(this.state.speech.identifier === 'summary' ? this.state.speech.state : '') ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                        )
                      }>
                        <div className="p-0 card-body">
                          <div className="patient-textwrapper textwrap">
                            <textarea
                              tabIndex={1}
                              rows={8}
                              cols={23}
                              placeholder="Write Summary Here"
                              disabled={this.state.appointment_status === 2}
                              name="summary"
                              value={this.state.summary}
                              ref={this.summaryRef}
                              onChange={this.handleOnChange}
                            />
                          </div>
                        </div>
                      </Panel>
                    </Collapse>

                    <Collapse defaultActiveKey={['2']}

                      className="notes-wrapper collapse-disabled"
                    >
                      <Panel header="FOLLOW UP ADVISE" key="2" className="accordianstyle panelcol notes-panel micwrap" extra={
                        this.state.appointment_status !== 2 && (
                          <Link
                            to="" onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.initSpeech({ identifier: 'advice' }) }}
                            className={(this.state.speech.identifier === 'advice' ? this.state.speech.state : '') ? 'mic subjective-mic on mute' : 'mic subjective-mic'} ></Link>
                        )
                      }>
                        <div className="p-0 card-body">
                          <div className="patient-textwrapper textwrap">
                            <textarea
                              tabIndex={1}
                              rows={8}
                              cols={23}
                              placeholder="Write Advise Here"
                              disabled={this.state.appointment_status === 2}
                              name="advice"
                              value={this.state.advice}
                              ref={this.adviceRef}
                              onChange={this.handleOnChange}
                            />
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>


              {this.state.appointment_status !== 2 && (
                <div className="fixed-cta homecare-sticky">
                  <div className="w-100 checkin mb-2 text-left">
                    <span className="text-dark mr-5">Please select the sections you would like to add in summary</span>
                    <Checkbox className="ml-3"
                      onClick={this.handleOnClick}
                      value={this.state.send_vitals}
                      checked={this.state.send_vitals}
                      name="send_vitals">Patient Vital</Checkbox>
                    <Checkbox className="ml-3"
                      onClick={this.handleOnClick}
                      value={this.state.send_allergies}
                      checked={this.state.send_allergies}
                      name="send_allergies">Allergies</Checkbox>
                    <Checkbox className="ml-3"
                      onClick={this.handleOnClick}
                      value={this.state.send_conditions}
                      checked={this.state.send_conditions}
                      name="send_conditions">Existing Conditions</Checkbox>
                  </div>

                  <div className="w-100 text-right">
                    <button className="btn btn-outline-secondary mr-3"
                      disabled={this.handleSubmitDisabled()}
                      onClick={this.handleCaseDraft}>SAVE AS DRAFT</button>
                    {/* <button className="btn btn-outline-secondary mr-3"
                      disabled={this.handleSubmitDisabled()}
                      onClick={this.handleCaseSubmit}>SEND TO PATIENT</button> */}
                    <button className="btn btn-primary mr-3"
                      disabled={this.handleSubmitDisabled()}
                      onClick={e => this.handleCaseSubmit(e, 1)}>SEND TO PATIENT AND COMPLETE</button>
                  </div>
                </div>
              )}
            </>
          )}

          {!dataLoaded && (
            <div className="col-12">
              <div className="text-center">
                {LOADER_RED}
              </div>
            </div>
          )}

          {/* || REASON MODAL */}
          <Modal
            title={`Reason for early completion`}
            visible={this.state.reasonModal}
            onCancel={(e) => this.handleModalPopup(e, 'reasonModal', true)}
            style={{ top: '40px' }}
            footer={[
              <div className="text-center">
                <Button type="secondary" className="btn btn-secondary text-uppercase"
                  onClick={e => this.handleModalPopup(e, 'reasonModal', true)}>
                  CANCEL
                </Button>
                <Button form="reasonEarlyForm" type="primary" htmlType="submit" className="btn btn-primary text-uppercase ml-2"
                  disabled={this.state.spin_loading}>
                  SAVE{this.state.spin_loading ? LOADER : ''}
                </Button>
              </div>
            ]}
            className="emrfrm"
          >
            <Form
              id="reasonEarlyForm"
              ref={this.reasonEarlyFormRef}
              validateMessages={HANDLEVALIDANTMESSAGES}
              onFinish={() => this.handleCaseSubmit(null, 1)}
              layout="vertical"
            >

              <Radio.Group
                className="d-flex flex-column"
                onChange={this.handleAntRadioChange}
                value={this.state.reasonCompleteEarlyValue}>

                {this.reasonCompleteEarly.map(rc =>
                  <Radio value={rc.value} className="mb-2" key={rc.value}>
                    {rc.reason}
                  </Radio>
                )}

              </Radio.Group>

              {this.state.reasonCompleteEarlyValue === 3 && (
                <Form.Item name="reason"
                  rules={[{
                    required: true,
                  }]}>
                  <TextArea placeholder="Enter Reason" className="w-100 mt-2" rows={3} />
                </Form.Item>
              )}

            </Form>
          </Modal>

          {/* || CONFIM SUBMIT MODAL */}
          <Modal
            title={`Are you sure?`}
            visible={this.state.confirmSubmitModal}
            onCancel={(e) => this.handleModalPopup(e, 'confirmSubmitModal', true)}
            style={{ top: '40px' }}
            footer={[
              <div className="text-center">
                <Button type="secondary" className="btn btn-secondary text-uppercase"
                  onClick={e => { this.handleCaseDraft(e); this.handleModalPopup(e, 'confirmSubmitModal') }}>
                  SAVE AS DRAFT
                </Button>
                <Button type="primary" className="btn btn-primary text-uppercase ml-2"
                  onClick={e => this.handleCaseSubmit(e, 1, true)}
                  disabled={this.state.spin_loading}>
                  SUBMIT{this.state.spin_loading ? LOADER : ''}
                </Button>
              </div>
            ]}
            className="emrfrm"
          >
            <p>
              Complete &amp; Send to Patient will COMPLETE the homecare package for the patient. Would you like to proceed?
            </p>
          </Modal>
        </div>
      </>
    );
  }
}

export default CaseSummary;
