import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import { notification, AutoComplete, Select, Modal, Button, Radio, Form, Input, Checkbox, Tooltip } from 'antd';
import { MEDICINES_GETALL, SEARCH_DRUGS, DRUGS_CONSTANT, DRUG, DRUG_FAVOURITE, LATEST_DATA_FOR_EACH_VITAL, CIMS_INFO, CIMS_INTERACTION_EXISTS, CIMS_INTERACTION, REFERENCE_TYPE, PRESCRIPTION_DATA, DELETE_MEDICINE, MYHC_APPTYPE, WOODLANDS, HOSPITALDEFAULTS, LOADER_BLACK, DOSE_RULE, AVERAGE_BSA_FOR_MALE, AVERAGE_BSA_FOR_FEMALE, ADD_DRUGS } from '../../utils/constant';
import 'antd/dist/antd.css';
import LS_SERVICE from '../../utils/localStorage';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './medicine.scss';
import calendarDatePickerIco from "../../assets/images/ic-calendar.png";
import { jQueryLabels, AntSelectLabels, AntClearLabels, FixedCta, ClearAntList, ClearSelectSelectionItem } from '../../utils/jQueryLabels';
import {
  LAST_VITAL_ADDED_DATA,
  BMI_CALCULATE,
  LOADER,
  BSA_CALCULATE,
  PATIENT_INFO,
  USERTYPES,
  PROFILE,
  MARK_DEFAULT_CORRECTION_DOSE,
  SAVE_TARGET_BLOOD_SUGAR,
  FETCH_DEFAULT_CORRECTION_DOSE

} from '../../utils/constant';
const { Option } = Select;
class MedicineForm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      DrugApi: [],
      TaperBtnEnabled: true,
      searchDrugs: [],
      DRUGSConstants: this.props.constants ?? [],
      patient: this.props.patient ?? null,
      isPlanPrescribed: props.isPlanPrescribed ? props.isPlanPrescribed : false,
      isTableExternal: props.isTableExternal ? props.isTableExternal : false,
      dateFormat: 'dd/MM/yyyy',
      clearForm: props.clearForm,
      editData: props.editData ? props.editData : null,
      eventBased: props.eventBased ? props.eventBased : false,
      polyPharmacyModal: false,
      polyPharmacyFlag: 1,
      latest_vitals_with_datetime: {},
      correction_dose_applicable: 'N',
      formIsFetching: false,
      drugEditApiStatus: false,
      deleteMedicineId: -1,
      currentMeds: [],
      MedicineAddInitialListed: props.Input_data ? props.Input_data : [],
      ggpi: [],
      selectedDrugId: null,
      poly_pharmacy: 0,
      drug_form_defaulted: true,
      isFavCheckedVal: false,
      target_blood_sugar_error_message: "",
      addTaperDose: false,
      AddMedicineButton: true,
      AddDoseForScheduleButton: true,
      AddCorrectionDoseButton: true,
      drug_id_list: [],
      acceptDrugData: true,
      updateMedicineData: false,
      isModalTaperDoseVisible: false,
      polyPharmacyWarningShown: false,
      dose_for_schedule_applicable: 'N',
      addContinueTillReview: 'N',
      addTaperDoseBtn: true,
      correction_dose_data_value: [],
      correction_dose_data: [],
      adult_dose_exists: false,
      //constants
      drugForm: {},
      drugDosage: {},
      drugRoute: {},
      drugDaysInWeek: {},
      drugDailyFrequency: {},
      drugSchedules: {},
      drugUrgency: {},
      drugEvent: {},
      drugInstructions: {},
      drugMorningInstructions: {},
      drugAfternoonInstructions: {},
      drugEveningInstructions: {},
      drugNightInstructions: {},
      availableDrugRoutes: [],
      taperModelContinue: 0,
      disableNumberOfDays: false,
      disableNumberOfDaysTaper: false,
      //drug related details
      isTaperRow: false,
      drug_daily_frequency_repeats: null,
      selectDrugValue: '',
      dose_for_schedule: 'N',
      correction_dose: 'N',
      prescription_drug_id: -1,
      editIndex: -1,
      drug_generic: [],
      drug_id: null,
      drug_name: null,
      drug_dosage_form: 0,
      drug_dosage_form_text: null,
      drug_dosage_form_text_quantity: '-',
      drug_form: '',
      drug_urgency: '',
      drug_event: '',
      drug_lmp_event_duration: '',
      drug_lmp_event_duration_type: '',
      drug_fixed_qty: null,
      drug_medicine_remarks: null,
      drug_daily_frequency: '',
      drug_days_in_week: [],
      drug_days_in_week_text: null,
      drug_dosage: '',
      drug_dosage_value: '',
      drug_dosage_value_morning: '',
      drug_dosage_value_afternoon: '',
      drug_dosage_value_evening: '',
      drug_dosage_value_night: '',
      drug_frequency: 1,
      drug_instruction: '',
      drug_instruction_morning: '',
      drug_instruction_afternoon: '',
      drug_instruction_evening: '',
      drug_instruction_night: '',
      drug_number_of_days: null,
      drug_duration_time: "Days",
      drug_duration_time_taper: "Days",
      drug_route: null,
      drug_schedule_name: null,
      drug_schedules: [],
      drug_start_date: null,
      target_blood_sugar_max: null,
      target_blood_sugar_min: null,
      drug_end_date: null,
      drug_strength: null,
      drug_quantity: null,
      drug_taper_row: null,
      correction_dose_value: [],
      correction_dose_validate: [],
      //taper fields
      drug_dosage_taper: '',
      drug_dosage_value_taper: '',
      drug_dosage_form_text_taper: null,
      drug_strength_taper: null,
      drug_route_taper: '',
      drug_number_of_days_taper: null,
      drug_frequency_taper: 1,
      drug_days_in_week_taper: [],
      drug_days_in_week_text_taper: null,
      drug_daily_frequency_taper: '',
      drug_schedule_name_taper: null,
      drug_schedules_taper: [],
      cimsDrugInteractionErrorDummy: { 'atorvastatin': ['paracetamol'], 'paracetamol': ['atorvastatin'], 'warfarin': ['aspirin'], 'aspirin': ['warfarin'] },
      interactionDrugName: '',
      allowEdit: this.props.allowEdit ?? true,
      bmi: '',
      isPaediatricSpeciality: 0,
      highestDosageId: null,
      dosageDropDown: '',
      dosageDropDownMorning: '',
      dosageDropDownAfternoon: '',
      dosageDropDownEvening: '',
      dosageDropDownNight: '',
      dosageDropDownTaper: '',
      dosageConstantOriginal: this.props.constants && this.props.constants.drugDosage ? [...this.props.constants.drugDosage] : [],
      showDoseCalculator: false,
      localDoseRule: 3,
      isPaediatrics: false,
      doseCorrection: false,
      doseScheduling: false,
      diabaticPatient: false,
      correction_dose_error_messsage: [],
      showAddDrugButton: true,
      medicineFormValidate: true,
      isBorderColor: false
    }
    this.favArr = {}
    this.favDrug = [];
    this.schedulesName = [];
    this.FormRef = React.createRef();
  }

  getAvailableDrugRoute = (value) => {
    axios.post(DRUG, qs.stringify({
      id: value
    })).then(success => {
      const response = success.data.data.result;
      let availableDrugRoutesTemp = response.drug_route_for_form;
      let availableDrugRoutes = [];
      if (availableDrugRoutesTemp && availableDrugRoutesTemp.length == 0 || availableDrugRoutesTemp == null) {
        availableDrugRoutes = this.state.DRUGSConstants.route;
      }
      else {
        availableDrugRoutesTemp.map((v, k) => {
          availableDrugRoutes.push({ id: parseInt(v.routeId), description: v.long })
        })
      }
      this.setState({ availableDrugRoutes: availableDrugRoutes });
    })
  }

  changeDateToISO(date) {
    return date ? moment(date).toDate() : null;
  }

  showPolyModal = () => { this.setState({ polyPharmacyModal: true }); };

  handlePolyOk = () => {
    this.setState({ polyPharmacyModal: false, polyPharmacyWarningShown: true }, () => { this.props.updatepolyPharmacyWarningShown() });
  };


  handlePolyCancel = (e) => { this.setState({ polyPharmacyModal: false, polyPharmacyWarningShown: true }); this.Formclear() };

  showTaperModal = () => {
    let schedules = [];
    let schedules_name = [];
    let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === this.state.drug_daily_frequency)
    if (typeof frequencyObjKey != 'undefined') {
      schedules = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)].schedule.map(function (obj) { return obj.id });
      schedules_name = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)].schedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-');
    }
    this.setState({
      addTaperDose: false,
      taperModelContinue: !this.state.addTaperDose ? this.state.taperModelContinue : 0,
      addTaperDoseBtn: false,
      isModalTaperDoseVisible: true,
      drug_dosage_taper: this.state.drug_dosage,
      drug_dosage_value_taper: this.state.drug_dosage_value,
      drug_dosage_form_text_taper: this.state.drug_dosage_form_text,
      drug_route_taper: this.state.drug_route,
      drug_frequency_taper: 1,
      drug_days_in_week_taper: [],
      drug_days_in_week_text_taper: null,
      drug_strength_taper: this.state.drug_strength,
      /*drug_number_of_days_taper: this.state.drug_number_of_days,
      drug_daily_frequency_taper: this.state.drug_daily_frequency,
      drug_schedule_name_taper: schedules_name,
      drug_schedules_taper: schedules,*/
      drug_number_of_days_taper: null,
      drug_daily_frequency_taper: '',
      drug_schedule_name_taper: null,
      drug_schedules_taper: [],
      disableNumberOfDaysTaper: false,
      dosageDropDownTaper: this.state.drug_dosage_value
    }, () => { AntSelectLabels(); AntClearLabels(); jQueryLabels(); this.updateQuantity(); this.updateEndDate() });
  };

  handleTaperOk = () => { this.setState({ isModalTaperDoseVisible: false }); };

  handleDoseForScheduleOk = () => {
    this.setState({
      doseScheduling: false,
      dose_for_schedule_applicable: 'Y'
    }, () => {
      var morning = this.state.dosageDropDownMorning && this.state.dosageDropDownMorning != "" ? parseFloat(this.state.dosageDropDownMorning) : 0;
      var afternoon = this.state.dosageDropDownAfternoon && this.state.dosageDropDownAfternoon != "" ? parseFloat(this.state.dosageDropDownAfternoon) : 0;
      var evening = this.state.dosageDropDownEvening && this.state.dosageDropDownEvening != "" ? parseFloat(this.state.dosageDropDownEvening) : 0;
      var night = this.state.dosageDropDownNight && this.state.dosageDropDownNight != "" ? parseFloat(this.state.dosageDropDownNight) : 0;
      var obj = {
        'morning': morning,
        'afternoon': afternoon,
        'evening': evening,
        'night': night
      };
      var smallest = '';
      for (var name in obj) {
        if (smallest !== '' && obj[name] !== 0 && obj[name] < obj[smallest]) {
          smallest = name;
        } else if (smallest === '') {
          smallest = name;
        }
      }
      var dosage;
      if (smallest == 'morning') { dosage = morning; }
      else if (smallest == 'afternoon') { dosage = afternoon; }
      else if (smallest == 'evening') { dosage = evening; }
      else if (smallest == 'night') { dosage = night; }
      this.onBlurDrugDosage({
        target: {
          value: dosage,
        }
      })
      this.onChangeDrugDosage(dosage);
      AntSelectLabels(); AntClearLabels(); jQueryLabels(); this.updateQuantity();
    });
  };

  handleCorrectionDoseSaveDefault = () => {
    var remove_empty = this.state.correction_dose_validate.filter(function () { return true });
    var PARAMS = {
      correction_dose: remove_empty,
      drug_id: this.state.drug_id
    }
    Axios.post(MARK_DEFAULT_CORRECTION_DOSE, PARAMS)
      .then(success => {
        notification.success({
          message: 'Successfully marked as default correction dose',
          placement: 'topRight'
        });
      }).catch(err => {
        notification.error({
          message: 'Failed to marke as default correction dose',
          placement: 'topRight'
        });
        console.log(err);
      })
  }

  onChangeTargetBloodSugar = (value) => {
    this.setState({
      [value.target.name]: parseInt(value.target.value)
    }, () => {
      if (this.state.target_blood_sugar_min && this.state.target_blood_sugar_max) {
        if (this.state.target_blood_sugar_min < this.state.target_blood_sugar_max) {
          this.setState({
            target_blood_sugar_error_message: ""
          })
          var PARAMS = {
            patient_id: this.state.patient.patient_id,
            target_blood_sugar_min: this.state.target_blood_sugar_min,
            target_blood_sugar_max: this.state.target_blood_sugar_max
          }
          axios.post(SAVE_TARGET_BLOOD_SUGAR, PARAMS);
        }
        else {
          this.setState({
            target_blood_sugar_error_message: "From value must be lesser than To value"
          })
        }
      }
      else {
        this.setState({
          target_blood_sugar_error_message: ""
        })
      }
    })
  }

  handleCorrectionDoseCancel = async () => {
    var correction_dose_data_value = this.state.correction_dose_data_value;
    var correction_dose_data = this.state.correction_dose_data
    await this.setState({
      doseCorrection: false,
      correction_dose_value: correction_dose_data_value,
      correction_dose_validate: correction_dose_data
    })
  }

  handleCorrectionDoseSave = () => {
    var remove_empty = this.state.correction_dose_validate.filter(function () { return true });
    var correction_dose_value = this.state.correction_dose_value;
    this.setState({
      doseCorrection: false,
      correction_dose_applicable: 'Y',
      correction_dose_data_value: correction_dose_value,
      correction_dose_data: remove_empty
    });
  }

  handleTaperCancel = (e) => {
    this.setState({
      isModalTaperDoseVisible: false,
      drug_dosage_taper: '',
      drug_dosage_form_text_taper: null,
      drug_route_taper: '',
      drug_frequency_taper: 1,
      drug_days_in_week_taper: [],
      drug_days_in_week_text_taper: null,
      drug_strength_taper: null,
      drug_number_of_days_taper: null,
      drug_daily_frequency_taper: '',
      drug_schedule_name_taper: null,
      drug_schedules_taper: [],
      dosageDropDownTaper: ''
    }, () => { this.Formclear(); })
  };

  showAlertMModal = () => { this.setState({ isAlertMedicineMVisible: true }) };

  // handleAlertMOk = () => { this.setState({ isAlertMedicineMVisible: false }); this.MedicineOndelete(this.state.deleteMedicineId) };

  handleAlertMCancel = () => { this.setState({ isAlertMedicineMVisible: false }) };

  showModalInteraction = () => {
    let arr = this.state.$drug_id_list;
    if (arr.length > 1) {
      arr = arr.join('-')
      axios.get(CIMS_INTERACTION_EXISTS + '/' + arr)
        .then(success => {
          if (success.data.interaction) {
            this.setState({ $drug_id_Interaction_Modalurl: arr, isModalInteractionVisible: true });
          }
        })
        .catch(err => console.log(err))
    }
  };

  handleInteractionCancel = () => { this.setState({ isModalInteractionVisible: false }, () => { this.Formclear(); }); };

  handleInteractionOk = () => { this.setState({ isModalInteractionVisible: false, interactionDrugName: '' }) };

  showModalInfo = () => { this.setState({ isModalInfoVisible: true }); };

  handleInfoOk = () => { this.setState({ isModalInfoVisible: false }); };

  handleInfoCancel = (e) => { this.setState({ isModalInfoVisible: false }) };

  /*MedicineOndelete = (val) => {
    const { isPlanPrescribed } = this.state;

    if (isPlanPrescribed) {
      axios.get(DELETE_MEDICINE({ id: val }))
          .then(success => {
            this.handleDrugData();
          })
          .catch(err => console.log(err))
    } else {
      if (this.state.MedicineAddInitialListed.length === 1) {
        // this.MedicineAddTaperArr = [];
        this.setState({ MedicineAddInitialListed: [], MedicineAddTable: false, MedicineAddTaperArr: [] }, () => {
          this.props.medicine_data(
              this.state.MedicineAddInitialListed
          )
        })
      }
      else if (this.state.MedicineAddInitialListed.length > 1) {
        let array = this.state.MedicineAddInitialListed;
        array.splice(val, 1);
        // this.MedicineAddTaperArr = array;
        this.setState({ MedicineAddInitialListed: array, MedicineAddTaperArr: array }, () => {
          this.props.medicine_data(
              this.state.MedicineAddInitialListed
          )
        })
      }
      this.optionDrugLoad();
    }
  }*/

  optionDrugLoad() {
    if (this.state.searchDrugs !== undefined && this.state.searchDrugs.length > 0) {
      return this.state.searchDrugs.map((obj, i) => {
        return (<Option key={i} value={obj.id} fav={obj.fav} disabled={this.props.addedDrugsList && this.props.addedDrugsList.includes(obj.id)} title={obj.name}>
          <span className="desc">
            {obj.name}
          </span>
          <span className="float-right text-primary">
            {(LS_SERVICE.get("user_type") == USERTYPES.doctor) ?
              <i className={`icon_unique icon_star align-star ${obj.fav ? "favourite" : ""}`}></i>
              : null}
            {this.state.cimsallow ? (
              <Button onClick={(e) => { e.stopPropagation(); this.showModalInfo() }} className="nostyle-link p-0">
                <i className='icon_info'></i>
              </Button>
            ) : null}
          </span>
        </Option>
        )
      })
    } else return null
  }

  optionFormDrugLoad() {
    if (!this.state.DRUGSConstants.drugForm || this.state.DRUGSConstants.drugForm.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugForm.map((obj, i) => {
      this.state.drugForm[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionDosageLoad() {
    if (!this.state.DRUGSConstants.drugDosage || this.state.DRUGSConstants.drugDosage.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    // var highestDosageId = this.state.highestDosageId;
    /*var htmlText =  this.state.DRUGSConstants.drugDosage.map((obj, i) => {
      // highestDosageId = highestDosageId==null || highestDosageId<obj.id?obj.id:highestDosageId;
      this.state.drugDosage[obj.id] = ""+obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
      )
    }, this)
    // this.setState({highestDosageId});
    return htmlText;*/
    var options = [];
    this.state.DRUGSConstants.drugDosage.map((obj, i) => {
      this.state.drugDosage[obj.id] = "" + obj.description;
      options.push({ value: obj.description })
    }, this)
    return options;
  }

  optionRouteLoad = () => {
    if (!this.state.DRUGSConstants.route || this.state.DRUGSConstants.route.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    let availableDrugRoutes = this.state.availableDrugRoutes;
    var routeForForms = this.state.DRUGSConstants.drugRouteForForm.filter((item, j) => item.formId == this.state.drug_form) || [];
    let allDrugRoute = this.state.DRUGSConstants.route;
    if (availableDrugRoutes.length == 0 || availableDrugRoutes == null) {
      availableDrugRoutes.length = []
      for (var rff of routeForForms) {
        availableDrugRoutes.push({
          id: rff.routeId, description: rff.route_descriptions
        })
      }
    }
    let drugOptions = availableDrugRoutes.length == 0 || availableDrugRoutes == null ? allDrugRoute : availableDrugRoutes
    return drugOptions.map((obj, i) => {
      this.state.drugRoute[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionDaysLoad() {
    if (!this.state.DRUGSConstants.daysInWeek || this.state.DRUGSConstants.daysInWeek.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.daysInWeek.map((obj, i) => {
      this.state.drugDaysInWeek[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} id={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optiondaily_frequencyLoad() {
    if (!this.state.DRUGSConstants.dailyFrequency || this.state.DRUGSConstants.dailyFrequency.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.dailyFrequency.map((obj, i) => {
      this.state.drugDailyFrequency[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} dataSchedule={obj?.schedule} dataid={obj} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionschedulesLoad() {
    if (!this.state.DRUGSConstants.drugSchedules || this.state.DRUGSConstants.drugSchedules.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    this.schedulesName = [];
    return this.state.DRUGSConstants.drugSchedules.map((obj, i) => {
      this.state.drugSchedules[obj.id] = obj.description;
      this.schedulesName.push(obj.description)
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionPriorityLoad() {
    if (!this.state.DRUGSConstants.drugUrgency || this.state.DRUGSConstants.drugUrgency.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugUrgency.map((obj, i) => {
      this.state.drugUrgency[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionEventLoad() {
    if (!this.state.DRUGSConstants.drugEvent || this.state.DRUGSConstants.drugEvent.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugEvent.map((obj, i) => {
      this.state.drugEvent[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionLMPEventDurationTypeLoad() {
    return (
      <>
        <Option key={1} value="Days" title="Days">Days</Option>
        <Option key={2} value="Weeks" title="Weeks">Weeks</Option>
        <Option key={3} value="Months" title="Months">Months</Option>
      </>
    )
  }

  optionInstructionLoad() {
    if (!this.state.DRUGSConstants.drugInstructions || this.state.DRUGSConstants.drugInstructions.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugInstructions.map((obj, i) => {
      this.state.drugInstructions[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionMorningInstructionLoad() {
    if (!this.state.DRUGSConstants.drugMorningInstructions || this.state.DRUGSConstants.drugMorningInstructions.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugMorningInstructions.map((obj, i) => {
      this.state.drugMorningInstructions[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionAfternoonInstructionLoad() {
    if (!this.state.DRUGSConstants.drugAfternoonInstructions || this.state.DRUGSConstants.drugAfternoonInstructions.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugAfternoonInstructions.map((obj, i) => {
      this.state.drugAfternoonInstructions[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionEveningInstructionLoad() {
    if (!this.state.DRUGSConstants.drugEveningInstructions || this.state.DRUGSConstants.drugEveningInstructions.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugEveningInstructions.map((obj, i) => {
      this.state.drugEveningInstructions[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  optionNightInstructionLoad() {
    if (!this.state.DRUGSConstants.drugNightInstructions || this.state.DRUGSConstants.drugNightInstructions.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.DRUGSConstants.drugNightInstructions.map((obj, i) => {
      this.state.drugNightInstructions[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>)
    }, this)
  }

  selectSchedulesChange = (val, props) => {
    if (!this.state.isModalTaperDoseVisible) {
      this.setState({
        drug_schedules: val,
        drug_schedule_name: props.map(function (obj) { return obj.title }).toString().replace(/,/g, '-'),
        AddMedicineButton: this.state.drug_daily_frequency_repeats === val.length ? true : false
      }, () => { this.checkDrugSaveBtn() })
    }
    else {
      this.setState({
        drug_schedules_taper: val,
        drug_schedule_name_taper: props.map(function (obj) { return obj.title }).toString().replace(/,/g, '-'),
        addTaperDoseBtn: this.state.drug_daily_frequency_repeats === val.length ? false : true
      }, () => { this.checkDrugSaveBtn() })
    }
  }

  selectSchedulesChange_Taper = (val, props) => {
    this.setState({
      drug_schedules_taper: val,
      drug_schedule_name_taper: props.map(function (obj) { return obj.title }).toString().replace(/,/g, '-'),
      addTaperDoseBtn: this.state.drug_daily_frequency_repeats === val.length ? false : true
    }, () => { this.checkDrugSaveBtn() })
  }

  onselectDrugChange = (val) => {
    this.setState({ form_id: val }, () => { this.checkDrugSaveBtn() })
  }

  onChangeTaperModelContinue = (e) => {
    this.setState({ taperModelContinue: e.target.value })
  }

  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    window.addEventListener('load', (event) => {
      jQueryLabels();
      FixedCta();
    });
    setTimeout(() => this.MasterDrugApi(''), 500);
    this.setState({ isPaediatrics: LS_SERVICE.get('isPaediatrics') });
    if (this.props.formType != 'template') this.getCurrentMedicines();
    jQueryLabels();

    this.getDoseRule();
    if (this.props.formType != 'template') this.getPatientLastVitalAddedData();
    if (this.props.formType != 'template') this.getPatientLatestRecordedVitalsByDate();
    const is_paediatrician = LS_SERVICE.get('isPaediatrician');

    if (is_paediatrician == 1) {
      this.setState({ isPaediatricSpeciality: 1 });
    }
    if (this.props?.diagnoseData) {
      for (var diabetes of this.props?.diagnoseData) {
        if (diabetes.is_diabetic == "Y") {
          this.setState({ diabaticPatient: true });
        }
      }
    }
  }

  getPatientLastVitalAddedData = _ => {
    const patient_id = this.state.patient.patient_id;
    Axios.get(LAST_VITAL_ADDED_DATA({ patient_id }))
      .then(success => {
        this.setState({
          heightbmi: success.data.data.results.height,
          weightbmi: success.data.data.results.weight
        }, () => {
          if (success.data.data.results.height != '' && success.data.data.results.weight != '') {
            let bmi = BMI_CALCULATE(success.data.data.results.height, success.data.data.results.weight);
            let bsa = BSA_CALCULATE(success.data.data.results.height, success.data.data.results.weight);
            this.setState({
              bmi: bmi,
              bsa: bsa
            });
          }
        })
      })
  }

  getPatientLatestRecordedVitalsByDate() {
    const patient_id = this.state.patient.patient_id;
    Axios.get(LATEST_DATA_FOR_EACH_VITAL({ patient_id }))
      .then(success => {
        if (success.data.data != '' && success.data.data != {}) {
          this.setState({
            latest_vitals_with_datetime: success.data.data
          });
        }
      })
  }

  autoHeight = (e) => {
    e.target.style.height = e.target.scrollHeight + "px";
  }

  getCurrentMedicines() {
    let { patient } = this.state;
    if (patient == null || typeof patient == 'undefined') {
      patient = this.props.patient;
    }
    const { patient_id } = patient;
    axios.get(MEDICINES_GETALL({ patient_id })).then(success => {
      let currentMedsList = success.data.data.details.current_medicines;
      var tempArr = [];
      if (currentMedsList) {
        tempArr = currentMedsList.map(function (v) { return v.drug_id });
        tempArr = tempArr.filter(function (item, pos) { return tempArr.indexOf(item) == pos; })
      }
      this.setState({
        currentMeds: tempArr
      })
    });
    axios.get(PATIENT_INFO({ patient_id, reference_id: patient.qms_token_id }))
      .then(response => {
        this.setState({
          target_blood_sugar_max: response?.data?.data?.results?.target_blood_sugar_max,
          target_blood_sugar_min: response?.data?.data?.results?.target_blood_sugar_min
        })
      })

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.editData != null && this.state.editData != prevState.editData) {
      this.setState({ searchDrugs: [{ id: this.state.editData.drug_id, name: this.state.editData.drug_name, fav: this.state.editData.fav ?? false }] }, () => { this.handleEditData(); this.getAvailableDrugRoute(this.state.editData.drug_id); })
    }
    if (prevState.editData != null && this.state.editData == null) {
      this.props.clearEdit(); this.Formclear();
    }
    if (this.state.eventBased != prevState.eventBased) {
      this.checkDrugSaveBtn();
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    // if (nextProps.editData == null && nextProps.editData !== prevState.editData) {
    //   return { editData: null }
    // } else
    if (nextProps.editData !== prevState.editData) {
      return {
        eventBased: nextProps.eventBased,
        editData: nextProps.editData
      }
    }
    if (nextProps.polyPharmacyWarningShown !== prevState.polyPharmacyWarningShown) {
      return {
        eventBased: nextProps.eventBased,
        polyPharmacyWarningShown: nextProps.polyPharmacyWarningShown
      }
    }
    return {
      eventBased: nextProps.eventBased
    }
  }

  /*handleDrugData = () => {
    const { patient } = this.state;
    const { patient_id, qms_token_id } = patient;

    this.setState({
      drugDataLoaded: false,
      drugData: [],
    })

    const PARAMS = {
      patient_id,
      reference_type: REFERENCE_TYPE,
      reference_id: qms_token_id
    }

    axios.get(PRESCRIPTION_DATA(PARAMS))
        .then(success => {
          let { data } = success.data;
          this.setState({
            drugData: data.details,
            drugDataLoaded: true,
            MedicineAddTable: true,
            MedicineAddInitialListed: data.details
          })
        })
        .catch(err => console.log(err.response))
  }*/


  onSelectDailyFrequencyChange = (val, props) => {
    var day_interval = props.dataid.interval;
    day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : 1);
    var times_correction = props.dataid.total_times;
    var dosage_per_day = props.dataid.total_repeats;

    var start_date = this.state.start_date;
    if (!start_date && start_date != undefined) {
      if (this.state.editData != null && this.state.editData.start_date != null)
        start_date = this.state.editData.start_date
      else if (this.state.editData != null && this.state.editData.start_date == null && this.state.editData.previousEndDate != null && this.state.editData.taper_row)
        start_date = moment(this.state.editData.previousEndDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
      else
        start_date = moment().format('YYYY-MM-DD');
    }
    if (times_correction == 0) {
      start_date = moment().format('YYYY-MM-DD');
      if (!this.state.isModalTaperDoseVisible)
        this.setState({ disableNumberOfDays: false })
      else
        this.setState({ disableNumberOfDaysTaper: true })
    }
    else {
      if (start_date == null) {
        start_date = moment().format('YYYY-MM-DD');
      }

      if (!this.state.isModalTaperDoseVisible)
        this.setState({ disableNumberOfDays: false })
      else
        this.setState({ disableNumberOfDaysTaper: false })
    }

    if (!this.state.isModalTaperDoseVisible)
      this.setState({
        drug_start_date: start_date,
        drug_schedules: props.dataSchedule.map(function (obj) { return obj.id }),
        drug_schedule_name: props.dataSchedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-'),
        drug_daily_frequency: props.dataid.id,
        drug_daily_frequency_repeats: day_interval != 1 ? 1 : parseInt(dosage_per_day),
        drug_duration_time: day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months'
      }, () => { this.updateQuantity(); this.updateEndDate(); this.checkDrugSaveBtn(); })
    else
      this.setState({
        drug_start_date: start_date,
        drug_schedules_taper: props.dataSchedule.map(function (obj) { return obj.id }),
        drug_schedule_name_taper: props.dataSchedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-'),
        drug_daily_frequency_taper: props.dataid.id,
        drug_daily_frequency_repeats: day_interval != 1 ? 1 : parseInt(dosage_per_day),
        drug_duration_time_taper: day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months'
      }, () => { this.updateQuantity(); this.updateEndDate(); this.checkDrugSaveBtn(); })
    AntSelectLabels();

  }

  updateStartDate = async (val) => {
    if (val == null) {
      await this.setState({
        start_date: moment().format('YYYY-MM-DD'),
        drug_start_date: moment().format('YYYY-MM-DD')
      })
    } else {
      await this.setState({
        start_date: moment(val).format('YYYY-MM-DD'),
        drug_start_date: moment(val).format('YYYY-MM-DD')
      })
    }

    this.updateEndDate();
  }

  updateEndDate = () => {
    let drug_number_of_days, drug_daily_frequency, drug_start_date;
    drug_start_date = this.state.drug_start_date;

    if (!this.state.isModalTaperDoseVisible) {
      drug_number_of_days = this.state.drug_number_of_days;
      drug_daily_frequency = this.state.drug_daily_frequency;
    }
    else {
      drug_number_of_days = this.state.drug_number_of_days_taper;
      drug_daily_frequency = this.state.drug_daily_frequency_taper;
    }
    if (drug_number_of_days && drug_daily_frequency && drug_start_date) {
      var number_of_days = parseInt(drug_number_of_days);
      var start_date = drug_start_date;
      var end_date = null;
      let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === drug_daily_frequency)
      frequencyObjKey = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)]
      var day_interval = frequencyObjKey.interval;
      day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : 1);
      var times_correction = frequencyObjKey.total_times;
      var dosage_per_day = frequencyObjKey.total_repeats;
      if (typeof number_of_days != 'undefined' && !isNaN(number_of_days) && typeof start_date != 'undefined' && start_date != '' && times_correction != 0) {
        if (day_interval != 1)
          // number_of_days = Math.round(number_of_days / dosage_per_day);
          number_of_days = number_of_days * day_interval * times_correction;
        end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
      }
      if (times_correction == 0) {
        end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
        this.setState({ drug_start_date: start_date, drug_end_date: end_date }, () => { this.checkDrugSaveBtn() });
      } else {
        this.setState({ drug_end_date: end_date }, () => { this.checkDrugSaveBtn() });
      }
    }
    else if (drug_start_date == null)
      this.setState({ drug_end_date: null }, () => { this.checkDrugSaveBtn() });
  }

  updateQuantity = async () => {
    let drug_fixed_qty, drug_number_of_days, drug_dosage, drug_frequency, drug_daily_frequency, drug_dosage_form_text_quantity;
    drug_fixed_qty = this.state.drug_fixed_qty;
    drug_dosage_form_text_quantity = this.state.drug_dosage_form_text_quantity;
    if (!this.state.isModalTaperDoseVisible) {
      drug_number_of_days = this.state.drug_number_of_days;
      drug_dosage = this.state.drug_dosage;
      drug_frequency = this.state.drug_frequency;
      drug_daily_frequency = this.state.drug_daily_frequency;
    }
    else {
      drug_number_of_days = this.state.drug_number_of_days_taper;
      drug_dosage = this.state.drug_dosage_taper;
      drug_frequency = this.state.drug_frequency_taper;
      drug_daily_frequency = this.state.drug_daily_frequency_taper;
    }
    if (drug_dosage && drug_frequency) {
      let quantity = '';
      if (!isNaN(parseInt(drug_fixed_qty))) quantity = parseInt(drug_fixed_qty);
      else {
        var number_of_days = drug_number_of_days;
        var dosageVal = this.state.drugDosage[drug_dosage];
        drug_dosage = parseFloat(eval(dosageVal.replace(/[^\d.-/]/g, '')));
        var day_interval;
        var dosage_per_day;
        if (drug_daily_frequency != null && drug_daily_frequency != '') {
          let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === drug_daily_frequency)
          frequencyObjKey = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)];
          day_interval = frequencyObjKey.interval;
          var times_correction = frequencyObjKey.total_times;
          dosage_per_day = frequencyObjKey.total_repeats;
        }


        day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : 1);
        // if (day_interval != 1) dosage_per_day = 1;
        if (drug_frequency == 3) {
          quantity = drug_dosage;
        } else {
          // if (times_correction == 0) {
          //   // $('#number_of_days').prop('disabled', true);
          //   number_of_days = 1;
          // }
          // else {
          //   // $('#number_of_days').prop('disabled', false);
          // }
          if (drug_dosage_form_text_quantity != 'Unit') {
            if(this.state.drug_daily_frequency === 15){
              let numberOfDays = Math.ceil(number_of_days / 2);
              quantity = Math.ceil(parseInt(numberOfDays) * drug_dosage * dosage_per_day);
            }else{
              quantity = parseInt(number_of_days) * drug_dosage * dosage_per_day;
            }
          } else {
            quantity = 1;
          }
        }
        if (!this.state.isModalTaperDoseVisible)
          this.setState({ drug_number_of_days: number_of_days }, () => { this.checkDrugSaveBtn() })
        else
          this.setState({ drug_number_of_days_taper: number_of_days }, () => { this.checkDrugSaveBtn() })
      }

      if (drug_dosage_form_text_quantity != 'Unit' && this.state.dose_for_schedule_applicable == 'Y') {
        var morning = this.state.dosageDropDownMorning && this.state.dosageDropDownMorning != "" ? parseFloat(this.state.dosageDropDownMorning) : 0;
        var afternoon = this.state.dosageDropDownAfternoon && this.state.dosageDropDownAfternoon != "" ? parseFloat(this.state.dosageDropDownAfternoon) : 0;
        var evening = this.state.dosageDropDownEvening && this.state.dosageDropDownEvening != "" ? parseFloat(this.state.dosageDropDownEvening) : 0;
        var night = this.state.dosageDropDownNight && this.state.dosageDropDownNight != "" ? parseFloat(this.state.dosageDropDownNight) : 0;
        quantity = (morning + afternoon + evening + night) * parseInt(number_of_days);
      }
      let formObj = await this.state.DRUGSConstants?.drugForm?.find(item => item.id == this.state.drug_form);
      if (formObj && formObj.fixedQuantity && formObj.fixedQuantity != null) {
        quantity = formObj.fixedQuantity;
      }

      if (!isNaN(quantity) || drug_number_of_days == ""){
        quantity = !isNaN(quantity)? quantity : '';
        this.setState({ drug_quantity: quantity }, () => { AntSelectLabels(); AntClearLabels(); jQueryLabels(); this.checkDrugSaveBtn() })
      }
    }

  }

  handleInputOnChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => { this.checkDrugSaveBtn() })
  }

  filterDrugDosage = (inputValue, option) => {

  }

  onSearchDrugDosage = (val) => {
    if (!this.state.isModalTaperDoseVisible){
        if(val === "" || /^[1-9]\d*(?:\.\d{0,2})?$/.test(val)){
          this.setState({ dosageDropDown: val })
        }
      }else{
        if(val === "" || /^[1-9]\d*(?:\.\d{0,2})?$/.test(val)){
          this.setState({ dosageDropDownTaper: val })
        }
      }
  }

  onSearchDrugDosageMorning = (val) => {
    this.setState({ dosageDropDownMorning: val })
  }

  onSearchDrugDosageAfternoon = (val) => {
    this.setState({ dosageDropDownAfternoon: val })
  }

  onSearchDrugDosageEvening = (val) => {
    this.setState({ dosageDropDownEvening: val })
  }

  onSearchDrugDosageNight = (val) => {
    this.setState({ dosageDropDownNight: val })
  }

  getDosageUpdate(event) {
    var val = event.target.value;
    var dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == val);
    var DRUGSConstants = this.state.DRUGSConstants;
    var drugDosage = this.state.drugDosage;
    var highestDosageId = this.state.highestDosageId;
    var drugDosageValue = null;
    if (typeof dosage == 'undefined' && val != null && val != '') {
      this.state.DRUGSConstants.drugDosage.map((obj, i) => {
        highestDosageId = highestDosageId == null || highestDosageId < obj.id ? obj.id : highestDosageId;
      })
      drugDosage[parseInt(highestDosageId) + 1] = "" + val;
      DRUGSConstants.drugDosage.push({ id: parseInt(highestDosageId) + 1, description: val });
      drugDosageValue = parseInt(highestDosageId) + 1;
      this.setState({ drugDosage, DRUGSConstants });
    }
    else {
      drugDosageValue = dosage
    }
    return drugDosageValue;
  }

  onBlurDrugDosage = (event) => {
    var drugDosageValue = this.getDosageUpdate(event)
    if (!this.state.isModalTaperDoseVisible)
      this.setState({ drug_dosage: drugDosageValue }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
    else
      this.setState({ drug_dosage_taper: drugDosageValue }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onBlurDrugDosageMorning = (event) => {
    var drugDosageValue = this.getDosageUpdate(event)
    this.setState({ drug_dosage_morning: drugDosageValue }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onBlurDrugDosageAfternoon = (event) => {
    var drugDosageValue = this.getDosageUpdate(event)
    this.setState({ drug_dosage_afternoon: drugDosageValue }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onBlurDrugDosageEvening = (event) => {
    var drugDosageValue = this.getDosageUpdate(event)
    this.setState({ drug_dosage_evening: drugDosageValue }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onBlurDrugDosageNight = (event) => {
    var drugDosageValue = this.getDosageUpdate(event)
    this.setState({ drug_dosage_night: drugDosageValue }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onChangeDrugDosage = (val) => {
    var dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == val);
    if (!this.state.isModalTaperDoseVisible)
      this.setState({ dosageDropDown: val, drug_dosage: dosage }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
    else
      this.setState({ dosageDropDownTaper: val, drug_dosage_taper: dosage }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onChangeDrugDosageMorning = (val) => {
    var dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == val);
    this.setState({ dosageDropDownMorning: val, drug_dosage: dosage }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onChangeDrugDosageAfternoon = (val) => {
    var dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == val);
    this.setState({ dosageDropDownAfternoon: val, drug_dosage: dosage }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onChangeDrugDosageEvening = (val) => {
    var dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == val);
    this.setState({ dosageDropDownEvening: val, drug_dosage: dosage }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onChangeDrugDosageNight = (val) => {
    var dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == val);
    this.setState({ dosageDropDownNight: val, drug_dosage: dosage }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
  }

  onChangeDrugRoute = (val) => {
    if (!this.state.isModalTaperDoseVisible)
      this.setState({ drug_route: val }, () => { this.checkDrugSaveBtn() });
    else
      this.setState({ drug_route_taper: val }, () => { this.checkDrugSaveBtn() });
  }

  onChangeDrugFrequency = (e) => {
    if (!this.state.isModalTaperDoseVisible)
      this.setState({ drug_frequency: e.target.value }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
    else
      this.setState({ drug_frequency_taper: e.target.value }, () => { this.updateQuantity(); this.checkDrugSaveBtn() });
    if (this.state.medicineFormValidate && e.target.value != 3 && (this.state.drug_start_date == null || typeof this.state.drug_start_date == 'undefined')) {
      this.setState({ drug_start_date: moment().format('YYYY-MM-DD') });
    }
    if (e.target.value != 3 && (this.state.drug_start_date == null || typeof this.state.drug_start_date == 'undefined') && this.state.editData != null && this.state.editData.start_date == null && this.state.editData.previousEndDate != null && this.state.editData.taper_row) {
      this.setState({ drug_start_date: moment(this.state.editData.previousEndDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') });
    }
  }

  onChangeDaysInWeek = (val, props) => {
    let arr = [];
    let weekDaysName = [];
    props.forEach((ele, i) => { arr.push(ele.id); weekDaysName.push(ele.title) });
    weekDaysName = weekDaysName.join("-");

    if (!this.state.isModalTaperDoseVisible)
      this.setState({
        drug_days_in_week: arr,
        drug_days_in_week_text: weekDaysName,
      }, () => { this.checkDrugSaveBtn() })
    else
      this.setState({
        drug_days_in_week_taper: arr,
        drug_days_in_week_text_taper: weekDaysName,
      }, () => { this.checkDrugSaveBtn() })

  }

  onChangeDrugUrgency = (val) => {
    this.setState({ drug_urgency: val }, () => { this.checkDrugSaveBtn() });
  }

  onChangeDrugEvent = (val) => {
    this.setState({ drug_event: val }, () => { this.checkDrugSaveBtn() });
  }

  onChangeLMPEventDurationType = (val) => {
    this.setState({ drug_lmp_event_duration_type: val }, () => { this.checkDrugSaveBtn() });
  }

  onChangeDrugInstruction = (val) => {
    this.setState({ drug_instruction: val }, () => { this.checkDrugSaveBtn() });
  }

  onChangeDrugInstructionMorning = (val) => {
    this.setState({ drug_instruction_morning: val }, () => { this.checkDrugSaveBtn() });
  }

  onChangeDrugInstructionAfternoon = (val) => {
    this.setState({ drug_instruction_afternoon: val }, () => { this.checkDrugSaveBtn() });
  }

  onChangeDrugInstructionEvening = (val) => {
    this.setState({ drug_instruction_evening: val }, () => { this.checkDrugSaveBtn() });
  }

  onChangeDrugInstructionNight = (val) => {
    this.setState({ drug_instruction_night: val }, () => { this.checkDrugSaveBtn() });
  }

  handleSelectOnChange(e) {
    this.setState({ drug_form: e, availableDrugRoutes: [] }, () => { this.updateQuantity(); this.optionRouteLoad(); this.checkDrugSaveBtn();  })
  }

  onSelectRouteChange = (val) => {
    this.setState({ drug_route: val }, () => { this.checkDrugSaveBtn() })
  }

  onSelectPriorityChange = (val, props) => {
    this.setState({ drug_urgency: val }, () => { this.checkDrugSaveBtn() })
  }

  onChangeNumberOfDays = (val) => {
    if (!this.state.isModalTaperDoseVisible) {
      if (/^0/.test(val.target.value)) {
        this.setState({ drug_number_of_days: '' })
      } else {
        this.setState({ drug_number_of_days: !isNaN(val.target.value) ? val.target.value : null }, () => {
          this.updateEndDate();
          this.updateQuantity();
          this.checkDrugSaveBtn();
        });

        if (val.target.value === "") {
          this.setState({ drug_end_date: null })
        }
      }
    }
    else {
      this.setState({ drug_number_of_days_taper: !isNaN(val.target.value) ? val.target.value : null }, () => {
        this.updateEndDate();
        this.updateQuantity();
        this.checkDrugSaveBtn();
      })
    }
  }

  onChangeDurationSelect = async (value, name) => {
    await this.setState({
      [name]: value
    });
  }

  onChangeLMPEventDuration = (val) => {
    this.setState({
      drug_lmp_event_duration: !isNaN(val.target.value) ? val.target.value : null
    }, () => {
      this.checkDrugSaveBtn();
    })
  }

  onChangeCorrectionDose = async (val) => {
    var new_correction_dose = this.state.correction_dose_value;
    if (new_correction_dose.hasOwnProperty(val.target.dataset.position)) {
      new_correction_dose[val.target.dataset.position][val.target.dataset.name] = val.target.value ? (val.target.value) : undefined;
    }
    else {
      new_correction_dose[val.target.dataset.position] = {
        [val.target.dataset.name]: val.target.value ? (val.target.value) : undefined
      }
    }

    var new_correction_dose_validate = this.state.correction_dose_validate;
    if (new_correction_dose_validate.hasOwnProperty(val.target.dataset.position)) {
      // Do nothing
    }
    else {
      new_correction_dose_validate[val.target.dataset.position] = []
    }
    if (val.target.dataset.name == "correction_dose_from") {
      new_correction_dose_validate[val.target.dataset.position][0] = val.target.value ? (val.target.value) : undefined;
    }
    else if (val.target.dataset.name == "correction_dose_to") {
      new_correction_dose_validate[val.target.dataset.position][1] = val.target.value ? (val.target.value) : undefined;
    }
    else if (val.target.dataset.name == "correction_dose_unit") {
      new_correction_dose_validate[val.target.dataset.position][2] = val.target.value ? (val.target.value) : undefined;
    }
    await this.setState({
      correction_dose_value: new_correction_dose,
      correction_dose_validate: new_correction_dose_validate
    }, () => { this.checkDrugSaveBtn(); });
  }

  getDoseRule = () => {
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    const staff_id = LS_SERVICE.get('staff_id');
    const speciality_id = LS_SERVICE.get('speciality_id');
    if (LS_SERVICE.get("user_type") == USERTYPES.doctor) {
      const soapDoseRule = Axios.post(DOSE_RULE, qs.stringify({ staff_id, hospital_id, speciality_id }));
      const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor;
      const specialityPromise = Axios.post(PROFILE({ is_doctor }));
      Axios.all([soapDoseRule, specialityPromise])
        .then(Axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];
          const ruleInfo = Object.values(responseOne.data);
          const paediaInfo = Object.values(responseTwo.data);
          this.setState({
            doseRule: ruleInfo[0] ? ruleInfo[0].rule : 3
          });
          const is_paediatrician = LS_SERVICE.get('isPaediatrician');
          if (is_paediatrician == 1) {
            this.setState({ isPaediatricSpeciality: 1 });
          }
        }))
    }
  }

  doseCalculator = (drug_dosage) => {
    let dose;
    let gender = LS_SERVICE.get('gender');
    let dob = LS_SERVICE.get('dob');
    var today = moment();
    let patient_dose = 0;
    let doseRule = this.state.localDoseRule;
    if (typeof drug_dosage != 'undefined') {
      if (doseRule == 1) {
        //Dose = [Age (month)/150] * Adult Dose
        if (dob != undefined && dob != null) {
          dob = moment(dob, 'YYYY-MM-DD')
          var ageInMonths = today.diff(dob, 'months');
          dose = (parseInt(ageInMonths) / parseInt(150)) * drug_dosage.adult_dose;
        }
      } else if (doseRule == 2) {
        //Dose =  (Age in years/ Age in years +12) * Adult Dose
        if (dob != undefined && dob != null) {
          dob = moment(dob, 'YYYY-MM-DD')
          var ageInYears = today.diff(dob, 'years');
          var average_year = parseFloat(ageInYears) + parseInt(12)
          dose = (parseFloat(ageInYears) / parseFloat(average_year)) * drug_dosage.adult_dose;
        }
      }
      else if (doseRule == 3) {
        //Dose = (Body Weight in Kgs/ 70) * Adult Dose
        if (typeof this.state.weightbmi != 'undefined' && this.state.weightbmi != '' && parseFloat(this.state.weightbmi) > 0 && typeof drug_dosage.adult_dose != 'undefined')
          dose = (parseFloat(this.state.weightbmi) / 70) * drug_dosage.adult_dose;

      }
      else if (doseRule == 4) {
        //(Child's BSA/ Average BSA for adult) * Adult Dose
        let average = 0;
        if (gender == "Male")
          average = AVERAGE_BSA_FOR_MALE;
        else if (gender != "Male")
          average = AVERAGE_BSA_FOR_FEMALE;

        if (typeof this.state.bsa != 'undefined' && this.state.bsa != '')
          dose = (parseFloat(this.state.bsa) / parseFloat(average)) * drug_dosage.adult_dose;
      }

      //dose = this.getClosestDosage(dose);

      if (drug_dosage.adult_dose_uom != drug_dosage.uom_second.code) {
        var strength = "" + drug_dosage.strength;
        strength = strength.replace(/\D/g, "");
        patient_dose = parseFloat(strength) / drug_dosage.in_every;
      }


      if (patient_dose > 0)
        dose = dose / patient_dose;

      if (this.props.formType != 'template' && this.state.isPaediatricSpeciality == 1 && drug_dosage.adult_dose) {
        this.setState({
          adult_dose_exists: true
        })
      }
      else {
        this.setState({
          adult_dose_exists: false
        })
      }
      /*if(dose !='' && dose > 0)
      {
        dose = Math.round(((dose + Number.EPSILON) * 100)/ 100);
      }*/

      if (isNaN(dose)) {
        dose = 0;
      }

      if (dose > 0) {
        dose = dose.toFixed(2);
      }

    }
    else {
      dose = 0;
    }

    //return dose.toFixed(2);
    return dose;
  }

  getClosestDosage(val) {
    var fract = val % 1;
    var valInt = parseInt(val);
    fract = parseFloat(fract.toFixed(1));
    if (fract < 0.25)
      fract = 0;
    else if (fract >= 0.25 && fract <= 0.75)
      fract = 0.5;
    else if (fract > 0.75)
      fract = 1;
    return valInt + fract;
  }

  onChangeDrugsSelect = (value, props) => {
    let disabled = this.state.MedicineAddInitialListed.filter((data, i) => data.drug_id === value);
    axios.post(DRUG, qs.stringify({
      id: value
    })).then(success => {
      if (this.props.formType != 'template') {
        let { patient } = this.state;
        if (patient == null || typeof patient == 'undefined') {
          patient = this.props.patient;
        }

        const { patient_id } = patient;
        this.setState({
          correction_dose_value: [],
          correction_dose_data_value: [],
          correction_dose_validate: [],
          correction_dose_data: [],
        }, () => {
          axios.post(FETCH_DEFAULT_CORRECTION_DOSE, qs.stringify({
            drug_id: value,
            patient_id: patient_id
          })).then(success => {
            var value_data = [];
            var validation_data = [];
            if (success?.data?.data?.length > 0) {
              for (var dose of success?.data?.data) {
                value_data.push({
                  correction_dose_from: dose.range_min,
                  correction_dose_to: dose.range_max,
                  correction_dose_unit: dose.range_unit,
                })
                validation_data.push([dose.range_min, dose.range_max, dose.range_unit])
              }
            }
            this.setState({
              correction_dose_value: value_data,
              correction_dose_data_value: value_data,
              correction_dose_validate: validation_data,
              correction_dose_data: validation_data,
            })
            var overlap_status = this.checkRangeOverlap(validation_data) || false
            this.setState({ AddCorrectionDoseButton: overlap_status })
          });
        });
      }
      const { currentMeds, polyPharmacyFlag, medicine_remarks, cimsDrugInteractionErrorDummy } = this.state;
      const response = success.data.data.result;
      this.setState({ localDoseRule: this.state.doseRule, responseDrugDosage: response.drug_dosage[0], correction_dose_applicable: 'N', dose_for_schedule_applicable: 'N' })
      if (this.props.formType == "soap-full") {
        let drug_name = response.name;
        drug_name = drug_name.toLowerCase();
        let drugInteraction = Object.keys(cimsDrugInteractionErrorDummy).find(key => drug_name.includes(key));
        let interactingDrugs = cimsDrugInteractionErrorDummy[drugInteraction];
        if (typeof interactingDrugs != 'undefined') {
          let interactionDrugs = this.props.interactionDrugs;
          let showInteractionModal = false;
          let interactionDrugName = '';
          interactingDrugs.map((v, k) => {
            interactionDrugs.map((drug, key) => {
              drug = drug.toLowerCase();
              if (drug.includes(v)) {
                showInteractionModal = true;
                interactionDrugName = v
              }
            })
          })
          interactionDrugName = interactionDrugName.charAt(0).toUpperCase() + interactionDrugName.substr(1).toLowerCase();
          this.setState({ isModalInteractionVisible: showInteractionModal, interactionDrugName })
        }
      }
      let strength;
      let strength_txt;
      let uom_first = '';
      let number_of_days;
      let frequencyid = '';
      let route = '';
      let instruction = 4;
      let dosage = 2;
      let start_date = null;
      let end_date = null;
      let quantity = null;
      let quantity_text = '';
      let schedules = [];
      let schedules_name = [];
      var dose_for_schedule = 'N';
      var correction_dose = 'N';
      if (response.drug_dosage.length > 0) {
        dose_for_schedule = response.drug_dosage[0].dose_for_schedule;
        correction_dose = response.drug_dosage[0].correction_dose;
        strength = response.drug_dosage[0].strength;
        if (response.drug_dosage[0].uom_first) {
          uom_first = Object.keys(response.drug_dosage[0].uom_first).length === 0 ? '' : response.drug_dosage[0].uom_first.short_description;
        }
        strength_txt = (parseFloat(strength) == 0 && uom_first.toLowerCase() == 'na') || strength == '' ? 'NA' : (parseFloat(strength) + ' ' + uom_first).trim();
        dosage = response.drug_dosage && response.drug_dosage.length > 0 && response.drug_dosage[0].in_every ? response.drug_dosage[0].in_every : 1;
        var dosageValue
        //If speciality is Paediatrics
        if (this.state.isPaediatricSpeciality == 1 && this.state.isPaediatrics && this.props.formType != 'template') {
          // For paediatrics
          dosageValue = this.doseCalculator(response.drug_dosage[0]);
          dosage = dosageValue == 0 ? null : Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == dosageValue);
          // if (typeof dosage == 'undefined'){
          //   dosage = this.getClosestDosage(dosageValue);
          // }else
          dosage = dosageValue;

        }
        dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == dosage);
        if (this.props.formType != 'template' && typeof dosage == 'undefined' && dosageValue != null && dosageValue != '') {
          var DRUGSConstants = this.state.DRUGSConstants;
          var drugDosage = this.state.drugDosage;
          var highestDosageId = this.state.highestDosageId;
          this.state.DRUGSConstants.drugDosage.map((obj, i) => {
            highestDosageId = highestDosageId == null || highestDosageId < obj.id ? obj.id : highestDosageId;
          })
          drugDosage[parseInt(highestDosageId) + 1] = "" + dosageValue;
          DRUGSConstants.drugDosage.push({ id: parseInt(highestDosageId) + 1, description: dosageValue });
          this.setState({ drugDosage, DRUGSConstants });
          quantity = dosageValue;
          dosage = parseInt(highestDosageId) + 1;
        } else if (this.props.formType != 'template') {
          dosage = typeof dosage == 'undefined' ? null : parseInt(dosage);
          quantity = this.state.drugDosage[dosage];
        } else {
          dosage = typeof dosage == 'undefined' ? 1 : parseInt(dosage);
          quantity = this.state.drugDosage[dosage];
        }
      }

      var dosage_per_day = null;
      var day_interval = null;
      if (response.drug_frequency.length > 0) {
        number_of_days = response.drug_frequency[0].duration;
        start_date = moment().format('YYYY-MM-DD');
        if (typeof response.drug_frequency[0].frequency_id != 'undefined') {
          day_interval = response.drug_frequency[0].frequency_id.interval_per;
          day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : 1);
          var times_correction = response.drug_frequency[0].frequency_id.total_times;
          dosage_per_day = response.drug_frequency[0].frequency_id.total_repeats;
          if (day_interval != 1)
            number_of_days = Math.round(number_of_days / dosage_per_day);

          var endDatedays = (number_of_days * day_interval * times_correction) - 1;
          end_date = moment(start_date, 'YYYY-MM-DD').add(endDatedays, 'days').format('YYYY-MM-DD');
        }

      }
      if (response.drug_frequency.length > 0 && typeof response.drug_frequency[0].frequency_id != 'undefined') {
        frequencyid = response.drug_frequency[0].frequency_id.id;
        let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === frequencyid)
        schedules = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)].schedule.map(function (obj) { return obj.id });
        schedules_name = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)].schedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-');
      }

      if (response?.form_id?.fixed_quantity && response?.form_id?.fixed_quantity != null) {
        //hello
        quantity = response.form_id.fixed_quantity;
        quantity_text = 'Unit';
      }
      else {
        let formObj = this.state.DRUGSConstants?.drugForm?.find(item => item?.id == response?.form_id?.id);
        if (formObj?.fixedQuantity != null) {
          quantity = formObj.fixedQuantity;
          quantity_text = 'Unit';
        }
      }
      let drug_dosage_form_text = typeof response.drug_dosage[0]?.uom_second == 'undefined' || Object.keys(response.drug_dosage[0]?.uom_second).length === 0 ? '-' : response.drug_dosage[0]?.uom_second?.short_description;

      // let drug_frequency = frequencyid!=''?(frequencyid==0?3:1):1;
      let drug_frequency = frequencyid == '' || frequencyid == 0 ? 1 : 1;
      let drug_number_of_days = response.drug_frequency[0]?.duration;
      if (drug_frequency == 3) {
        start_date = null;
        end_date = null;
        drug_number_of_days = null;
      }
      // else if (times_correction == 0) {
      //   start_date = null;
      //   end_date = null;
      // }

      let availableDrugRoutesTemp = response.drug_route_for_form;
      let availableDrugRoutes = [];
      let defaultRouteExist = false;
      let defaultDrugRoute = response.drug_route[0]?.route_id ? response.drug_route[0]?.route_id : response.drug_route[0]?.id;
      if (availableDrugRoutesTemp.length == 0 || availableDrugRoutesTemp == null)
        availableDrugRoutes = this.state.DRUGSConstants.route;
      else
        availableDrugRoutesTemp.map((v, k) => {
          if (defaultDrugRoute == v.routeId)
            defaultRouteExist = true;
          availableDrugRoutes.push({ id: parseInt(v.routeId), description: v.long })
        })

      var drug_number_of_days_calculated;
      if (props.fav == 1) {
        drug_number_of_days_calculated = drug_number_of_days
      }
      else {
        drug_number_of_days_calculated = null;
      }
      if (this.props.formType != 'template' && (!drug_number_of_days_calculated || drug_number_of_days_calculated == null || drug_number_of_days_calculated == "")) {
        end_date = null;
      }

      this.setState({ drug_duration_time: day_interval === 1 ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months' });

      this.setState({
        DrugApi: response,
        drug_id: response.id,
        drug_name: props.title,
        drug_dosage:  props.fav == 1 && response.dosage? response.dosage : dosage,
        drug_dosage_value: dosage != null ? this.state.drugDosage[dosage] : '',
        dosageDropDown: (response.dosage)? this.state.drugDosage[response.dosage] : dosage ? this.state.drugDosage[dosage] : '',
        // drug_number_of_days: drug_number_of_days,
        drug_number_of_days: props.fav == 1 ? drug_number_of_days : '',
        drug_frequency: response.frequency? response.frequency : drug_frequency,
        drug_days_in_week: props.fav == 1 && response.days_in_week != null ? response.days_in_week.split(',').map(element => Number(element)) : this.state.drug_days_in_week,
        drug_strength: strength_txt,
        drug_dosage_form_text: drug_dosage_form_text,
        drug_form: response.form_id?.id,
        drug_form_defaulted: response.form_id?.id ? true : false,
        drug_route: defaultRouteExist ? defaultDrugRoute : '',
        drug_daily_frequency: frequencyid,
        drug_schedules: props.fav == 1 && response.schedules != null ? response.schedules.split(',').map(element => Number(element)) : (props.fav == 1 && response.schedules == null)? [] : schedules,
        drug_schedule_name: schedules_name,
        drug_instruction:  props.fav == 1 && response.instruction? response.instruction: 4,
        drug_instruction_morning: '',
        drug_instruction_afternoon: '',
        drug_instruction_evening: '', 
        drug_instruction_night: '',
        drug_medicine_remarks: props.fav == 1? response.medicine_remarks : '',
        drug_urgency:  response.urgency? response.urgency : 3,
        drug_dosage_form: 0,
        dose_for_schedule: dose_for_schedule,
        correction_dose: correction_dose,
        drug_fixed_qty: response.form_id?.fixed_quantity && response.form_id?.fixed_quantity != null ? response.form_id?.fixed_quantity : null,
        drug_quantity: quantity,
        drug_dosage_form_text_quantity: quantity_text != '' ? quantity_text : drug_dosage_form_text,
        drug_start_date: start_date,
        drug_end_date: end_date,
        drug_generic: response.drug_generic,
        drug_daily_frequency_repeats: day_interval != 1 ? 1 : dosage_per_day,
        selectDrugValue: props.title,
        isDisabled: true,
        drug_id_list: [...this.state.drug_id_list, response.id],
        isFavCheckedVal: props.fav,
        availableDrugRoutes: availableDrugRoutes,
        AddMedicineButton: false,
        medicineFormValidate: true,
        isBorderColor: true

      }, () => { this.updateQuantity(); this.checkDrugSaveBtn(); this.handleTextareaAutoHeight('drug_medicine_remarks'); });
      AntSelectLabels();

      if (this.props.formType != 'template') {
        if (currentMeds.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
          this.showPolyModal()
        }
      }
    }).catch(err => console.log(err))
  }

  MasterDrugApi = (search) => {
    this.setState({
      formIsFetching: true
    })

    let params = { search: search };
    if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
      params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null;
    } else if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
      params.hospital_id = LS_SERVICE.get('master_hospital_id') ?? null;
    } else {
      params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
    }

    axios.post(SEARCH_DRUGS, qs.stringify(params)).then(success => {
      this.setState({
        searchDrugs: success.data.medicine_list,
        formIsFetching: false,
        AddMedicineButton: true,
        showAddDrugButton: (search.length > 2 && success.data?.medicine_list?.length == 0 && this.props.formType != 'template') ? false : true,
      });
    }).catch(err => console.log(err))
  }


  onDrugssearch = (value) => {
    this.MasterDrugApi(value);
    this.setState({ selectDrugValue: value });
  }

  FavoriteApi = (val) => {

    // if (this.props.isPracticing === false) {
    //   this.props.practiceModal('isPracticingModal');
    //   return;
    // }

    let dosageObj = this.state.dosageConstantOriginal.find(item => item.id == this.state.drug_dosage);

    if (val != null && val !== '') {
      this.favDrug = [];
      this.favArr['drug_id'] = this.state.drug_id;
      this.favArr['drug_name'] = this.state.drug_name;
      this.favArr['drug_form'] = this.state.drug_form;
      this.favArr['dosage'] = dosageObj ? dosageObj.id : null;
      this.favArr['dosage_form'] = this.state.drug_dosage_form == '' || this.state.drug_dosage_form == null ? 0 : this.state.drug_dosage_form;
      this.favArr['strength'] = this.state.drug_strength;
      this.favArr['route'] = this.state.drug_route;
      this.favArr['instruction'] = this.state.drug_instruction;
      this.favArr['medicine_remarks'] = this.state.drug_medicine_remarks;
      this.favArr['drug_frequency'] = this.state.drug_frequency;
      this.favArr['days_in_week'] = this.state.drug_days_in_week;
      this.favArr['fav'] = this.state.isFavCheckedVal ? 0 : 1;
      this.favArr['daily_frequency'] = this.state.drug_frequency != 3 ? this.state.drug_daily_frequency : null;
      this.favArr['number_of_days'] = this.state.drug_frequency != 3 ? this.state.drug_number_of_days : null;
      this.favArr['schedules'] = this.state.drug_frequency != 3 ? this.state.drug_schedules : null;
      this.favArr['urgency'] = this.state.drug_frequency != 3 ? this.state.drug_urgency : null;
      this.favDrug.push(this.favArr);

      const elem = document.querySelector('#drug_id').parentElement?.nextSibling?.querySelector('.desc');
      let searchKeys = elem !== undefined ? elem.innerText : document.querySelector('#drug_id').value;
      searchKeys = searchKeys.replace(/[^\w\s]/gi, ' ');

      axios.post(DRUG_FAVOURITE, qs.stringify({
        drug_id: this.state.drug_id,
        fav_drug: this.favDrug,
        hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
      })).then(success => {
        let searchList = this.state.searchDrugs;
        let drug_id = this.state.drug_id;
        let objIndex = Object.keys(searchList).find(key => searchList[key].id === drug_id)
        searchList[objIndex].fav = !searchList[objIndex].fav;
        this.setState({
          isFavCheckedVal: !this.state.isFavCheckedVal,
          searchDrugs: searchList
        }, () => { this.optionDrugLoad() });
      }).catch(err => {
        this.setState({
          isFavCheckedVal: this.state.isFavCheckedVal ? 0 : 1
        })
      })
    }
  }


  Taperdose = (val) => {
    const { addTaperDose } = this.state;
    this.setState({
      addTaperDose: !addTaperDose
    })
  }

  ContinueTillReview = (val) => {
    this.setState({
      addContinueTillReview: val == 'Y' ? 'N' : 'Y'
    })
  }

  handleUpdateFormForEdit() {
    this.EditList(this.state.editData.i, this.state.editData)
  }

  handleEditData = async () => {
    if (this.props.editData != null) {
      let data = this.props.editData;
      let index = this.props.editIndex;
      let quantity = null;
      let quantity_text = '';
      let searchDrugs = [{
        id: data.drug_id,
        fav: data.fav,
        name: data.drug_name
      }]
      this.setState({ searchDrugs: searchDrugs }, () => { this.optionDrugLoad() });

      if (data.correction_dose == 'Y') {
        var value_data = [];
        var validation_data = [];
        if (data?.correction_dose_data?.length > 0) {
          for (var dose of data?.correction_dose_data) {
            value_data.push({
              correction_dose_from: dose.range_min,
              correction_dose_to: dose.range_max,
              correction_dose_unit: dose.range_unit,
            })
            validation_data.push([dose.range_min, dose.range_max, dose.range_unit])
          }
        }
        await this.setState({
          correction_dose_applicable: 'Y',
          correction_dose_value: value_data,
          correction_dose_data_value: value_data,
          correction_dose_validate: validation_data,
          correction_dose_data: validation_data,
        })
      }
      else {
        this.setState({
          correction_dose_applicable: 'N'
        });
        this.setState({
          correction_dose_value: [],
          correction_dose_data_value: [],
          correction_dose_validate: [],
          correction_dose_data: [],
        }, () => {
          if (this.props.formType != 'template') {
            axios.post(FETCH_DEFAULT_CORRECTION_DOSE, qs.stringify({
              drug_id: data.drug_id,
              patient_id: this.state.patient.patient_id
            })).then(success => {
              var value_data = [];
              var validation_data = [];
              if (success?.data?.data?.length > 0) {
                for (var dose of success?.data?.data) {
                  value_data.push({
                    correction_dose_from: dose.range_min,
                    correction_dose_to: dose.range_max,
                    correction_dose_unit: dose.range_unit,
                  })
                  validation_data.push([dose.range_min, dose.range_max, dose.range_unit])
                }
              }
              this.setState({
                correction_dose_value: value_data,
                correction_dose_data_value: value_data,
                correction_dose_validate: validation_data,
                correction_dose_data: validation_data,
              })
              var overlap_status = this.checkRangeOverlap(validation_data) || false
              this.setState({ AddCorrectionDoseButton: overlap_status })
            });
          }
        });
      }
      const { drugEditApiStatus } = this.state;

      this.setState({
        TaperBtnEnabled: data.taper_row ? 0 : 1,
        editIndex: index
      });
      let selectSchedules = [];
      if (data.schedules !== undefined && data.schedules !== null && data.schedules != "") {
        data.schedules = data.schedules.map(data => parseInt(data))
      }
      if (data.days_in_week !== undefined && data.days_in_week !== null && data.days_in_week.length > 0) {
        if (Array.isArray(data.days_in_week) === false) {
          data.days_in_week = data.days_in_week.split(',');
        }
        data.days_in_week = data.days_in_week.map(data => parseInt(data))
      }

      if (data.daily_frequency) {
        let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === data.daily_frequency)
        frequencyObjKey = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)]
        var day_interval = frequencyObjKey.interval;
        day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : 1);
        var times_correction = frequencyObjKey.total_times;
        var dosage_per_day = frequencyObjKey.total_repeats;
      }
      var drugDosage = this.state.drugDosage;
      var DRUGSConstants = this.state.DRUGSConstants;
      var dosageKey = Object.keys(drugDosage).find(key => drugDosage[key] == data.dosage_value);
      var dosageDropdownValue = '';
      
      if (data.dosage_value != null && data.dosage_value != '' && !dosageKey) {
        var highestDosageId = Math.max(...Object.keys(drugDosage).map(Number))
        DRUGSConstants.drugDosage.push({ id: parseInt(highestDosageId) + 1, description: "" + data.dosage_value });
        drugDosage[parseInt(highestDosageId) + 1] = "" + data.dosage_value;
        data.dosage = parseInt(highestDosageId) + 1;
        dosageDropdownValue = data.dosage_value;
      }
      else {
        dosageDropdownValue = data.dosage_value;
        // if (this.state.isPaediatricSpeciality == 1) {
          data.dosage = dosageKey;
        // }
      }
      var dosageKeyMorning = Object.keys(drugDosage).find(key => drugDosage[key] == data.dosage_value_morning);
      data.dosage_morning = dosageKeyMorning;
      var dosageKeyAfternoon = Object.keys(drugDosage).find(key => drugDosage[key] == data.dosage_value_afternoon);
      data.dosage_afternoon = dosageKeyAfternoon;
      var dosageKeyEvening = Object.keys(drugDosage).find(key => drugDosage[key] == data.dosage_value_evening);
      data.dosage_evening = dosageKeyEvening;
      var dosageKeyNight = Object.keys(drugDosage).find(key => drugDosage[key] == data.dosage_value_night);
      data.dosage_night = dosageKeyNight;

      if ((data.drug_fixed_qty && data.drug_fixed_qty != null) || (data.fixed_quantity && data.fixed_quantity != null)) {
        quantity = data.drug_fixed_qty ? data.drug_fixed_qty : data.fixed_quantity;
        quantity_text = 'Unit';
      }
      console.log('>>>>>>>>>>>>>>>> mohit editData', this.state.editData);
      this.setState({
        prescription_drug_id: data.prescription_drug_id,
        drug_id: data.drug_id,
        drug_name: data.drug_name,
        drug_dosage: data.dosage,
        drug_dosage_morning: data.dosage_morning,
        drug_dosage_afternoon: data.dosage_afternoon,
        drug_dosage_evening: data.dosage_evening,
        drug_dosage_night: data.dosage_night,
        drug_dosage_value: data.dosage_value,
        dosageDropDownMorning: data.dosage_value_morning,
        dosageDropDownAfternoon: data.dosage_value_afternoon,
        dosageDropDownEvening: data.dosage_value_evening,
        dosageDropDownNight: data.dosage_value_night,
        dosageDropDown: dosageDropdownValue,
        drug_number_of_days: (data.number_of_days != 0) ? data.number_of_days : null,
        drug_frequency: data.drug_frequency,
        drug_strength: (data.strength && data.strength !== null) ? data.strength : '',
        drug_dosage_form_text: data.dosage_form_text,
        drug_form: (data.drug_form != 0) ? data.drug_form : null,
        drug_route: (data.route != 0) ? data.route : null,
        drug_daily_frequency: (data.daily_frequency != 0 && data.daily_frequency != undefined) ? data.daily_frequency : '',
        drug_schedules: (data.active == 'Y' && data.schedules !== null) ? data.schedules : (data.schedules && data.schedules !== null) ? data.schedules : [],
        drug_schedule_name: data.schedule_name,
        drug_instruction: (data.instruction != 0) ? data.instruction : null,
        drug_instruction_morning: data.instruction_morning,
        drug_instruction_afternoon: data.instruction_afternoon,
        drug_instruction_evening: data.instruction_evening,
        drug_instruction_night: data.instruction_night,
        drug_medicine_remarks: data.medicine_remarks,
        drug_urgency: data.urgency,
        drug_event: data.event,
        drug_lmp_event_duration: data.lmp_event_duration,
        drug_lmp_event_duration_type: data.lmp_event_duration_type,
        drug_dosage_form: (data.dosage_form != 0) ? data.dosage_form : null,
        drug_fixed_qty: data.fixed_quantity,
        drug_quantity: (quantity && quantity !== null) ? quantity : '',
        //drug_dosage_form_text_quantity: data.dosage_form_text,
        drug_dosage_form_text_quantity: quantity_text != '' ? quantity_text : data.dosage_form_text,
        drug_start_date: (data.start_date != "0000-00-00") ? data.start_date : null,
        drug_end_date: (data.end_date != "0000-00-00") ? data.end_date : null,
        drug_generic: data.drug_generic,
        drug_days_in_week: data.days_in_week ?? [],
        drug_days_in_week_text: data.days_in_week_text,
        drug_daily_frequency_repeats: day_interval != 1 ? 1 : dosage_per_day,
        selectDrugValue: data.drug_name,
        TaperBtnEnabled: false,
        localDoseRule: data.dosage_rule ? parseInt(data.dosage_rule) : this.state.localDoseRule,
        dosage_rule: data.dosage_rule ? parseInt(data.dosage_rule) : this.state.localDoseRule,
        EditSection: 1,
        editIndex: index,
        dose_for_schedule_applicable: data.dose_for_schedule,
        Taperdose: 0,
        AddMedicineButton: false,
        isFavCheckedVal: data.fav ?? false,
        drugEditApiStatus: false,
        addContinueTillReview: (this.state.editData.addContinueTillReview === 'Y')? 'Y' : 'N',
        drugDosage,
        DRUGSConstants,
        active: this.state.editData != null ? this.state.editData.active : (this.state.drug_id == null || this.state.drug_id == '') ? 'N' : 'Y',
        medicineFormValidate: (data.active === 'N') ? false : true,
        drug_duration_time: day_interval === 1 || (data.active === 'N' && day_interval === undefined) ? 'Days' : day_interval === 7 ? 'Weeks' : 'Months'
      }, () => {
        axios.post(DRUG, qs.stringify({
          id: data.drug_id
        })).then(success => {
          const response = success.data.data.result;

          if (response.status != 400) {

            this.setState({ responseDrugDosage: response.drug_dosage[0] })
            if (this.props.formType != 'template' && this.state.isPaediatricSpeciality == 1 && response?.drug_dosage?.[0]?.adult_dose > 0) {
              this.setState({
                adult_dose_exists: true
              });
            }
            else {
              this.setState({
                adult_dose_exists: false
              });
            }

            if (this.props.formType != 'template') {
              if (response.drug_dosage.length > 0) {
                this.setState({
                  dose_for_schedule: response.drug_dosage[0].dose_for_schedule || 'N',
                  correction_dose: response.drug_dosage[0].correction_dose || 'N',
                })
              }
            }

          }
        })
        if (quantity_text != 'Unit') {
          this.updateQuantity(); this.handleTextareaAutoHeight('drug_medicine_remarks'); this.checkDrugSaveBtn();
        }
      })

      if (this.props.formType == "copy-to-visit") {
        setTimeout(() => {
          if (this.state.currentMeds.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
            this.showPolyModal()
          }
        }, 2000)
      }
      AntSelectLabels();
      jQueryLabels();
    }
  }

  Formclear = () => {
    this.setState({
      editIndex: -1,
      editData: null,
    }, () => {
      this.setState({
        searchDrugs: [],
        // editIndex: -1,
        addTaperDose: false,
        AddMedicineButton: true,
        AddDoseForScheduleButton: true,
        AddCorrectionDoseButton: true,
        isFavCheckedVal: false,
        // editData: null,
        TaperBtnEnabled: true,
        isTaperRow: false,
        drug_daily_frequency_repeats: null,
        selectDrugValue: '',
        prescription_drug_id: -1,
        drug_id: null,
        drug_name: null,
        drug_dosage_form: 0,
        drug_dosage_form_text: null,
        drug_dosage_form_text_quantity: '-',
        drug_form: '',
        adult_dose_exists: false,
        drug_urgency: '',
        drug_lmp_event_duration: '',
        drug_lmp_event_duration_type: '',
        correction_dose_value: [],
        correction_dose_data_value: [],
        correction_dose_validate: [],
        drug_form_defaulted: true,
        correction_dose_data: [],
        correction_dose_applicable: 'N',
        drug_fixed_qty: null,
        drug_medicine_remarks: '',
        drug_daily_frequency: '',
        drug_days_in_week: [],
        drug_days_in_week_text: null,
        drug_dosage: '',
        drug_dosage_value: null,
        drug_frequency: 1,
        drug_generic: null,
        drug_instruction: '',
        drug_number_of_days: '',
        drug_route: null,
        drug_schedule_name: null,
        drug_schedules: [],
        drug_start_date: null,
        drug_end_date: null,
        correction_dose_error_messsage: [],
        drug_strength: '',
        drug_taper_row: null,
        drug_quantity: '',
        start_date: null,
        end_date: null,
        taperModelContinue: 0,
        dosageDropDown: '',
        dosageDropDownMorning: '',
        dosageDropDownAfternoon: '',
        dosageDropDownEvening: '',
        dosageDropDownNight: '',
        dosageDropDownTaper: '',
        dose_for_schedule: 'N',
        correction_dose: 'N',
        drug_dosage_value_morning: null,
        drug_dosage_value_afternoon: null,
        drug_dosage_value_evening: null,
        drug_dosage_value_night: null,
        drug_instruction_morning: '',
        drug_instruction_afternoon: '',
        drug_instruction_evening: '',
        drug_instruction_night: '',
        dose_for_schedule_applicable: 'N',
        addContinueTillReview: 'N',
        medicineFormValidate: true,
        availableDrugRoutes: [],
        isBorderColor: false,
        drug_duration_time: 'Days'
      }, () => {
        this.props.clearEdit(); AntSelectLabels(); AntClearLabels(); jQueryLabels(); this.MasterDrugApi(''); this.handleTextareaAutoHeight('drug_medicine_remarks', true); this.checkDrugSaveBtn(); this.setState({
          AddMedicineButton: true,
        });
      });
    })


  }

  saveMedicineData = async () => {
    if (this.props?.isPracticing === false) {
      this.props.practiceModal('isPracticingModal');
      return;
    }
    let drug_fixed_qty;
    if (this.props.editData != null) {
      let edit_data = this.props.editData;
      drug_fixed_qty = edit_data.drug_fixed_qty || edit_data.fixed_quantity;
    } else {
      drug_fixed_qty = this.state.drug_fixed_qty;
    }
    var day_interval;
    var times_correction;
    var dosage_per_day;
    var selectedFrequency = !this.state.addTaperDose && this.state.isModalTaperDoseVisible ? this.state.drug_daily_frequency_taper : this.state.drug_daily_frequency;
    if (selectedFrequency && selectedFrequency != null && selectedFrequency != '') {
      let freqVal = !this.state.addTaperDose && this.state.isModalTaperDoseVisible ? this.state.drug_daily_frequency_taper : this.state.drug_daily_frequency;
      let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === freqVal)
      frequencyObjKey = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)]
      day_interval = frequencyObjKey.interval;
      day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : 1);
      times_correction = frequencyObjKey.total_times;
      dosage_per_day = frequencyObjKey.total_repeats;
    }

    let start_date = this.state.start_date;
    let s_date = start_date ? start_date : this.state.end_date;
    if (this.state.editData != null)
      start_date = this.state.drug_start_date;
    else if (times_correction != null && (start_date == null || typeof start_date == 'undefined') && (!this.state.addTaperDose && this.state.isModalTaperDoseVisible))
      start_date = moment().add(1, 'days').format('YYYY-MM-DD');
    else if (times_correction != null && (start_date == null || typeof start_date == 'undefined'))
      start_date = moment().format('YYYY-MM-DD');
    /*else if (times_correction == 0)
      start_date = moment(s_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');*/
    else
      start_date = moment(s_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');

    var correction_dose_data = [];
    var correction_dose_details = []
    if (this.state.correction_dose_validate?.length > 0) {
      for (var dose of this.state.correction_dose_validate) {
        correction_dose_data.push({
          range_min: dose?.[0],
          range_max: dose?.[1],
          range_unit: dose?.[2],
        })
        correction_dose_details.push([dose?.[0], dose?.[1], dose?.[2]])
      }
    }
    else if (this.state.correction_dose_data?.length > 0) {
      for (var dose of this.state.correction_dose_data) {
        correction_dose_details.push([dose?.range_min, dose?.range_max, dose?.range_unit])
      }
    }
    if (correction_dose_details?.length == 0 && this.state.correction_dose_data?.length > 0) {
      correction_dose_details = this.state.correction_dose_data
    }
    
    const medicine_data = {
      id: this.state.editData != null ? this.state.editData.id : null,
      i: this.props.editIndex,
      drug_generic: this.state.editData != null ? this.state.editData.drug_generic : this.state.drug_generic,
      active: this.state.editData != null ? this.state.editData.active : 'Y',
      drug_id: this.state.drug_id,
      drug_form: this.state.drug_form,
      strength: this.state.drug_strength,
      dosage: this.state.drug_dosage,
      dosage_value: this.state.drugDosage[this.state.drug_dosage],
      dosage_value_morning: this.state.dosageDropDownMorning,
      dosage_value_afternoon: this.state.dosageDropDownAfternoon,
      dosage_value_evening: this.state.dosageDropDownEvening,
      dosage_value_night: this.state.dosageDropDownNight,
      route: this.state.drug_route,
      drug_frequency: this.state.drug_frequency,
      daily_frequency: this.state.drug_daily_frequency,
      number_of_days: this.state.drug_frequency !== 3 ? this.state.drug_number_of_days : null,
      days_in_week_text: this.state.drug_days_in_week_text,
      days_in_week: this.state.drug_days_in_week,
      quantity: this.state.drug_quantity,
      instruction: this.state.drug_instruction,
      instruction_morning: this.state.drug_instruction_morning,
      instruction_afternoon: this.state.drug_instruction_afternoon,
      instruction_evening: this.state.drug_instruction_evening,
      instruction_night: this.state.drug_instruction_night,
      dose_for_schedule_applicable: this.state.dose_for_schedule_applicable || 'N',
      dose_for_schedule: this.state.dose_for_schedule_applicable || 'N',
      correction_dose_applicable: this.state.correction_dose_applicable || 'N',
      correction_dose: this.state.correction_dose_applicable || 'N',
      medicine_remarks: this.state.drug_medicine_remarks,
      drug_name: this.state.drug_name,
      dosage_form: this.state.drug_dosage_form,
      dosage_form_text: this.state.drug_dosage_form_text,
      drug_fixed_qty: drug_fixed_qty,
      taper_row: this.state.isTaperRow,
      correction_dose_details: this.state.correction_dose_applicable == 'Y' ? correction_dose_details : [],
      correction_dose_data: this.state.correction_dose_applicable == 'Y' ? correction_dose_data : [],
      fav: this.state.isFavCheckedVal,
      prescription_drug_id: this.state.prescription_drug_id,
      urgency: this.state.drug_frequency !== 3 ? this.state.drug_urgency : null,
      event: this.state.drug_event || null,
      lmp_event_duration: this.state.drug_lmp_event_duration || null,
      lmp_event_duration_type: this.state.drug_lmp_event_duration_type || null,
      schedules: this.state.drug_schedules,
      schedule_name: this.state.drug_schedule_name,
      addContinueTillReview: (this.state.addContinueTillReview === 'Y')? 'Y' : 'N'
    }

    if (this.props.formType != 'template' && this.state.adult_dose_exists == true && this.state.isPaediatricSpeciality == 1) {
      medicine_data.dosage_rule = this.state.localDoseRule
    }

    medicine_data.start_date = start_date;
    medicine_data.end_date = this.state.drug_end_date;

    if (!this.state.addTaperDose && this.state.isModalTaperDoseVisible) {
      let number_of_days = this.state.drug_number_of_days_taper;
      number_of_days = (number_of_days * day_interval * times_correction) - 1;
      medicine_data.start_date = moment(start_date, 'YYYY-MM-DD').format('YYYY-MM-DD');;
      medicine_data.end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days, 'days').format('YYYY-MM-DD');

      medicine_data.dosage = this.state.drug_dosage_taper;
      medicine_data.dosage_value = this.state.drugDosage[this.state.drug_dosage_taper];
      medicine_data.dosage_form_text = this.state.drug_dosage_form_text_taper;
      medicine_data.strength = this.state.drug_strength_taper;
      medicine_data.route = this.state.drug_route_taper;
      medicine_data.number_of_days = this.state.drug_frequency_taper !== 3 ? this.state.drug_number_of_days_taper : null;
      medicine_data.drug_frequency = this.state.drug_frequency_taper;
      medicine_data.days_in_week = this.state.drug_days_in_week_taper;
      medicine_data.days_in_week_text = this.state.drug_days_in_week_text_taper;
      medicine_data.daily_frequency = this.state.drug_daily_frequency_taper;
      medicine_data.schedule_name = this.state.drug_schedule_name_taper;
      medicine_data.schedules = this.state.drug_schedules_taper;
      medicine_data.dose_for_schedule_applicable = 'N';
    }

    if (medicine_data.drug_frequency == 3) {
      medicine_data.start_date = null;
      medicine_data.end_date = null;
      medicine_data.number_of_days = null;
      medicine_data.schedules = null;
      medicine_data.days_in_week = null;
      medicine_data.urgency = null;
    }
    // else if (times_correction == 0) {
    // medicine_data.start_date = null;
    // medicine_data.end_date = null;
    // }

    if (medicine_data.end_date != null)
      this.setState({ start_date: medicine_data.end_date });
    this.setState({ isTaperRow: this.state.addTaperDose });

    if (!this.state.medicineFormValidate && this.state.editData == null) {
      medicine_data.start_date = (medicine_data.start_date && moment(start_date, 'YYYY-MM-DD', true).isValid()) ? medicine_data.start_date : null;

      let dosageValue = null;
      this.state.dosageConstantOriginal.filter((element) => {
        if (element.id == medicine_data.dosage) { dosageValue = element.description }
      })

      let route = null;
      this.state.DRUGSConstants.route.filter((element) => {
        if (element.id == medicine_data.route) { route = element.description }
      })

      let frequency = null;
      if (medicine_data.drug_frequency == 1) {
        this.state.DRUGSConstants.dailyFrequency.filter((element) => {
          if (element.id == medicine_data.daily_frequency) { frequency = element.description }
        })
      }
      if (medicine_data.drug_frequency == 2) {
        let weekDaysText = [];
        if (medicine_data.days_in_week) {
          for (let index = 0; index < medicine_data.days_in_week.length; index++) {
            this.state.DRUGSConstants.daysInWeek.filter((element) => {
              if (element.id == medicine_data.days_in_week[index]) {
                weekDaysText.push(element.description);
              }
            });
          }

          frequency = weekDaysText.join('-');
        } else {
          frequency = 'Specific Days';
        }
      }
      if (medicine_data.drug_frequency == 3) {
        frequency = "PRN";
      }

      let instruction = null;
      this.state.DRUGSConstants.drugInstructions.filter((element) => {
        if (element.id == medicine_data.instruction) { instruction = element.description }
      })

      let priority = null;
      this.state.DRUGSConstants.drugUrgency.filter((element) => {
        if (element.id == medicine_data.urgency) { priority = element.description }
      })

      let emailParams = {
        drug_name: medicine_data.drug_name,
        strength: medicine_data.strength,
        route: route,
        priority: priority,
        frequency: frequency,
        schedule: medicine_data.schedule_name,
        dosage: dosageValue,
        instruction: instruction,
        number_of_days: medicine_data.number_of_days,
        due_from: medicine_data.start_date
      }

      await Axios.post(ADD_DRUGS, {
        hospital_id: LS_SERVICE.get('slot_hospital_id'),
        hospital_master: LS_SERVICE.get('slot_hospital_master'),
        name: this.state.drug_name,
        form_id: this.state.drug_form,
        route: this.state.drug_route,
        dosage: this.state.drug_dosage,
        quantity: this.state.drug_quantity,
        frequency_id: this.state.drug_frequency,
        emailParams: emailParams
      })
        .then(success => {
          medicine_data.drug_id = success.data.data.id;
          console.log(success.data.message);
        }).catch(err => {
          console.log(err);
        })
    }

    this.props.updateData(medicine_data, (this.state.addTaperDose || this.state.taperModelContinue == 1));

    AntSelectLabels();

    if (this.state.addTaperDose || this.state.taperModelContinue == 1) {
      this.setState({
        isModalTaperDoseVisible: false,
        drug_dosage_taper: '',
        dosageDropDownTaper: '',
        drug_dosage_form_text_taper: null,
        drug_route_taper: '',
        drug_frequency_taper: 1,
        drug_days_in_week_taper: [],
        drug_days_in_week_text_taper: null,
        drug_strength_taper: null,
        drug_number_of_days_taper: null,
        drug_daily_frequency_taper: '',
        drug_schedule_name_taper: null,
        drug_schedules_taper: [],
        dosageDropDown: '',
      }, () => { this.showTaperModal() })

    }
    else {
      this.handleTaperCancel();
      this.Formclear();
    }
  }

  handleTextareaAutoHeight = (textarea, clear = false) => {
    let Elem = document.querySelectorAll('.' + textarea);
    if (!clear) {
      Elem.forEach((el) => { el.style.height = el.scrollHeight + "px"; });
    } else {
      Elem.forEach((el) => { el.style.height = "40px"; });
    }
  }

  handleDateChange = (date = null) => {
    const { drug_frequency, start_date } = this.state;

    if (date === null) {
      date = start_date
    }

    if (date !== null) {

      let end_date = moment(date).add(+drug_frequency, 'days');

      this.setState({
        start_date: date,
        end_date: end_date._d
      })
    }
  }

  checkRangeOverlap(ranges) {
    let correction_dose_error_messsage = [];
    let n;
    if (ranges?.length > 0) {
      //ranges = ranges.filter(function () { return true });
      n = ranges.length;
      for (var i = 0; i < n; i++) {
        if (ranges[i] != undefined && ranges[i][0] != undefined && ranges[i][1] != undefined && ranges[i][2] != undefined) {
          if (ranges[i][0] >= 0 && ranges[i][1] >= 0 && ranges[i][2] >= 0) {
            if (parseInt(ranges[i][0]) < parseInt(ranges[i][1])) {
              for (var u = 0; u < n; u++) {
                if (ranges[u] != undefined && ranges[u][0] != undefined && ranges[u][1] != undefined) {
                  if (i == u) // skip if comparing the same range
                    continue
                  var rangeA = ranges[i]
                  var rangeB = ranges[u]
                  if (parseInt(rangeA[0]) <= parseInt(rangeB[1]) && parseInt(rangeA[1]) >= parseInt(rangeB[0])) {
                    correction_dose_error_messsage[u] = 'Overlapping values, please check';
                    this.setState({ correction_dose_error_messsage });
                    return true
                  }
                }
              }
            }
            else {
              correction_dose_error_messsage[i] = 'To value should always be greater than From Value';
              this.setState({ correction_dose_error_messsage });
              /* this.setState({correction_dose_error_messsage: 'To value should always be greater than From Value'}); */
              return true
            }
          }
          else if (ranges[i][0] < 0 && ranges[i][1] < 0 && ranges[i][2] < 0) {
            this.setState({ correction_dose_error_messsage });
            //this.setState({correction_dose_error_messsage: ''});
            return false;
          }
          else if (!ranges[i][0] && !ranges[i][1] && !ranges[i][2]) {
            this.setState({ correction_dose_error_messsage });
            //this.setState({correction_dose_error_messsage: ''});
            return false;
          }
          else {
            this.setState({ correction_dose_error_messsage });
            //this.setState({correction_dose_error_messsage: ''});
            return true
          }
        }
        else if (ranges[i] != undefined) {
          if (ranges[i][0] == undefined && ranges[i][1] == undefined && ranges[i][2] == undefined) {
            // Do nothing
          }
          else {
            this.setState({ correction_dose_error_messsage });
            //this.setState({correction_dose_error_messsage: ''});
            return true
          }
        }
      }
    }
    else {
      this.setState({ correction_dose_error_messsage });
      //this.setState({correction_dose_error_messsage: ''});
      return true;
    }

    if (ranges?.length > 0) {
      let all_undefined_rows_count = 0;
      let total_rows = 0;
      for (var i = 0; i < n; i++) {
        if (ranges[i] != undefined) {
          total_rows++;
          if (ranges[i][0] == undefined && ranges[i][1] == undefined && ranges[i][2] == undefined) {
            all_undefined_rows_count++;
          }
        }
      }
      if (all_undefined_rows_count == total_rows) {
        this.setState({ correction_dose_error_messsage });
        return true;
      }
    }
    this.setState({ correction_dose_error_messsage });
    //this.setState({correction_dose_error_messsage: ''});
    return false
  }

  DrugClear = () => {
    ClearSelectSelectionItem()
  }

  checkDrugSaveBtn = () => {
    let drugId = this.state.drug_id;
    let drugForm = this.state.drug_form;

    let strength = this.state.drug_strength;
    let dosage = this.state.drug_dosage;
    let route = this.state.drug_route;
    let drugFrequency = this.state.drug_frequency;
    let drugDaysInWeek = this.state.drug_days_in_week;
    let dailyFrequency = this.state.drug_daily_frequency;
    let schedule = this.state.drug_schedules;
    let urgency = this.state.drug_urgency;
    let event = this.state.drug_event;
    let lmp_event_duration = this.state.drug_lmp_event_duration;
    let lmp_event_duration_type = this.state.drug_lmp_event_duration_type;
    let numberOfDays = this.state.drug_number_of_days;
    let startDate = this.state.drug_start_date;
    let endDate = this.state.drug_end_date;

    if (!this.state.addTaperDose && this.state.isModalTaperDoseVisible) {
      dosage = this.state.drug_dosage_taper;
      route = this.state.drug_route_taper;
      strength = this.state.drug_strength_taper;
      numberOfDays = this.state.drug_number_of_days_taper;
      drugFrequency = this.state.drug_frequency_taper;
      drugDaysInWeek = this.state.drug_days_in_week_taper;
      dailyFrequency = this.state.drug_daily_frequency_taper;
      schedule = this.state.drug_schedules_taper;
    }

    let disableBtn = false;

    if (this.checkEmptyValue(drugId)) disableBtn = true;
    if (this.checkEmptyValue(drugForm)) disableBtn = true;
    if (this.checkEmptyValue(strength)) disableBtn = true;
    if (this.checkEmptyValue(route)) disableBtn = true;
    if (this.checkEmptyValue(drugFrequency)) disableBtn = true;
    if (this.checkEmptyValue(dosage)) disableBtn = true;
    if (this.state.dose_for_schedule_applicable == 'Y') {
      if (this.state.drug_schedules.includes(1)) {
        if (this.checkEmptyValue(this.state.dosageDropDownMorning)) disableBtn = true;
        if (this.checkEmptyValue(this.state.drug_instruction_morning)) disableBtn = true;
      }
      if (this.state.drug_schedules.includes(2)) {
        if (this.checkEmptyValue(this.state.dosageDropDownAfternoon)) disableBtn = true;
        if (this.checkEmptyValue(this.state.drug_instruction_afternoon)) disableBtn = true;
      }
      if (this.state.drug_schedules.includes(3)) {
        if (this.checkEmptyValue(this.state.dosageDropDownEvening)) disableBtn = true;
        if (this.checkEmptyValue(this.state.drug_instruction_evening)) disableBtn = true;
      }
      if (this.state.drug_schedules.includes(4)) {
        if (this.checkEmptyValue(this.state.dosageDropDownNight)) disableBtn = true;
        if (this.checkEmptyValue(this.state.drug_instruction_night)) disableBtn = true;
      }
    }
    if (this.state.eventBased == true) {
      if (this.checkEmptyValue(event)) disableBtn = true;
      if (event == 1 || event == 2) {
        if (this.checkEmptyValue(lmp_event_duration)) disableBtn = true;
        if (this.checkEmptyValue(lmp_event_duration_type)) disableBtn = true;
      }
    }
    if (drugFrequency != 3) {
      let day_interval = null;
      let times_correction = null;
      let dosage_per_day = null;
      if (this.checkEmptyValue(dailyFrequency)) disableBtn = true;
      else {
        let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === dailyFrequency)
        frequencyObjKey = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)];
        day_interval = frequencyObjKey.interval;
        day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : 1);
        dosage_per_day = frequencyObjKey.total_repeats
        times_correction = frequencyObjKey.total_times;
        if(schedule && schedule.length > 0){
          if (day_interval != 1 && dosage_per_day > 1 && schedule && schedule.length != 1)
            disableBtn = true;
          else if (day_interval != 1 && dosage_per_day == 1 && schedule && dosage_per_day != schedule.length)
            disableBtn = true;
          else if (day_interval == 1 && dosage_per_day <= 4 && schedule && dosage_per_day != schedule.length)
            disableBtn = true;
        }
      }


      if (drugFrequency == 2 && this.checkEmptyMultiSelectValue(drugDaysInWeek)) disableBtn = true;
      // if (this.checkEmptyMultiSelectValue(schedule)) disableBtn = true;
      if (this.checkEmptyValue(urgency)) disableBtn = true;
      // if (this.checkEmptyValue(numberOfDays)) disableBtn = true;

      if (this.props.formType != 'template' && times_correction != null && times_correction != 0) {
        if (this.checkEmptyValue(startDate)) disableBtn = true;
        // if (this.checkEmptyValue(endDate)) disableBtn = true;
      }
    }


    if (!this.state.addTaperDose && this.state.isModalTaperDoseVisible)
      this.setState({ addTaperDoseBtn: disableBtn })
    else
      this.setState({ AddMedicineButton: disableBtn })
    let disableScheduleForDoseBtn = false;
    if (this.state.drug_schedules.includes(1)) {
      if (this.checkEmptyValue(this.state.dosageDropDownMorning)) disableScheduleForDoseBtn = true;
      if (this.checkEmptyValue(this.state.drug_instruction_morning)) disableScheduleForDoseBtn = true;
    }
    if (this.state.drug_schedules.includes(2)) {
      if (this.checkEmptyValue(this.state.dosageDropDownAfternoon)) disableScheduleForDoseBtn = true;
      if (this.checkEmptyValue(this.state.drug_instruction_afternoon)) disableScheduleForDoseBtn = true;
    }
    if (this.state.drug_schedules.includes(3)) {
      if (this.checkEmptyValue(this.state.dosageDropDownEvening)) disableScheduleForDoseBtn = true;
      if (this.checkEmptyValue(this.state.drug_instruction_evening)) disableScheduleForDoseBtn = true;
    }
    if (this.state.drug_schedules.includes(4)) {
      if (this.checkEmptyValue(this.state.dosageDropDownNight)) disableScheduleForDoseBtn = true;
      if (this.checkEmptyValue(this.state.drug_instruction_night)) disableScheduleForDoseBtn = true;
    }
    this.setState({ AddDoseForScheduleButton: disableScheduleForDoseBtn })

    var overlap_status = this.checkRangeOverlap(this.state.correction_dose_validate) || false

    this.setState({ AddCorrectionDoseButton: overlap_status })

    if ((drugFrequency == 1 || drugFrequency == 2) && !this.state.medicineFormValidate && this.state.drug_number_of_days != null) {
      this.setState({ AddMedicineButton: this.state.medicineFormValidate })
    }

    if ((drugFrequency == 1 || drugFrequency == 2) && !this.state.medicineFormValidate && (this.state.drug_number_of_days == null || this.state.drug_number_of_days == "")) {
      this.setState({ AddMedicineButton: true })
    }

    if (drugFrequency == 3 && !this.state.medicineFormValidate) {
      this.setState({ AddMedicineButton: this.state.medicineFormValidate })
    }

    if(!this.state.medicineFormValidate){
      this.setState({ AddMedicineButton: false })
    }

    /*if(drugFrequency==1 && !this.checkEmptyValue(dailyFrequency)){
      let frequencyObjKey = Object.keys(this.state.DRUGSConstants.dailyFrequency).find(key => this.state.DRUGSConstants.dailyFrequency[key].id === drug_daily_frequency)
      frequencyObjKey = this.state.DRUGSConstants.dailyFrequency[parseInt(frequencyObjKey)];
      let day_interval = frequencyObjKey.day_interval;
      if(day_interval!=schedule.length)
        disableBtn=true;
    }
    else if(drugFrequency==2 && this.checkEmptyMultiSelectValue(drugDaysInWeek)) disableBtn=true;*/

  }

  checkEmptyValue(val) {
    return typeof val == 'undefined' || val == null || val == '' ? true : false;
  }

  checkEmptyMultiSelectValue(val) {
    return typeof val == 'undefined' || val == null || val.length == 0 ? true : false;
  }

  handleDoseCalculator = (e, val) => {
    this.setState({ showDoseCalculator: val });
  }

  getDoseName(doseRule) {
    if (doseRule == 1)
      return "Fried's Rule"
    if (doseRule == 2)
      return "Young's Rule"
    if (doseRule == 3)
      return "Clarke's Rule"
    if (doseRule == 4)
      return "Dose based on BSA"
  }

  handleDoseChange = (e, val) => {
    this.setState({ localDoseRule: val }, () => {
      var dosageValue;
      dosageValue = this.doseCalculator(this.state.responseDrugDosage);
      var dosage = dosageValue == 0 ? null : Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == dosageValue);
      if (typeof dosage == 'undefined') {
        // dosage = this.getClosestDosage(dosageValue);
        dosage = dosageValue;
      } else
        dosage = dosageValue;
      dosage = Object.keys(this.state.drugDosage).find(key => this.state.drugDosage[key] == dosage);
      if (this.props.formType != 'template' && typeof dosage == 'undefined' && this.state.isPaediatricSpeciality == 1 && dosageValue != null && dosageValue != '') {
        var DRUGSConstants = this.state.DRUGSConstants;
        var drugDosage = this.state.drugDosage;
        var highestDosageId = this.state.highestDosageId;
        this.state.DRUGSConstants.drugDosage.map((obj, i) => {
          highestDosageId = highestDosageId == null || highestDosageId < obj.id ? obj.id : highestDosageId;
        })
        drugDosage[parseInt(highestDosageId) + 1] = "" + dosageValue;
        DRUGSConstants.drugDosage.push({ id: parseInt(highestDosageId) + 1, description: dosageValue });
        this.setState({ drugDosage, DRUGSConstants });
        dosage = parseInt(highestDosageId) + 1;
      } else if (this.props.formType != 'template') {
        dosage = typeof dosage == 'undefined' ? null : parseInt(dosage);
      }
      this.setState({
        drug_dosage: dosage,
        drug_dosage_value: dosage != null ? this.state.drugDosage[dosage] : '',
        dosageDropDown: this.state.drugDosage[dosage]
      }, () => { this.updateQuantity(); this.checkDrugSaveBtn(); })
    });

  }

  onChangeQuantity(e) {
    this.setState({ drug_quantity: e.target.value });
  }

  handleAddDrug() {
    const { currentMeds } = this.state;

    this.setState({
      AddMedicineButton: false,
      showAddDrugButton: true,
      medicineFormValidate: false,
      drug_id: null,
      drug_name: this.state.selectDrugValue,
      drug_instruction: 4,
      drug_urgency: 3,
      drug_number_of_days: ''
    });

    if (this.props.formType != 'template') {
      if (currentMeds.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
        this.showPolyModal()
      }
    }

    AntSelectLabels();
  }

  render() {
    let doseRule = this.state.doseRule;
    var doseForScheduleClass = ''
    if (this.state?.drug_schedules?.length == 2) {
      doseForScheduleClass = "col-12 col-md-6"
    }
    else if (this.state?.drug_schedules?.length == 3) {
      doseForScheduleClass = "col-12 col-md-4"
    }
    else if (this.state?.drug_schedules?.length == 4) {
      doseForScheduleClass = "col-12 col-md-3"
    }

    var schedule_short_array = [];
    if (this.state.drug_schedules.includes(1)) {
      if (this.state.dosageDropDownMorning && this.state.dosageDropDownMorning != null && this.state.dosageDropDownMorning != "") {
        schedule_short_array.push(this.state.dosageDropDownMorning + "M")
      }
    }
    if (this.state.drug_schedules.includes(2)) {
      if (this.state.dosageDropDownAfternoon && this.state.dosageDropDownAfternoon != null && this.state.dosageDropDownAfternoon != "") {
        schedule_short_array.push(this.state.dosageDropDownAfternoon + "A")
      }
    }
    if (this.state.drug_schedules.includes(3)) {
      if (this.state.dosageDropDownEvening && this.state.dosageDropDownEvening != null && this.state.dosageDropDownEvening != "") {
        schedule_short_array.push(this.state.dosageDropDownEvening + "E")
      }
    }
    if (this.state.drug_schedules.includes(4)) {
      if (this.state.dosageDropDownNight && this.state.dosageDropDownNight != null && this.state.dosageDropDownNight != "") {
        schedule_short_array.push(this.state.dosageDropDownNight + "N")
      }
    }
    var schedule_short = schedule_short_array.length > 0 ? schedule_short_array.join(', ') : "";
    return (
      <Fragment>

        <div className="col-12 emrfrm" id="create-medicine-details">
          <Form ref={this.FormRef}>
            <div className="row mb-2">
              <div className="col-6 py-2">
                <h2 className="text-heading">Medicines</h2>
              </div>
              <div className="col-6 text-right py-2">
                {this.state.bmi != '' ?//  Height 90cm | Weight 12kg | BMI 23.4 | BSA 1.75
                  <span><b>Height {this.state.heightbmi} cm | Weight {this.state.weightbmi} kg | BMI {this.state.bmi} | BSA {this.state.bsa}</b></span>
                  : null}
              </div>
              {
                this.state.eventBased == true && this.props.formType == 'template'
                  ?
                  <>
                    <div className="col-12">
                      <h2 className="text-heading">Events</h2>
                    </div>
                    <div className="col-2 antInputItem">
                      <div className={this.state?.drug_event ? "form-group hasdata" : "form-group"}>
                        <Select showSearch id="event" name="drug_event" style={{ width: 100 + '%' }}
                          placeholder="Event"
                          onChange={this.onChangeDrugEvent}
                          className="drug-input Event"
                          value={this.state.drug_event}
                        >
                          {this.optionEventLoad()}
                        </Select>
                        <label htmlFor="event">Event{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                      </div>
                    </div>
                    {
                      this.state.eventBased == true && (this.state.drug_event == 1 || this.state.drug_event == 2)
                        ?
                        <div className="col-1 antInputItem">
                          <div className={this.state?.drug_lmp_event_duration ? "form-group hasdata" : "form-group"}>
                            <input
                              type="text"
                              className="form-control onlynumbers"
                              maxLength="2"
                              data-name="lmp_event_duration"
                              name="drug_lmp_event_duration"
                              id="lmp_event_duration"
                              onChange={(e) => { this.onChangeLMPEventDuration(e) }}
                              value={this.state.drug_lmp_event_duration}
                              placeholder="Due From"
                            />
                            <label htmlFor="lmp_event_duration">Due From{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                          </div>
                        </div>
                        : null}
                    {
                      this.state.eventBased == true && (this.state.drug_event == 1 || this.state.drug_event == 2)
                        ?
                        <div className="col-1 antInputItem">
                          <div className={this.state?.drug_lmp_event_duration_type ? "form-group hasdata" : "form-group"}>
                            <Select showSearch id="lmp_event_duration_type" name="drug_lmp_event_duration_type" style={{ width: 100 + '%' }}
                              placeholder="Duration"
                              onChange={this.onChangeLMPEventDurationType}
                              className="drug-input"
                              value={this.state.drug_lmp_event_duration_type}
                            >
                              {this.optionLMPEventDurationTypeLoad()}
                            </Select>
                            <label htmlFor="lmp_event_duration_type">Duration{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                          </div>
                        </div>
                        : null}
                    <div className="col-8"></div>
                  </>
                  :
                  null
              }

              {
                this.props.formType !== 'template' && this.state.diabaticPatient == true
                  ?
                  <>
                    <div className="col-3 text-bold padding_top">
                      Target Blood Sugar(mg/dL)
                    </div>
                    <div className="col-1 antInputItem">
                      <div className={this.state?.target_blood_sugar_min ? "form-group hasdata" : "form-group"}>
                        <input
                          type="text"
                          className="form-control drug-input mandatory-field onlynumbers"
                          maxLength="4"
                          name="target_blood_sugar_min"
                          id="target_blood_sugar_min"
                          onChange={(e) => { this.onChangeTargetBloodSugar(e) }}
                          value={this.state?.target_blood_sugar_min ? this.state.target_blood_sugar_min : ""}
                          defaultValue={this.state?.target_blood_sugar_min ? this.state.target_blood_sugar_min : ""}
                        />
                        <label htmlFor="target_blood_sugar_min">From</label>
                        <div>
                          <label style={{ paddingLeft: 0, fontSize: "14px", whiteSpace: "nowrap" }} className="text-secondary">
                            {this.state.target_blood_sugar_error_message}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-1 antInputItem">
                      <div className={this.state?.target_blood_sugar_max ? "form-group hasdata" : "form-group"}>
                        <input
                          type="text"
                          className="form-control drug-input mandatory-field onlynumbers"
                          maxLength="4"
                          name="target_blood_sugar_max"
                          id="target_blood_sugar_max"
                          onChange={(e) => { this.onChangeTargetBloodSugar(e) }}
                          value={this.state?.target_blood_sugar_max ? this.state.target_blood_sugar_max : ""}
                          defaultValue={this.state?.target_blood_sugar_max ? this.state.target_blood_sugar_max : ""}
                        />
                        <label htmlFor="To">To</label>
                      </div>
                    </div>
                    <div className="col-7 text-right button_ant_new">
                      {
                        this.state?.latest_vitals_with_datetime?.V010 && this.state?.latest_vitals_with_datetime?.V010 != "" && this.state?.latest_vitals_with_datetime?.V010 != null
                          ?
                          <>
                            <Tooltip placement="bottom" title={'Recorded on ' + moment(this.state?.latest_vitals_with_datetime?.V010?.vital_datetime).format('D MMM YYYY | h:mm A')} color={'#ffffff'}>
                              <Button>FBS {this.state?.latest_vitals_with_datetime?.V010?.vital_value} mg/dL</Button>
                            </Tooltip>
                            {
                              (this.state?.latest_vitals_with_datetime?.V011 && this.state?.latest_vitals_with_datetime?.V011 != "" && this.state?.latest_vitals_with_datetime?.V011 != null) ||
                                (this.state?.latest_vitals_with_datetime?.V013 && this.state?.latest_vitals_with_datetime?.V013 != "" && this.state?.latest_vitals_with_datetime?.V013 != null) ||
                                (this.state?.latest_vitals_with_datetime?.HbA1c && this.state?.latest_vitals_with_datetime?.HbA1c != "" && this.state?.latest_vitals_with_datetime?.HbA1c != null)
                                ?
                                <>|</>
                                :
                                null
                            }
                          </>
                          :
                          null
                      }
                      {
                        this.state?.latest_vitals_with_datetime?.V011 && this.state?.latest_vitals_with_datetime?.V011 != "" && this.state?.latest_vitals_with_datetime?.V011 != null
                          ?
                          <>
                            <Tooltip placement="bottom" title={'Recorded on ' + moment(this.state?.latest_vitals_with_datetime?.V011?.vital_datetime).format('D MMM YYYY | h:mm A')} color={'#ffffff'}>
                              <Button>PPBS {this.state?.latest_vitals_with_datetime?.V011?.vital_value} mg/dL</Button>
                            </Tooltip>
                            {
                              (this.state?.latest_vitals_with_datetime?.V013 && this.state?.latest_vitals_with_datetime?.V013 != "" && this.state?.latest_vitals_with_datetime?.V013 != null) ||
                                (this.state?.latest_vitals_with_datetime?.HbA1c && this.state?.latest_vitals_with_datetime?.HbA1c != "" && this.state?.latest_vitals_with_datetime?.HbA1c != null)
                                ?
                                <>|</>
                                :
                                null
                            }
                          </>
                          :
                          null
                      }
                      {
                        this.state?.latest_vitals_with_datetime?.V013 && this.state?.latest_vitals_with_datetime?.V013 != "" && this.state?.latest_vitals_with_datetime?.V013 != null
                          ?
                          <>
                            <Tooltip placement="bottom" title={'Recorded on ' + moment(this.state?.latest_vitals_with_datetime?.V013?.vital_datetime).format('D MMM YYYY | h:mm A')} color={'#ffffff'}>
                              <Button>RBS {this.state?.latest_vitals_with_datetime?.V013?.vital_value} mg/dL</Button>
                            </Tooltip>
                            {
                              (this.state?.latest_vitals_with_datetime?.HbA1c && this.state?.latest_vitals_with_datetime?.HbA1c != "" && this.state?.latest_vitals_with_datetime?.HbA1c != null)
                                ?
                                <>|</>
                                :
                                null
                            }
                          </>
                          :
                          null
                      }
                      {
                        this.state?.latest_vitals_with_datetime?.HbA1c && this.state?.latest_vitals_with_datetime?.HbA1c != "" && this.state?.latest_vitals_with_datetime?.HbA1c != null
                          ?
                          <>
                            <Tooltip placement="bottom" title={'Recorded on ' + moment(this.state?.latest_vitals_with_datetime?.HbA1c?.vital_datetime).format('D MMM YYYY | h:mm A')} color={'#ffffff'}>
                              <Button>HbA1c {this.state?.latest_vitals_with_datetime?.HbA1c?.vital_value} %</Button>
                            </Tooltip>
                          </>
                          :
                          null
                      }
                    </div>
                  </>
                  :
                  null
              }
              <div className="col-12 col-md-6">
                <div className="form-group add_buttons font-col">
                  {(this.state.showAddDrugButton) ?
                    null
                    :
                    <div className={"btn btn-outline-primary"} onClick={() => this.handleAddDrug()}>ADD</div>
                  }
                  <AutoComplete
                    id="drug_id"
                    name="drug_id"
                    style={{ width: 100 + '%' }}
                    showSearch
                    showArrow={this.state.showAddDrugButton}
                    notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                    loading={this.state.formIsFetching}
                    onSelect={this.onChangeDrugsSelect}
                    onSearch={this.onDrugssearch}
                    className="select-drugs"
                    value={this.state.selectDrugValue}
                    filterOption={false}
                    dropdownClassName="custom_drug_select"
                    autoFocus={this.state.selectDrugValue != null && this.state.selectDrugValue != '' ? false : true}
                    showAction={this.state.selectDrugValue != null && this.state.selectDrugValue != '' ? [] : ['focus', 'click']}
                    disabled={this.props.editData != null}
                  >
                    {this.optionDrugLoad()}
                  </AutoComplete>
                  <label htmlFor="drug_id">Medicine Name and Strength<span className="text-danger">*</span></label>
                </div>
              </div>
              {/* <div className="col-12 col-md-2">
                <div className="form-group">
                  <Select showSearch id="drug_form" name="drug_form" style={{ width: 100 + '%' }}
                    onChange={e => this.handleSelectOnChange(e)}
                    className="drug-input"
                    value={this.state.drug_form}
                    disabled={this.state.medicineFormValidate}
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }}
                  >
                    {this.optionFormDrugLoad()}
                  </Select>
                  <label htmlFor="drug_form">Form{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <input
                    type="text"
                    className={((this.state.drug_strength === '' || this.state.drug_strength == null) && this.state.isBorderColor) ? "form-control custom-pink" : "form-control"}
                    data-name="strength"
                    name="drug_strength"
                    id="strength"
                    onChange={(e) => this.handleInputOnChange(e)}
                    value={this.state.drug_strength}
                    placeholder="Strength"
                  />
                  <label htmlFor="strength">Strength{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                </div>
              </div> */}
              <div className="col-12 col-md-3">
                <div className="form-group">
                  <Select showSearch id="route" name="drug_route" style={{ width: 100 + '%' }}
                    onChange={this.onChangeDrugRoute}
                    //open={true}
                    className={((this.state.drug_route == '' || this.state.drug_route == null) && this.state.isBorderColor) ? "drug-input drug-Route custom-pink" : "drug-input drug-Route"}
                    value={this.state.drug_route}
                    // disabled={this.state.isDisabled}

                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }}
                  >
                    {this.optionRouteLoad()}
                  </Select>
                  <label htmlFor="route">Route{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                </div>
              </div>
              <div className="col-12 col-md-3">
                {
                  this.props.formType != 'template' && (this.state.dose_for_schedule_applicable == 'Y')
                    ?
                    <>
                      <div className="form-label-group input-group hasdata">
                        <AutoComplete
                          // options={this.optionDosageLoad()}
                          // id="dosage"
                          // name="drug_dosage"
                          // onSearch={this.onSearchDrugDosage}
                          // onBlur={this.onBlurDrugDosage}
                          // onSelect={this.onChangeDrugDosage}
                          className="drug-input drug-Dosage"
                          value="-"
                          disabled
                        // filterOption={(inputValue, option) =>
                        //   typeof option.value === 'string' && option.value.indexOf(inputValue) !== -1
                        // }
                        />
                        <label htmlFor="dosage">Dosage{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                      </div>
                      <label className="text-primary text-underline">
                        <span>{schedule_short}</span>
                      </label>
                    </>
                    :
                    <>
                      <div className="form-label-group input-group ">
                        <AutoComplete
                          options={this.optionDosageLoad()}
                          id="dosage"
                          name="drug_dosage"
                          onSearch={this.onSearchDrugDosage}
                          onBlur={this.onBlurDrugDosage}
                          onSelect={this.onChangeDrugDosage}
                          className={((this.state.dosageDropDown == '' || this.state.dosageDropDown == null) && this.state.isBorderColor) ? "drug-input drug-Dosage custom-pink" : "drug-input drug-Dosage"}
                          value={this.state.dosageDropDown}
                          filterOption={(inputValue, option) =>
                            typeof option.value === 'string' && option.value.indexOf(inputValue) !== -1
                          }
                        />
                        <label htmlFor="dosage">Dosage{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                        <span className="input-group-addon dosage-form-text">{this.state.drug_dosage_form_text}</span>
                      </div>
                      {
                        this.props.formType != 'template' && this.state.drug_id && this.state.adult_dose_exists == true && this.state.isPaediatricSpeciality == 1 && this.state.isPaediatrics
                          ?
                          <label className="text-secondary text-underline cursor-pointer">
                            <button type="button" className="ant-btn nostyle-link text-secondary text-underline p-0" onClick={e => this.handleDoseCalculator(e, true)}>
                              <span>{this.getDoseName(this.state.localDoseRule)}</span>
                            </button>
                          </label>
                          :
                          null
                      }
                    </>
                }
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-md-4 mb-2">
                <h2 className="text-heading d-md-inline mr-3">
                  Frequency{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}
                </h2>
                <Radio.Group value={this.state.drug_frequency} onChange={this.onChangeDrugFrequency}>
                  <Radio value={1} name="drug_frequency" title={'Daily'}>Daily</Radio>
                  <Radio value={2} name="drug_frequency" title={'Specific Days'}>Specific Days</Radio>
                  <Radio value={3} name="drug_frequency" title={'PRN'}>PRN/SOS</Radio>
                </Radio.Group>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group div_days_week" style={{ visibility: this.state.drug_frequency === 2 ? 'visible' : 'hidden' }}>
                  <Select
                    id="days_in_week"
                    name="drug_days_in_week"
                    style={{ width: 100 + '%' }}
                    showSearch
                    onChange={this.onChangeDaysInWeek}
                    className={(this.state.drug_days_in_week == '' || this.state.drug_days_in_week == null || this.state.drug_days_in_week.length == 0) ? "drug-input daysinweek custom-pink" : "drug-input daysinweek"}
                    value={this.state.drug_days_in_week}
                    mode="multiple"
                    allowClear
                  >
                    {this.optionDaysLoad()}
                  </Select>
                  <label htmlFor="days_in_week">Days{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12 col-md-2">
                <div className="form-group " style={{ display: this.state.drug_frequency !== 3 ? 'block' : 'none' }}>
                  <Select
                    id="daily_frequency"
                    name="daily_frequency"
                    style={{ width: 100 + '%' }}
                    showSearch
                    placeholder="Frequency"
                    onChange={this.onSelectDailyFrequencyChange}
                    className="drug-input dailyFrequency"
                    value={this.state.drug_daily_frequency}
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }}
                  >
                    {this.optiondaily_frequencyLoad()}
                  </Select>
                  <label htmlFor="daily_frequency">Frequency{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-label-group input-group" style={{ display: this.state.drug_frequency !== 3 ? 'block' : 'none' }}>
                  <Select showSearch id="schedules" name="schedules" style={{ width: 100 + '%' }}
                    // placeholder="Schedule"
                    onChange={this.selectSchedulesChange}
                    className={((this.state.drug_schedules == '' || this.state.drug_schedules == null || this.state.drug_schedules.length == 0) && this.state.isBorderColor) ? "schedules" : "schedules"}
                    value={this.state.drug_schedules}
                    mode="multiple"
                    onSelect={this.schedulesSelected}
                    allowClear
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }}
                  >
                    {this.optionschedulesLoad()}
                  </Select>
                  <label htmlFor="schedules">Schedule</label>
                </div>
                {this.state.drug_id && this.props.formType != 'template' && (this.state.dose_for_schedule == 'Y' || this.state.dose_for_schedule_applicable == 'Y') && this.state?.drug_schedules?.length > 1 ?
                  <label className="text-secondary text-underline cursor-pointer" style={{ display: this.state.drug_frequency !== 3 ? 'block' : 'none' }}>
                    <button type="button" className="ant-btn nostyle-link text-secondary text-underline p-0" onClick={() => this.setState({ doseScheduling: true })}>
                      <span>Dose For Schedule</span>
                    </button>
                  </label>
                  : null}
              </div>
              <div className="col-12 col-md-2">
                <div style={{ display: this.state.drug_frequency !== 3 ? 'block' : 'none' }}>
                  <div className={`form-group input-group frequency-field-row ${this.state.drug_number_of_days ? 'hasdata' : ''}`} >
                    <input
                      type="text"
                      className={((this.state.drug_number_of_days === '' || this.state.drug_number_of_days == null) && this.state.isBorderColor) ? "form-control onlynumbers" : "form-control onlynumbers"}
                      maxLength="3"
                      data-name="number_of_days"
                      name="drug_number_of_days"
                      id="number_of_days"
                      disabled={this.state.disableNumberOfDays}
                      onChange={(e) => { this.onChangeNumberOfDays(e) }}
                      value={this.state.drug_number_of_days}
                      placeholder="Duration"
                    />
                    <span className="input-group-addon dosage-form-text">{this.state.drug_duration_time}</span>
                    <label htmlFor="number_of_days">Duration</label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <Select id="drug_instruction" name="drug_instruction" style={{ width: 100 + '%' }}
                    placeholder="Instructions"
                    onChange={this.onChangeDrugInstruction}
                    className={((this.state.drug_instruction === '' || this.state.drug_instruction == null) && this.state.isBorderColor) ? "drug-input drug_Instructions custom-pink" : "drug-input drug_Instructions"}
                    value={this.state.drug_instruction}
                    // filterOption={(input, option) => {
                    //   return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    // }}
                    showSearch={false}
                  >
                    {this.optionInstructionLoad()}
                  </Select>
                  <label htmlFor="drug_instruction">Instructions</label>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group" style={{ display: this.state.drug_frequency !== 3 ? 'block' : 'none' }}>
                  <Select showSearch id="urgency" name="drug_urgency" style={{ width: 100 + '%' }}
                    placeholder="Priority"
                    onChange={this.onChangeDrugUrgency}
                    className={((this.state.drug_urgency == '' || this.state.drug_urgency == null) && this.state.isBorderColor) ? "drug-input Priority custom-pink" : "drug-input Priority"}
                    value={this.state.drug_urgency}
                    filterOption={(input, option) => {
                      return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }}
                  >
                    {this.optionPriorityLoad()}
                  </Select>
                  <label htmlFor="urgency">Priority{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                </div>
              </div>
            </div>
            {/* <div className="row">
              
            </div> */}
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <textarea className="form-control drug_medicine_remarks" id="medicine_remarks" placeholder="Remarks" name="drug_medicine_remarks"
                    rows="1"
                    onInput={(e) => this.autoHeight(e)}
                    onChange={(e) => this.handleInputOnChange(e)}
                    value={this.state.drug_medicine_remarks}
                  ></textarea>
                  <label htmlFor="medicine_remarks">Remarks</label>
                </div>
              </div>
            </div>
            <div className="row">
              {/*<div>{this.state.onRadioChange_Taper1}</div>*/}
              <div className="col-12 col-md-3">
                <div className="form-group input-group" >
                  <input
                    type="text"
                    className="form-control drug-input"
                    id="quantity"
                    data-name="quantity"
                    placeholder="Quantity"
                    data-fixedquantity=" "
                    disabled={true}
                    value={this.state.drug_quantity}
                    onChange={(e) => { this.onChangeQuantity(e) }}
                  />
                  <span className="input-group-addon dosage-form-text dosage-form-text-quanity" style={{ width: 100 + 'px', display: 'inline-block' }}>{this.state.drug_dosage_form_text_quantity}</span>
                  {/* className="required special-label-T" */}
                  <label htmlFor="quantity">Quantity</label>
                </div>
              </div>
              {this.props.formType != 'template' && this.state.drug_frequency !== 3 ? (
                <>
                  <div className="col-12 col-md-3 frequency-field-row">
                    <div className={`form-group react_datepicker ${this.state.drug_start_date ? "hasdata" : ""}`}>
                      <DatePicker
                        selected={this.changeDateToISO(this.state.drug_start_date)}
                        dateFormat={this.state.dateFormat}
                        minDate={moment().toDate()}
                        onChange={this.updateStartDate}
                      />
                      <span className="input-group-addon"><img src={calendarDatePickerIco} alt="Calendar" className="icon" /></span>
                      <label htmlFor="start_date">Start Date</label>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 frequency-field-row">
                    <div className={`form-group react_datepicker disabled ${this.state.drug_end_date ? "hasdata" : ""}`}>
                      <DatePicker
                        selected={this.changeDateToISO(this.state.drug_end_date)}
                        dateFormat={this.state.dateFormat}
                        disabled
                      />
                      <span className="input-group-addon"><img src={calendarDatePickerIco} alt="Calendar" className="icon" /></span>
                      <label htmlFor="end-date">End Date</label>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="col-12 col-md-3 mt-2">
                <div className="custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="continue_till_review"
                    name="continue_till_review"
                    checked={this.state.addContinueTillReview == 'Y' ? true : false}
                    value={this.state.addContinueTillReview == 'Y' ? true : false}
                    onChange={() => this.ContinueTillReview(this.state.addContinueTillReview)}
                  />
                  <label style={{ fontSize: "16px" }} className='custom-control-label text-secondary cursor-pointer' htmlFor="continue_till_review">
                    Continue till Review
                  </label>
                </div>
              </div>

            </div>
                {/* <div style={{'clear': 'both', 'width': '100%'}}></div> */}

            <div className="row">
              <div className="col-6 col-md-6 padding_top_1">
                {this.state.cimsallow ? <div className="medinfowrap"><Button className="nostyle-link p-0" onClick={() => this.showModalInteraction()}><i className="icon-medinfo drug_info"></i></Button></div> : null}

                {(LS_SERVICE.get("user_type") == USERTYPES.doctor && this.state.medicineFormValidate) ?
                  <div className="custom-control custom-checkbox-favourite fav_drug custom-control-inline pl-0">
                    <input type="checkbox" className="custom-control-input" id="favourite" name="favourite" drug_id="" />
                    <label className={this.state.isFavCheckedVal ? 'custom-control-label text-secondary text-underline cursor-pointer favorite label-star' : 'label-star custom-control-label text-secondary text-underline cursor-pointer'} htmlFor="favourite">
                      <Button style={{ width: 106 + 'px', textAlign: 'right' }} className="nostyle-link text-secondary text-underline p-0" onClick={() => this.FavoriteApi(this.state.drug_id)}>FAVOURITE</Button>
                    </label>
                  </div>
                  : null}

                {this.state.TaperBtnEnabled && this.state.medicineFormValidate ?
                  <div className=" custom-control custom-checkbox custom-control-inline" style={{ display: this.state.TaperBtnEnabled ? 'inline-flex' : 'none' }}>
                    <input type="checkbox" className="custom-control-input" id="taper_dose" name="taper_dose"
                      checked={this.state.addTaperDose} value={this.state.addTaperDose} onChange={() => this.Taperdose(this.state.drug_id)} />
                    <label style={{ textDecoration: 'underline', fontSize: "16px" }} className={this.state.addTaperDose ? 'custom-control-label text-secondary cursor-pointer taper' : 'custom-control-label text-secondary cursor-pointer'} htmlFor="taper_dose">
                      TAPER DOSE
                    </label>
                  </div>
                  : null}

                {
                  this.state.drug_id && this.props.formType != 'template' && this.state.correction_dose == 'Y'
                    ?
                    <div className={this.state.TaperBtnEnabled ? "custom-control custom-checkbox custom-control-inline padding_lef_corr" : "custom-control custom-checkbox custom-control-inline pl-0"}>
                      <label style={{ textDecoration: 'underline', paddingLeft: 0, fontSize: "16px" }} className="text-secondary cursor-pointer taper" onClick={() => this.setState({ doseCorrection: true })}>
                        CORRECTION DOSE
                      </label>
                    </div>
                    : null}
              </div>
              {/* <div className="col-12 col-md-1"> </div> */}
              <div className="col-6 modal-medicine">
                <div className="float-right">
                  <Button className="btn-outline-secondary px-4 small text-uppercase mr-3" id="drug-clear" onClick={() => this.Formclear()}>Clear</Button>
                  <Button
                    type="button"
                    className="btn btn-primary px-4 small text-uppercase mandatory-save"
                    id="drug-save"
                    data-drug="-1"
                    data-prescriptionid="-1"
                    disabled={!this.state.allowEdit ? true : this.state.AddMedicineButton}
                    onClick={() => {
                      this.saveMedicineData();

                    }}
                  >{this.state.editData !== null ? 'Update' : 'Add'}</Button>
                </div>
              </div>
            </div>
          </Form>
        </div>

        <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
          title="Drug Interaction"
          visible={this.state.isModalInteractionVisible}
          onOk={() => this.handleInteractionOk()}
          onCancel={() => this.handleInteractionCancel()}
          footer={false}
          style={{ width: '600px' }}
        >
          {/*<iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMS_INTERACTION}/${this.state.$drug_id_Interaction_Modalurl}`}></iframe>*/}

          <div className="row mb-3">
            <div className="col-12">This drug has severe interaction with {this.state.interactionDrugName}. Are you sure you want to continue?</div>
          </div>
          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>No</button>
            <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>Yes</button>
          </div>
        </Modal>

        <Modal className="myDrugInfo_modal" id="myDrugInfo"
          title="Drug Information"
          visible={this.state.isModalInfoVisible}
          onOk={() => this.handleInfoOk()}
          onCancel={() => this.handleInfoCancel()}
          footer={false}
        >
          <iframe id="drug_interaction_widget_iframe1" title="information" className="cimsiframe" src={`${CIMS_INFO}/${this.state.$drug_id}`}></iframe>
        </Modal>
        {this.state.isModalTaperDoseVisible ?
          <Modal className="taper_dose_modal" id="taper_dose_modal"
            title="Add Taper Dose"
            visible={this.state.isModalTaperDoseVisible}
            onOk={() => this.handleTaperOk()}
            bodyStyle={{ padding: '0 10px' }}
            onCancel={() => this.handleTaperCancel()}
            width={1000}
            footer={[
              <Button
                type="button"
                className="btn btn-primary text-uppercase mr-3 mandatory-save text-uppercase"
                id="drug-save-taper"
                disabled={this.state.addTaperDoseBtn}
                onClick={() => {
                  this.saveMedicineData();
                }} >
                ADD
              </Button>
            ]}
          >
            <h5 className="col-12 text-secondary font-weight-normal mb-3">
              Drug: <span className="taper_drug_name">{this.state.drug_name}</span>
            </h5>
            <div className="col-12 emrfrm">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <div className="form-label-group input-group">
                    {/*<Select
                            style={{ width: 100 + '%' }}
                            id="dosage_taper"
                            className="drug-input mandatory-field col-lg-8 p-0"
                            name="drug_dosage_taper"
                            onChange={this.onChangeDrugDosage}
                            value={this.state.drug_dosage_taper}
                        >
                          {this.optionDosageLoad()}
                        </Select>*/}
                    <AutoComplete
                      style={{ width: 100 + '%' }}
                      options={this.optionDosageLoad()}
                      id="dosage_taper"
                      name="drug_dosage_taper"
                      onSearch={this.onSearchDrugDosage}
                      onBlur={this.onBlurDrugDosage}
                      onSelect={this.onChangeDrugDosage}
                      className="drug-input mandatory-field col-lg-8 p-0"
                      value={this.state.dosageDropDownTaper}
                      filterOption={(inputValue, option) =>
                        typeof option.value === 'string' && option.value.indexOf(inputValue) !== -1
                      }
                    />
                    <span className="input-group-addon dosage-form-text-Modal col-lg-4">{this.state.drug_dosage_form_text_taper}</span>
                    <label className="required special-label-open" htmlFor="dosage_taper">
                      Dosage <span>*</span>
                    </label>
                  </div>
                </div>

                {/* <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control drug-input mandatory-field"
                      data-name="strength"
                      name="drug_strength_taper"
                      placeholder=" "
                      id="strength_taper"
                      onChange={(e) => this.handleInputOnChange(e)}
                      value={this.state.drug_strength_taper}
                    />
                    <label className="required special-label-open" htmlFor="strength">
                      Strength<span>*</span>
                    </label>
                  </div>
                </div> */}

                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <Select style={{ width: 100 + '%' }}
                      name="drug_route_taper"
                      id="route_taper"
                      placeholder="Route"
                      onChange={this.onChangeDrugRoute}
                      className="drug-input drug-Route"
                      value={this.state.drug_route_taper}
                    >
                      {this.optionRouteLoad()}
                    </Select>
                    <label className="required special-label-open" htmlFor="route_taper">Route<span>*</span></label>
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="form-group input-group frequency-field-row frequency-field-row" style={{ display: this.state.drug_frequency_taper !== 3 ? "flex" : "none" }}>
                    <input
                      type="text"
                      className="form-control drug-input mandatory-field onlynumbers"
                      maxLength="3"
                      data-name="number_of_days"
                      name="drug_number_of_days_taper"
                      id="number_of_days_taper"
                      placeholder="Number of Days"
                      disabled={this.state.disableNumberOfDaysTaper}
                      onChange={(e) => { this.onChangeNumberOfDays(e) }}
                      value={this.state.drug_number_of_days_taper}
                      defaultValue={this.state.drug_number_of_days_taper}
                    />
                    <span className="input-group-addon dosage-form-text">{this.state.drug_duration_time_taper}</span>
                    <label className="required" htmlFor="number_of_days_taper">
                      Duration</label>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-sm-8">
                  <h4 className="page-title d-md-inline mr-3">
                    Frequency{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}
                  </h4>
                  <Radio.Group value={this.state.drug_frequency_taper} onChange={this.onChangeDrugFrequency}>
                    <Radio value={1} name="drug_frequency_taper">Daily</Radio>
                    <Radio value={2} name="drug_frequency_taper">Specific Days</Radio>
                    <Radio value={3} name="drug_frequency_taper">PRN/SOS</Radio>
                  </Radio.Group>
                </div>
                <div className="col-12 col-sm-4 div_days_week" id="taper_div_days_week" style={{ display: this.state.drug_frequency_taper === 2 ? 'inline-block' : 'none' }}>
                    <div className="form-group">
                      <Select
                        id="drug_days_in_week_taper"
                        name="days_in_week"
                        style={{ width: 100 + '%' }}
                        showSearch
                        onChange={this.onChangeDaysInWeek}
                        className={"days_in_week drug-input mandatory-field"}
                        value={this.state.drug_days_in_week_taper}
                        mode="multiple"
                        allowClear
                      >
                        {this.optionDaysLoad()}
                      </Select>
                      <label className="required" htmlFor="days_in_week_taper"
                      >Days<span>*</span></label>
                    </div>
                </div>
              </div>
              <div className="row">
                <div className="row frequency-field-row" style={{ width: 100 + '%', margin: 0 + 'px' }} >

                  <div className="col-12 col-sm-6" style={{ display: this.state.drug_frequency_taper !== 3 ? 'inline-block' : 'none' }}>
                    <div className="form-group hasdata">
                      <Select
                        style={{ width: 100 + '%' }}
                        name="daily_frequency"
                        placeholder="Frequency"
                        onChange={this.onSelectDailyFrequencyChange}
                        className="drug-input dailyFrequency"
                        value={this.state.drug_daily_frequency_taper}
                        id="daily_frequency_taper"
                      >
                        {this.optiondaily_frequencyLoad()}
                      </Select>
                      <label className="required special-label-open" htmlFor="daily_frequency_taper">Frequency<span>*</span></label>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6" style={{ display: this.state.drug_frequency_taper !== 3 ? 'block' : 'none' }}>
                    <div className="form-group">
                      <Select showSearch id="schedules" name="schedules" style={{ width: 100 + '%' }}
                        placeholder="schedules"
                        onChange={this.selectSchedulesChange_Taper}
                        className="drug-input schedules"
                        value={this.state.drug_schedules_taper}
                        mode="multiple"
                        allowClear
                      >
                        {this.optionschedulesLoad()}
                      </Select>
                      <label className="required special-label-open" htmlFor="schedules_taper"
                      >Schedules</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4 className="page-title d-md-inline mr-2">
                    Condition{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}
                  </h4>
                  <Radio.Group onChange={this.onChangeTaperModelContinue} value={this.state.taperModelContinue}>
                    <Radio value={1} name="continue_taper">Then</Radio>
                    <Radio value={0} name="continue_taper">Stop</Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </Modal>
          : null}

        {/*  <Modal id="alert_confirm_message_medicine"
                 className="alert_confirm_message"
                 title='Are you sure you want to delete medicine?'
                 visible={this.state.isAlertMedicineMVisible}
                 onOk={() => this.handleAlertMOk()}
                 bodyStyle={{ padding: 0 + 'px' }}
                 onCancel={() => this.handleAlertMCancel()}
                 closable={false}
                 footer={[
                   <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.handleAlertMCancel()}>
                     CANCEL
                   </Button>,
                   <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={() => this.handleAlertMOk()}>
                     DELETE
                   </Button>,
                 ]}
          >
          </Modal>*/}

        {/* MEDICATION: CORRECTION DOSE */}
        <Modal
          title={'Correction Dose for ' + this.state.drug_name}
          visible={this.state.doseCorrection}
          onCancel={() => this.handleCorrectionDoseCancel()}
          style={{ top: 40 }}
          width="600px"
          className="emrfrm"
          footer={[
            <div className="row">
              <div className="col-md-12 float-right">

                <button className="btn btn-outline-primary px-5 submit-delete-history mr-3"
                  disabled={!this.state.allowEdit ? true : this.state.AddCorrectionDoseButton} onClick={() => this.handleCorrectionDoseSaveDefault()}>
                  SAVE AS DEFAULT{this.state.spin_loading ? LOADER : ''}</button>
                <button className="btn btn-primary px-5 submit-delete-history"
                  disabled={!this.state.allowEdit ? true : this.state.AddCorrectionDoseButton} onClick={() => this.handleCorrectionDoseSave()}>
                  SAVE{this.state.spin_loading ? LOADER : ''}</button>
              </div>
            </div>
          ]}
        >
          <Form
            id="doseScheduling"
            className="add-vitals emrfrm"
            layout="vertical"
          >
            <div className="row">
              <div className="table_poup">
                <div className="tr abc">
                  <div className="table_td"><h2>BLOOD SUGAR (mg/dL)</h2>
                  </div>
                  {/* <div className="table_td">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</div> */}
                  <div className="table_td"><h2>TAKE INSULIN</h2>
                  </div>
                </div>
                {[...Array(8)].map((e, i) => {
                  var id_name_from = "correction_dose_from_" + i;
                  var id_name_to = "correction_dose_to_" + i;
                  var id_name_unit = "correction_dose_unit_" + i;
                  return <>
                    <div className="tr">
                      <div className={this.state?.correction_dose_error_messsage?.[i] ? "table_td has-error" : "table_td"}>
                        <div className="row">
                          <div className="col-6 antInputItem">
                            <div className={this.state?.correction_dose_value?.[i]?.correction_dose_from >= 0 ? "form-group hasdata mb-1" : "form-group mb-1"}>
                              <input
                                type="text"
                                className="form-control drug-input mandatory-field onlynumbers"
                                maxLength="4"
                                data-name="correction_dose_from"
                                name={id_name_from}
                                id={id_name_from}
                                data-position={i}
                                placeholder="From"
                                onChange={(e) => { this.onChangeCorrectionDose(e) }}
                                value={this.state?.correction_dose_value?.[i]?.correction_dose_from >= 0 ? this.state?.correction_dose_value?.[i]?.correction_dose_from : ""}
                                defaultValue={this.state?.correction_dose_value?.[i]?.correction_dose_from >= 0 ? this.state?.correction_dose_value?.[i]?.correction_dose_from : ""}
                              />
                              <label className="required" htmlFor={id_name_from}>
                                From
                                {this.state?.correction_dose_value?.[i]?.correction_dose_from || this.state?.correction_dose_value?.[i]?.correction_dose_to || this.state?.correction_dose_value?.[i]?.correction_dose_unit ? <span className="text-danger">*</span> : null}
                              </label>
                            </div>
                          </div>
                          <div className="col-6 antInputItem">
                            <div className={this.state?.correction_dose_value?.[i]?.correction_dose_to >= 0 ? "form-group hasdata mb-1" : "form-group mb-1"}>
                              <input
                                type="text"
                                className="form-control drug-input mandatory-field onlynumbers"
                                maxLength="4"
                                data-name="correction_dose_to"
                                name={id_name_to}
                                id={id_name_to}
                                data-position={i}
                                placeholder="To"
                                onChange={(e) => { this.onChangeCorrectionDose(e) }}
                                value={this.state?.correction_dose_value?.[i]?.correction_dose_to >= 0 ? this.state?.correction_dose_value?.[i]?.correction_dose_to : ""}
                                defaultValue={this.state?.correction_dose_value?.[i]?.correction_dose_to >= 0 ? this.state?.correction_dose_value?.[i]?.correction_dose_to : ""}
                              />
                              <label className="required" htmlFor={id_name_to}>
                                To
                                {this.state?.correction_dose_value?.[i]?.correction_dose_from || this.state?.correction_dose_value?.[i]?.correction_dose_to || this.state?.correction_dose_value?.[i]?.correction_dose_unit ? <span className="text-danger">*</span> : null}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table_td">
                        <div className="row">
                          <div className="col-12 antInputItem">
                            <div className={this.state?.correction_dose_value?.[i]?.correction_dose_unit >= 0 ? "form-group hasdata mb-1" : "form-group mb-1"}>
                              <input
                                type="text"
                                className="form-control drug-input mandatory-field onlynumbers"
                                maxLength="4"
                                data-name="correction_dose_unit"
                                name={id_name_unit}
                                id={id_name_unit}
                                data-position={i}
                                onChange={(e) => { this.onChangeCorrectionDose(e) }}
                                value={this.state?.correction_dose_value?.[i]?.correction_dose_unit >= 0 ? this.state?.correction_dose_value?.[i]?.correction_dose_unit : ""}
                                defaultValue={this.state?.correction_dose_value?.[i]?.correction_dose_unit >= 0 ? this.state?.correction_dose_value?.[i]?.correction_dose_unit : ""}
                              />
                              <label className="required" htmlFor={id_name_to}>
                                Insulin ({this.state.drug_dosage_form_text})
                                {this.state?.correction_dose_value?.[i]?.correction_dose_from || this.state?.correction_dose_value?.[i]?.correction_dose_to || this.state?.correction_dose_value?.[i]?.correction_dose_unit ? <span className="text-danger">*</span> : null}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state?.correction_dose_error_messsage?.[i] ? <div className="text-secondary tr"><div className="table_td pl-3"> {this.state.correction_dose_error_messsage[i]}</div> <div className="table_td"></div> </div> : null}
                  </>
                })}
              </div>
            </div>

          </Form>
        </Modal>

        {/* MEDICATION: DOSAGE SCHEDULING popup */}
        <Modal
          title={'Dose for Schedule'}
          visible={this.state.doseScheduling}
          onOk={() => this.handleDoseForScheduleOk()}
          onCancel={(e) => this.setState({ doseScheduling: false })}
          style={{ top: 40 }}
          width="1200px"
          className="emrfrm"
          footer={[
            <div className="text-right">
              <button className="btn btn-primary px-5 submit-delete-history" disabled={!this.state.allowEdit ? true : this.state.AddDoseForScheduleButton} onClick={() => this.handleDoseForScheduleOk()}>
                SAVE{this.state.spin_loading ? LOADER : ''}
              </button>
            </div>
          ]}
        >
          <Form
            id="doseScheduling"
            className="add-vitals"
            layout="vertical"
          >
            <h2 className="model_head">Dose ({this.state.drug_dosage_form_text})</h2>
            <div className="row mb-3 mt-3">
              {
                this.state.drug_schedules.includes(1)
                  ?
                  <div className={doseForScheduleClass}>
                    <div className="form-label-group input-group hasdata">
                      <AutoComplete
                        options={this.optionDosageLoad()}
                        id='dosage_morning'
                        name='drug_dosage_morning'
                        onSearch={this.onSearchDrugDosageMorning}
                        onBlur={this.onBlurDrugDosageMorning}
                        onSelect={this.onChangeDrugDosageMorning}
                        className="drug-input drug-Dosage"
                        value={this.state.dosageDropDownMorning}
                        filterOption={(inputValue, option) =>
                          typeof option.value === 'string' && option.value.indexOf(inputValue) !== -1
                        }
                      />
                      <label htmlFor="dosage_morning">Morning{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                      <span className="input-group-addon dosage-form-text">{this.state.drug_dosage_form_text}</span>
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.drug_schedules.includes(2)
                  ?
                  <div className={doseForScheduleClass}>
                    <div className="form-label-group input-group hasdata">
                      <AutoComplete
                        options={this.optionDosageLoad()}
                        id='dosage_afternoon'
                        name='drug_dosage_afternoon'
                        onSearch={this.onSearchDrugDosageAfternoon}
                        onBlur={this.onBlurDrugDosageAfternoon}
                        onSelect={this.onChangeDrugDosageAfternoon}
                        className="drug-input drug-Dosage"
                        value={this.state.dosageDropDownAfternoon}
                        filterOption={(inputValue, option) =>
                          typeof option.value === 'string' && option.value.indexOf(inputValue) !== -1
                        }
                      />
                      <label htmlFor="dosage_afternoon">Afternoon{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                      <span className="input-group-addon dosage-form-text">{this.state.drug_dosage_form_text}</span>
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.drug_schedules.includes(3)
                  ?
                  <div className={doseForScheduleClass}>
                    <div className="form-label-group input-group hasdata">
                      <AutoComplete
                        options={this.optionDosageLoad()}
                        id='dosage_evening'
                        name='drug_dosage_evening'
                        onSearch={this.onSearchDrugDosageEvening}
                        onBlur={this.onBlurDrugDosageEvening}
                        onSelect={this.onChangeDrugDosageEvening}
                        className="drug-input drug-Dosage"
                        value={this.state.dosageDropDownEvening}
                        filterOption={(inputValue, option) =>
                          typeof option.value === 'string' && option.value.indexOf(inputValue) !== -1
                        }
                      />
                      <label htmlFor="dosage_evening">Evening{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                      <span className="input-group-addon dosage-form-text">{this.state.drug_dosage_form_text}</span>
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.drug_schedules.includes(4)
                  ?
                  <div className={doseForScheduleClass}>
                    <div className="form-label-group input-group hasdata">
                      <AutoComplete
                        options={this.optionDosageLoad()}
                        id='dosage_night'
                        name='drug_dosage_night'
                        onSearch={this.onSearchDrugDosageNight}
                        onBlur={this.onBlurDrugDosageNight}
                        onSelect={this.onChangeDrugDosageNight}
                        className="drug-input drug-Dosage"
                        value={this.state.dosageDropDownNight}
                        filterOption={(inputValue, option) =>
                          typeof option.value === 'string' && option.value.indexOf(inputValue) !== -1
                        }
                      />
                      <label htmlFor="dosage_night">Night{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                      <span className="input-group-addon dosage-form-text">{this.state.drug_dosage_form_text}</span>
                    </div>
                  </div>
                  :
                  null
              }
            </div>
            <div className="row">
              {
                this.state.drug_schedules.includes(1)
                  ?
                  <div className={doseForScheduleClass}>
                    <div className="form-group hasdata">
                      <Select
                        showSearch
                        id="drug_instruction_morning"
                        name="drug_instruction_morning"
                        style={{ width: 100 + '%' }}
                        onChange={this.onChangeDrugInstructionMorning}
                        className="drug-input drug_Instructions"
                        value={this.state.drug_instruction_morning}
                      >
                        {this.optionMorningInstructionLoad()}
                      </Select>
                      <label htmlFor="drug_instruction_morning">Instructions{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.drug_schedules.includes(2)
                  ?
                  <div className={doseForScheduleClass}>
                    <div className="form-group hasdata">
                      <Select
                        showSearch
                        id="drug_instruction_afternoon"
                        name="drug_instruction_afternoon"
                        style={{ width: 100 + '%' }}
                        onChange={this.onChangeDrugInstructionAfternoon}
                        className="drug-input drug_Instructions"
                        value={this.state.drug_instruction_afternoon}
                      >
                        {this.optionAfternoonInstructionLoad()}
                      </Select>
                      <label htmlFor="drug_instruction_afternoon">Instructions{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.drug_schedules.includes(3)
                  ?
                  <div className={doseForScheduleClass}>
                    <div className="form-group hasdata">
                      <Select
                        showSearch
                        id="drug_instruction_evening"
                        name="drug_instruction_evening"
                        style={{ width: 100 + '%' }}
                        onChange={this.onChangeDrugInstructionEvening}
                        className="drug-input drug_Instructions"
                        value={this.state.drug_instruction_evening}
                      >
                        {this.optionEveningInstructionLoad()}
                      </Select>
                      <label htmlFor="drug_instruction_evening">Instructions{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                    </div>
                  </div>
                  :
                  null
              }
              {
                this.state.drug_schedules.includes(4)
                  ?
                  <div className={doseForScheduleClass}>
                    <div className="form-group hasdata">
                      <Select
                        showSearch
                        id="drug_instruction_night"
                        name="drug_instruction_night"
                        style={{ width: 100 + '%' }}
                        onChange={this.onChangeDrugInstructionNight}
                        className="drug-input drug_Instructions"
                        value={this.state.drug_instruction_night}
                      >
                        {this.optionNightInstructionLoad()}
                      </Select>
                      <label htmlFor="drug_instruction_night">Instructions{(this.state.medicineFormValidate) ? <span className="text-danger">*</span> : null}</label>
                    </div>
                  </div>
                  :
                  null
              }
            </div>
          </Form>
        </Modal>


        <Modal
          title="Poly Pharmacy Alert"
          visible={this.state.polyPharmacyModal}
          onCancel={(e) => this.handlePolyCancel()}
          footer={false}
          closable={false}
        >
          <div className="row mb-3">
            <div className="col-12">The patient is already on {this.state.currentMeds.length} medications. Are you sure you want to prescribe more?</div>
          </div>
          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyCancel(e)}>No</button>
            <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyOk(e)}>Yes</button>
          </div>
        </Modal>
        <Modal
          title="Dose Calculator"
          visible={this.state.showDoseCalculator}
          onCancel={(e) => this.handleDoseCalculator(false)}
          footer={false}
          closable={false}
        >
          <div className="emrfrm row">
            <div className="col-6">
              <div className="custom-control custom-radio mt-2">
                <input type="radio" id="frieds_rule" name="dose" value="1" checked={this.state.localDoseRule == 1} className="custom-control-input" onChange={e => this.handleDoseChange(e, 1)} />
                <label className="custom-control-label font-weight-semibold" htmlFor="frieds_rule">Fried’s Rule</label>
              </div>
              <div className="pl-4"><small>Dose = [Age (month)/150] * Adult Dose</small></div>
            </div>
            <div className="col-6">
              <div className="custom-control custom-radio mt-2">
                <input type="radio" id="youngs_rule" name="dose" value="2" checked={this.state.localDoseRule == 2} className="custom-control-input" onChange={e => this.handleDoseChange(e, 2)} />
                <label className="custom-control-label font-weight-semibold" htmlFor="youngs_rule">Young’s Rule</label>
              </div>
              <div className="pl-4"><small>Dose =  (Age in years/ Age in years +12) * Adult Dose</small></div>
            </div>
            <div className="col-6">
              <div className="custom-control custom-radio mt-2">
                <input type="radio" id="clarkes_rule" name="dose" value="3" checked={this.state.localDoseRule == 3} className="custom-control-input" onChange={e => this.handleDoseChange(e, 3)} />
                <label className="custom-control-label font-weight-semibold" htmlFor="clarkes_rule">Clarke's Rule</label>
              </div>
              <div className="pl-4"><small>Dose = (Body Weight in Kgs/ 70) * Adult Dose</small></div>
            </div>
            <div className="col-6">
              <div className="custom-control custom-radio mt-2">
                <input type="radio" id="dose_bsa" name="dose" value="4" checked={this.state.localDoseRule == 4} className="custom-control-input" onChange={e => this.handleDoseChange(e, 4)} />
                <label className="custom-control-label font-weight-semibold" htmlFor="dose_bsa">Dose based on BSA</label>
              </div>
              <div className="pl-4"><small>(Child's BSA/ Average BSA for adult) * Adult Dose</small></div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default MedicineForm;