import React, { Component, Fragment } from 'react';
import { Select, Modal, Button, Radio } from 'antd';
import { MEDICINES_GETALL, SEARCH_DRUGS, DRUGS_CONSTANT, DRUG, DRUG_FAVOURITE, CIMS_INFO, CIMS_INTERACTION_EXISTS, CIMS_INTERACTION, REFERENCE_TYPE, PRESCRIPTION_DATA, DELETE_MEDICINE, MYHC_APPTYPE, WOODLANDS, HOSPITALDEFAULTS, LOADER_BLACK } from '../../utils/constant';
import 'antd/dist/antd.css';
import LS_SERVICE from '../../utils/localStorage';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './medicine.scss';
import calendarDatePickerIco from "../../assets/images/ic-calendar.png";
import { jQueryLabels, AntSelectLabels, AntClearLabels, FixedCta, ClearAntList, ClearSelectSelectionItem } from '../../utils/jQueryLabels';

const { Option } = Select;
class MedicineTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewType: 'full', //template, full
      constants: [],
      drugsData: [],
      eventBased: false,
      patientObsGynaeInfo: {},
      patientInfo:{},
      alreadyPrescribedDrugs: [],
      //constants
      drugForm: {},
      drugDosage: {},
      drugRoute: {},
      drugDaysInWeek: {},
      drugDailyFrequency: {},
      drugSchedules: {},
      drugUrgency: {},
      drugInstructions: {},
    }
  }

  optionFormDrugLoad() {
    if (!this.state.constants.drugForm || this.state.constants.drugForm.length === 0) {
      return (<Option value={0}><span>No Result Found</span></Option>)
    }
    return this.state.constants.drugForm.map((obj, i) => {
      this.state.drugForm[obj.id] = obj.description;
      return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
      )
    }, this)
  }

  optionDosageLoad() {

    // return this.state.constants.drugDosage.map((obj, i) => {
    //   this.state.drugDosage[obj.id] = obj.description;
    // }, this)
    if(this.state.constants.drugDosage && this.state.constants.drugDosage.length>0){
      return this.state.constants.drugDosage.map((obj, i) => {
        this.state.drugDosage[obj.id] = obj.description;
      }, this)
    }else{
      return null;
    }

  }

  optionRouteLoad() {
    if (this.state.drugRoute && this.state.drugRoute.length>0) {
      return this.state.constants.route.map((obj, i) => {
        this.state.drugRoute[obj.id] = obj.description;
      }, this)
    } else {
      return null;
    }
    
  }

  optionDaysLoad() {
    return this.state.constants.daysInWeek.map((obj, i) => {
      this.state.drugDaysInWeek[obj.id] = obj.description;
    }, this)
  }

  optionDailyFrequencyLoad() {
    return this.state.constants.dailyFrequency.map((obj, i) => {
      this.state.drugDailyFrequency[obj.id] = obj.description;
    }, this)
  }

  optionSchedulesLoad() {
    this.schedulesName = [];
    return this.state.constants.drugSchedules.map((obj, i) => {
      this.state.drugSchedules[obj.id] = obj.description;
      this.schedulesName.push(obj.description)
    }, this)
  }

  optionPriorityLoad() {
    return this.state.constants.drugUrgency.map((obj, i) => {
      this.state.drugUrgency[obj.id] = obj.description;
    }, this)
  }

  optionInstructionLoad() {
    return this.state.constants.drugInstructions.map((obj, i) => {
      this.state.drugInstructions[obj.id] = obj.description;
    }, this)
  }



  componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
  }

  componentDidUpdate(prevProps, prevState) {

  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return {
      drugsData: nextProps.drugsData,
      viewType: nextProps.viewType,
      eventBased: nextProps.eventBased,
      patientObsGynaeInfo: nextProps.patientObsGynaeInfo,
      patientInfo: nextProps.patientInfo,
      alreadyPrescribedDrugs: nextProps.alreadyPrescribedDrugs,
      constants: nextProps.constants
    }
  }

  handleEditDelete = (e, modal, data, action, idx) => {

    if (this.props.isPracticing === false) {
      this.props.practiceModal('isPracticingModal');
      return;
    }

    this.props.handlePopUp(e, modal, data, action, idx);
  }

  updateStartDate = (start_data, this_drug_id) => {
    var medicine_data = []
    start_data = moment(start_data).format('YYYY-MM-DD');
    for(var drugs of this.state.drugsData){
      if(drugs.drug_id == this_drug_id){
        medicine_data.push(drugs)
      }
      else{
        medicine_data.push(drugs)
      }
    }
    this.props.updateData(medicine_data, start_data, this_drug_id)
  }

  render() {
    this.optionFormDrugLoad();
    this.optionDosageLoad();
    this.optionRouteLoad();
    this.optionDaysLoad();
    this.optionDailyFrequencyLoad();
    this.optionSchedulesLoad();
    this.optionPriorityLoad();
    this.optionInstructionLoad();
    let medicineRows = {};
    if (this.state.drugsData && this.state.drugsData.length > 0) {
      for (const medInfo of this.state.drugsData) {
        let medKey = medInfo['drug_id'];
        if(medInfo['dose_for_schedule'] == 'Y' && medInfo['schedules']?.length > 1){
          medicineRows[medKey] = medicineRows[medKey] !== undefined ? (medicineRows[medKey] + medInfo['schedules'].length) : medInfo['schedules'].length;
        }
        else{
          medicineRows[medKey] = medicineRows[medKey] !== undefined ? (medicineRows[medKey] + 1) : 1;
        }
        if(medInfo['correction_dose'] == 'Y' && medInfo['correction_dose_details']?.length <= 0){
          var final_cd = []
          for(var cd of medInfo['correction_dose_data']){
            final_cd.push([cd.range_min, cd.range_max, cd.range_unit])
          }
          medInfo['correction_dose_details'] = final_cd
        }
      }
    }

    let iteratedDrugs = [];
    let mederowCount = 1;
    let medicine_remarks = '';
    let correction_dose = 'N';
    let correction_dose_data;

    return (
      <Fragment>
        {this.state.drugsData && this.state.drugsData.length > 0 ?
          <>
            <div className="table-responsive emrfrm">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>MEDICINE NAME</th>
                    {/* {this.state.viewType != 'copy-from-prescription' ? (
                        <th>STRENGTH</th>
                    ) : null} */}
                    <th>ROUTE</th>
                    {this.state.viewType != 'copy-from-prescription' ? (
                        <th>PRIORITY</th>
                    ) : null}
                   <th>FREQUENCY</th>
                   <th>SCHEDULE</th>
                    <th>DOSAGE</th>
                    <th>INSTRUCTIONS</th>
                    <th width="120px">Duration</th>
                    {(((this.state.viewType == 'template'  || this.state.viewType == 'list-visit') && this.state.eventBased == true) || (this.state.viewType != 'template' && this.state.viewType != 'list-visit')) ? <th width="180px">DUE FROM</th> : null}
                    {this.props.showAction ? <td width="120">Action</td> : null}
                  </tr>
                </thead>
                <tbody>
                  {this.state.drugsData.map((data, i) => {
                    var deleteIndex = this.state.viewType == 'visit-full' ? data.id : i;
                    let medexists;
                    var protocol_start_date = moment(data.start_date).format('DD MMM, YYYY');
                    if (!iteratedDrugs.includes(data.drug_id)) {
                      medexists = false;
                      mederowCount = 1;
                      medicine_remarks = data.medicine_remarks
                      correction_dose = data.correction_dose
                      correction_dose_data = data.correction_dose_data
                      iteratedDrugs.push(data.drug_id);
                    } else {
                      medexists = true;
                      if (medicine_remarks === '') {
                        medicine_remarks = data.medicine_remarks;
                        correction_dose = data.correction_dose
                        correction_dose_data = data.correction_dose_data
                      }
                    }

                    if (data.schedules === null) {
                      data.schedule_name = null
                    }
                    var custom_bgcolor;
                    var show_original_due = false;
                    if(this.state.alreadyPrescribedDrugs?.includes(data.drug_id)){
                      custom_bgcolor = '#dddbdb'
                    }
                    else if(moment(data.start_date_protocol).diff(moment(), 'days') < 0){
                      custom_bgcolor = '#f7eaea'
                      show_original_due = true;
                    }
                    else if(moment(data.start_date).diff(moment(), 'days') == 0){
                      custom_bgcolor = '#dcedf4'
                    }

                    const drugRowSpan = medicineRows[data.drug_id] !== undefined && medicineRows[data.drug_id] > 1 ? medicineRows[data.drug_id] : 1;
                    let drugFreqText = data.drug_frequency ? Object.keys(this.state.constants.drugFrequency).find(key => this.state.constants.drugFrequency[key].id === data.drug_frequency) : null;
                    drugFreqText = drugFreqText != null ? this.state.constants.drugFrequency[drugFreqText].description : '';
                    let htmlData = [];
                    if(data.dose_for_schedule == 'Y' && data?.schedules?.length > 1){
                      var counter = 1;
                      data.drug_duration_time = (data.daily_frequency == 8 || data.daily_frequency == 10)? 'Months' : (data.daily_frequency == 9 || data.daily_frequency == 11)? 'Weeks' : 'Days';
                      for(var sch of data?.schedules){
                        if(counter == 1){
                          htmlData.push(
                            <>
                              <tr key={i}>
                                {drugRowSpan > 1 && !medexists ? <td rowSpan={drugRowSpan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.drug_name}</td> : null}
                                {drugRowSpan === 1 && !medexists ? <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.drug_name}</td> : null}
                                {/* {this.state.viewType != 'copy-from-prescription' ? (
                                  <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.strength}</td>
                                ) : null} */}
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{this.state.constants.route.map((data1, i) => data1.id == data.route ? data1.description : null)}</td>
                                {this.state.viewType != 'copy-from-prescription' ? (
                                  <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.urgency !== null ? this.state.constants.drugUrgency.map((data1, i) => data1.id === data.urgency ? data1.description : null) : '-'}</td>
                                ) : null}
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.days_in_week_text && data.drug_frequency && parseInt(data.drug_frequency) == 2 ? data.days_in_week_text : (data.drug_frequency == 1 ? this.state.drugDailyFrequency[data.daily_frequency] : drugFreqText)}</td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  {sch == 1 ? 'Morning': null}
                                  {sch == 2 ? 'Afternoon': null}
                                  {sch == 3 ? 'Evening': null}
                                  {sch == 4 ? 'Night': null}
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  {sch == 1 ? data.dosage_value_morning +' '+data.dosage_form_text: null}
                                  {sch == 2 ? data.dosage_value_afternoon +' '+ data.dosage_form_text: null}
                                  {sch == 3 ? data.dosage_value_evening +' '+ data.dosage_form_text: null}
                                  {sch == 4 ? data.dosage_value_night +' '+ data.dosage_form_text: null}
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  {sch == 1 ? this.state.constants.drugMorningInstructions.map((data1, i) => data1.id == data.instruction_morning ? data1.description : null) : null}
                                  {sch == 2 ? this.state.constants.drugAfternoonInstructions.map((data1, i) => data1.id == data.instruction_afternoon ? data1.description : null) : null}
                                  {sch == 3 ? this.state.constants.drugEveningInstructions.map((data1, i) => data1.id == data.instruction_evening ? data1.description : null) : null}
                                  {sch == 4 ? this.state.constants.drugNightInstructions.map((data1, i) => data1.id == data.instruction_night ? data1.description : null) : null}
                                </td>
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.number_of_days ? data.number_of_days +" "+ data?.drug_duration_time : '-'}</td>

                                {(this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.state.eventBased == true ? 
                                <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.event !== null ? this.state.constants.drugEvent.map((data1, i) => data1.id === data.event ? data1.description : null) : ''} {data.event == 1 || data.event == 2 ? data.lmp_event_duration : null} {data.event == 1 || data.event == 2 ? data.lmp_event_duration_type : null}</td>
                                : null}
                                {
                                  this.state.viewType != 'template' && this.state.viewType != 'list-visit'
                                ?
                                  <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                    {
                                      data?.event == 4
                                    ?
                                      <>
                                        {
                                          moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                        ?
                                          '-'
                                        : 
                                          <>
                                            <div className="form-group hasdata">
                                              <DatePicker
                                                id="protocol_start_date"
                                                name="protocol_start_date"
                                                data-drug={data.drug_id}
                                                minDate={moment().toDate()}
                                                defaultPickerValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                defaultValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                                value={moment(protocol_start_date).format('D MMM, YYYY')}
                                                className="form-control datetimepicker"
                                                onChange={(e) => { this.updateStartDate(e, data.drug_id) }}
                                              />
                                              <label htmlFor="key_finding_date_time">Custom Date</label>
                                            </div>
                                          </>
                                        }
                                      </>
                                    :
                                      <>
                                        {
                                          moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                        ?
                                          '-'
                                        : 
                                          <>
                                            {moment(data.start_date).format('D MMM, YYYY')}
                                            <span style={{fontSize: "12px"}}>
                                              {
                                                show_original_due == true
                                              ?
                                                <>
                                                  <br/>
                                                  (Due from {moment(data.start_date_protocol).format('D MMM, YYYY')})
                                                </>
                                              :
                                                null
                                              }
                                            </span>
                                          </>
                                        }
                                      </>
                                    }
                                  </td>
                                :
                                  null
                                }

                                {/* <td rowSpan={data?.schedules?.length} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.start_date ? data.start_date : '-'}</td> */}
                                {this.props.showAction ?
                                  <td rowSpan={data?.schedules?.length} >
                                    {
                                      this.state.viewType != 'template' && this.state.viewType != 'soap-full' ?
                                        <a className="nostyle-link mr-2" onClick={e => this.handleEditDelete(e, 'showMedicineModal', data, 'editMedicine', i)}><i className="icon-edit"></i></a>
                                        :
                                        <a className="nostyle-link mr-2" onClick={e => this.props.handleEdit(data, i)}><i className="icon-edit"></i></a>
                                    }
                                    <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', '', 'deleteMedicine', deleteIndex)}><i className="icon_delete"></i></a>
                                    {this.props.showSelectCheck ?
                                      <span className="select-diagnosis select-check">
                                        <i data-checkSelect={i} data-type={'drugs'} onClick={(e) => this.props.updateSelection(i, 'drugsData')}
                                          className={data.selected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                                      </span>
                                      : null}
                                  </td>
                                  : null}
                              </tr>
                              {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                                <tr>
                                  <td colspan="11" className="text-center py-2"><span
                                    className="text-muted">Remarks</span>&nbsp;{medicine_remarks}</td>
                                </tr>
                                : null}
                              {drugRowSpan === mederowCount && correction_dose == 'Y' ?
                                <>
                                  <tr>
                                    <td colspan="11" className="py-2">
                                      <span className="text-primary">Correction Dose for {data.drug_name}</span>
                                      <table className="table" style={{width: "auto"}}>
                                        <thead>
                                          <tr>
                                            <th width="200">Blood Sugar (mg/dL)</th>
                                            {data?.correction_dose_data?.[0] ? <th width="120">{data?.correction_dose_data?.[0]?.range_min} - {data?.correction_dose_data?.[0]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[1] ? <th width="120">{data?.correction_dose_data?.[1]?.range_min} - {data?.correction_dose_data?.[1]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[2] ? <th width="120">{data?.correction_dose_data?.[2]?.range_min} - {data?.correction_dose_data?.[2]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[3] ? <th width="120">{data?.correction_dose_data?.[3]?.range_min} - {data?.correction_dose_data?.[3]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[4] ? <th width="120">{data?.correction_dose_data?.[4]?.range_min} - {data?.correction_dose_data?.[4]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[5] ? <th width="120">{data?.correction_dose_data?.[5]?.range_min} - {data?.correction_dose_data?.[5]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[6] ? <th width="120">{data?.correction_dose_data?.[6]?.range_min} - {data?.correction_dose_data?.[6]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[7] ? <th width="120">{data?.correction_dose_data?.[7]?.range_min} - {data?.correction_dose_data?.[7]?.range_max}</th> : null }
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Insulin (Units)</td>
                                            {data?.correction_dose_data?.[0] ? <td>{data?.correction_dose_data?.[0]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[1] ? <td>{data?.correction_dose_data?.[1]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[2] ? <td>{data?.correction_dose_data?.[2]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[3] ? <td>{data?.correction_dose_data?.[3]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[4] ? <td>{data?.correction_dose_data?.[4]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[5] ? <td>{data?.correction_dose_data?.[5]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[6] ? <td>{data?.correction_dose_data?.[6]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[7] ? <td>{data?.correction_dose_data?.[7]?.range_unit}</td> : null }
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                                : null}
                            </>
                          )
                          mederowCount = mederowCount + 1;
                        }
                        else{
                          htmlData.push(
                            <>
                              <tr key={i}>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  {sch == 1 ? 'Morning': null}
                                  {sch == 2 ? 'Afternoon': null}
                                  {sch == 3 ? 'Evening': null}
                                  {sch == 4 ? 'Night': null}
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  {sch == 1 ? data.dosage_value_morning +' '+data.dosage_form_text: null}
                                  {sch == 2 ? data.dosage_value_afternoon +' '+ data.dosage_form_text: null}
                                  {sch == 3 ? data.dosage_value_evening +' '+ data.dosage_form_text: null}
                                  {sch == 4 ? data.dosage_value_night +' '+ data.dosage_form_text: null}
                                </td>
                                <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                  {sch == 1 ? this.state.constants.drugMorningInstructions.map((data1, i) => data1.id == data.instruction_morning ? data1.description : null) : null}
                                  {sch == 2 ? this.state.constants.drugAfternoonInstructions.map((data1, i) => data1.id == data.instruction_afternoon ? data1.description : null) : null}
                                  {sch == 3 ? this.state.constants.drugEveningInstructions.map((data1, i) => data1.id == data.instruction_evening ? data1.description : null) : null}
                                  {sch == 4 ? this.state.constants.drugNightInstructions.map((data1, i) => data1.id == data.instruction_night ? data1.description : null) : null}
                                </td>
                              </tr>
                              {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                                <tr>
                                  <td colspan="11" className="text-center py-2"><span
                                    className="text-muted">Remarks</span>&nbsp;{medicine_remarks}</td>
                                </tr>
                                : null}
                              {drugRowSpan === mederowCount && correction_dose == 'Y' ?
                                <>
                                  <tr>
                                    <td colspan="11" className="py-2">
                                      <span className="text-primary">Correction Dose for {data.drug_name}</span>
                                      <table className="table" style={{width: "auto"}}>
                                        <thead>
                                          <tr>
                                            <th width="200">Blood Sugar (mg/dL)</th>
                                            {data?.correction_dose_data?.[0] ? <th width="120">{data?.correction_dose_data?.[0]?.range_min} - {data?.correction_dose_data?.[0]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[1] ? <th width="120">{data?.correction_dose_data?.[1]?.range_min} - {data?.correction_dose_data?.[1]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[2] ? <th width="120">{data?.correction_dose_data?.[2]?.range_min} - {data?.correction_dose_data?.[2]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[3] ? <th width="120">{data?.correction_dose_data?.[3]?.range_min} - {data?.correction_dose_data?.[3]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[4] ? <th width="120">{data?.correction_dose_data?.[4]?.range_min} - {data?.correction_dose_data?.[4]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[5] ? <th width="120">{data?.correction_dose_data?.[5]?.range_min} - {data?.correction_dose_data?.[5]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[6] ? <th width="120">{data?.correction_dose_data?.[6]?.range_min} - {data?.correction_dose_data?.[6]?.range_max}</th> : null }
                                            {data?.correction_dose_data?.[7] ? <th width="120">{data?.correction_dose_data?.[7]?.range_min} - {data?.correction_dose_data?.[7]?.range_max}</th> : null }
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Insulin (Units)</td>
                                            {data?.correction_dose_data?.[0] ? <td>{data?.correction_dose_data?.[0]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[1] ? <td>{data?.correction_dose_data?.[1]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[2] ? <td>{data?.correction_dose_data?.[2]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[3] ? <td>{data?.correction_dose_data?.[3]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[4] ? <td>{data?.correction_dose_data?.[4]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[5] ? <td>{data?.correction_dose_data?.[5]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[6] ? <td>{data?.correction_dose_data?.[6]?.range_unit}</td> : null }
                                            {data?.correction_dose_data?.[7] ? <td>{data?.correction_dose_data?.[7]?.range_unit}</td> : null }
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </>
                                : null}
                            </>
                          )
                          mederowCount = mederowCount + 1;
                        }
                        counter++;
                      }
                    }
                    else {
                      data.drug_duration_time = (data.daily_frequency == 8 || data.daily_frequency == 10)? 'Months' : (data.daily_frequency == 9 || data.daily_frequency == 11)? 'Weeks' : 'Days';
                      htmlData.push(
                        <>
                          <tr key={i} style={{backgroundColor: this.state.viewType == 'copy-to-visit' && this.state.eventBased == true && this.state.patientInfo.gender == 'Female' && LS_SERVICE.get('isObsGynaeDoctor') == 1 && LS_SERVICE.get('isObsGynaePatient') == true && custom_bgcolor ? custom_bgcolor : null}}>
                            {drugRowSpan > 1 && !medexists ? <td rowSpan={drugRowSpan} className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.drug_name}</td> : null}
                            {drugRowSpan === 1 && !medexists ? <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.drug_name}</td> : null}
                            {/* {this.state.viewType != 'copy-from-prescription' ? (
                              <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.strength?data.strength:'-'}</td>
                            ) : null} */}
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.route != null? this.state.constants.route.map((data1, i) => data1.id == data.route ? data1.description : null): '-'}</td>
                            {this.state.viewType != 'copy-from-prescription' ? (
                              <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.urgency !== null ? this.state.constants.drugUrgency.map((data1, i) => data1.id === data.urgency ? data1.description : null) : '-'}</td>
                            ) : null}
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.days_in_week_text && data.drug_frequency && parseInt(data.drug_frequency) == 2 ? data.days_in_week_text : (data.drug_frequency == 1 ? this.state.drugDailyFrequency[data.daily_frequency]?this.state.drugDailyFrequency[data.daily_frequency]:'-' : drugFreqText)}</td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.schedule_name != null && data.schedule_name ? data.schedule_name : '-'}</td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                              {
                                data.dosage && data.dosage < 17
                              ?
                                this.state.constants.drugDosage.map(
                                  function(data1, i){
                                    if(data.dosage_form_text === null){
                                      data.dosage_form_text = '';
                                    }
                                    
                                    if(parseInt(data1.id) == parseInt(data.dosage)){
                                      return data1.description + ' ' + data.dosage_form_text;
                                    }else{
                                      return null;
                                    }
                                  })
                              :
                                (data.dosage_value != undefined)? data.dosage_value + ' ' + (data.dosage_form_text)?? data.dosage_form_text : '-'}</td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{this.state.constants.drugInstructions.map((data1, i) => data1.id == data.instruction ? data1.description : null)}</td>
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.number_of_days ? data.number_of_days +" "+  data?.drug_duration_time : '-'}</td>

                            {(this.state.viewType == 'template' || this.state.viewType == 'list-visit') && this.state.eventBased == true ? 
                            <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>{data.event !== null ? this.state.constants.drugEvent.map((data1, i) => data1.id === data.event ? data1.description : null) : ''} {data.event == 1 || data.event == 2 ? data.lmp_event_duration : null} {data.event == 1 || data.event == 2 ? data.lmp_event_duration_type : null}</td>
                            : null}
                            {
                              this.state.viewType != 'template' && this.state.viewType != 'list-visit'
                            ?
                              <td className={this.state.viewType === 'copy-to-visit' && !data.selected ? 'text-danger' : null}>
                                {
                                  data?.event == 4
                                ?
                                  <>
                                    {
                                      moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                    ?
                                      '-'
                                    : 
                                      <>
                                        <div className="form-group hasdata">
                                          <DatePicker
                                            id="protocol_start_date"
                                            name="protocol_start_date"
                                            data-drug={data.drug_id}
                                            minDate={moment().toDate()}
                                            defaultPickerValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                            defaultValue={moment(protocol_start_date).format('D MMM, YYYY')}
                                            value={moment(protocol_start_date).format('D MMM, YYYY')}
                                            className="form-control datetimepicker"
                                            onChange={(e) => { this.updateStartDate(e, data.drug_id) }}
                                          />
                                          <label htmlFor="key_finding_date_time">Custom Date</label>
                                        </div>
                                      </>
                                    }
                                  </>
                                :
                                  <>
                                    {
                                      moment(data.start_date).format('D MMM, YYYY') == 'Invalid date'
                                    ?
                                      '-'
                                    : 
                                      <>
                                        {moment(data.start_date).format('D MMM, YYYY')}
                                        <span style={{fontSize: "12px"}}>
                                          {
                                            show_original_due == true
                                          ?
                                            <>
                                              <br/>
                                              (Due from {moment(data.start_date_protocol).format('D MMM, YYYY')})
                                            </>
                                          :
                                            null
                                          }
                                        </span>
                                      </>
                                    }
                                  </>
                                }
                              </td>
                            :
                              null
                            }
                            {this.props.showAction ?
                              <td>
                                {
                                  this.state.viewType != 'template' && this.state.viewType != 'soap-full' ?
                                    <a className="nostyle-link mr-2" onClick={e => this.handleEditDelete(e, 'showMedicineModal', data, 'editMedicine', i)}><i className="icon-edit"></i></a>
                                    :
                                    <a className="nostyle-link mr-2" onClick={e => this.props.handleEdit(data, i)}><i className="icon-edit"></i></a>
                                }
                                <a className="nostyle-link mr-2" onClick={(e) => this.handleEditDelete(e, 'deleteConfirmModal', '', 'deleteMedicine', deleteIndex)}><i className="icon_delete"></i></a>
                                {this.props.showSelectCheck ?
                                  <span className="select-diagnosis select-check">
                                    <i data-checkSelect={i} data-type={'drugs'} onClick={(e) => this.props.updateSelection(i, 'drugsData')}
                                      className={data.selected ? "btn-true-sm accept" : "btn-true-sm"}></i>
                                  </span>
                                  : null}
                              </td>
                              : null}
                          </tr>
                          {drugRowSpan === mederowCount && medicine_remarks !== '' && medicine_remarks !== null ?
                            <tr>
                              <td colspan="11" className="text-center py-2"><span
                                className="text-muted">Remarks</span>&nbsp;{medicine_remarks}</td>
                            </tr>
                            : null}
                          {drugRowSpan === mederowCount && correction_dose == 'Y' ?
                            <>
                              <tr>
                                <td colspan="11" className="py-2">
                                  <span className="text-primary">Correction Dose for {data.drug_name}</span>
                                  <table className="table" style={{width: "auto"}}>
                                    <thead>
                                      <tr>
                                        <th width="200">Blood Sugar (mg/dL)</th>
                                        {data?.correction_dose_data?.[0] ? <th width="120">{data?.correction_dose_data?.[0]?.range_min} - {data?.correction_dose_data?.[0]?.range_max}</th> : null }
                                        {data?.correction_dose_data?.[1] ? <th width="120">{data?.correction_dose_data?.[1]?.range_min} - {data?.correction_dose_data?.[1]?.range_max}</th> : null }
                                        {data?.correction_dose_data?.[2] ? <th width="120">{data?.correction_dose_data?.[2]?.range_min} - {data?.correction_dose_data?.[2]?.range_max}</th> : null }
                                        {data?.correction_dose_data?.[3] ? <th width="120">{data?.correction_dose_data?.[3]?.range_min} - {data?.correction_dose_data?.[3]?.range_max}</th> : null }
                                        {data?.correction_dose_data?.[4] ? <th width="120">{data?.correction_dose_data?.[4]?.range_min} - {data?.correction_dose_data?.[4]?.range_max}</th> : null }
                                        {data?.correction_dose_data?.[5] ? <th width="120">{data?.correction_dose_data?.[5]?.range_min} - {data?.correction_dose_data?.[5]?.range_max}</th> : null }
                                        {data?.correction_dose_data?.[6] ? <th width="120">{data?.correction_dose_data?.[6]?.range_min} - {data?.correction_dose_data?.[6]?.range_max}</th> : null }
                                        {data?.correction_dose_data?.[7] ? <th width="120">{data?.correction_dose_data?.[7]?.range_min} - {data?.correction_dose_data?.[7]?.range_max}</th> : null }
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Insulin (Units)</td>
                                        {data?.correction_dose_data?.[0] ? <td>{data?.correction_dose_data?.[0]?.range_unit}</td> : null }
                                        {data?.correction_dose_data?.[1] ? <td>{data?.correction_dose_data?.[1]?.range_unit}</td> : null }
                                        {data?.correction_dose_data?.[2] ? <td>{data?.correction_dose_data?.[2]?.range_unit}</td> : null }
                                        {data?.correction_dose_data?.[3] ? <td>{data?.correction_dose_data?.[3]?.range_unit}</td> : null }
                                        {data?.correction_dose_data?.[4] ? <td>{data?.correction_dose_data?.[4]?.range_unit}</td> : null }
                                        {data?.correction_dose_data?.[5] ? <td>{data?.correction_dose_data?.[5]?.range_unit}</td> : null }
                                        {data?.correction_dose_data?.[6] ? <td>{data?.correction_dose_data?.[6]?.range_unit}</td> : null }
                                        {data?.correction_dose_data?.[7] ? <td>{data?.correction_dose_data?.[7]?.range_unit}</td> : null }
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                            : null}
                        </>
                      )
                      mederowCount = mederowCount + 1;
                    }
                    delete data.drug_duration_time;
                    return htmlData;
                  })}
                </tbody>
              </table>
            </div>
          </>
          : (null)
        }

      </Fragment>
    );
  }
}

export default MedicineTable;
