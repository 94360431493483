import { Subject } from 'rxjs';

const practice = new Subject();
export const practiceService = {
    init: param => practice.next(param),
    status: () => practice.asObservable()
};

const pqpractice = new Subject();
export const queuePracticeService = {
    init: param => pqpractice.next(param),
    status: () => pqpractice.asObservable()
};

const subject = new Subject();
export const messageService = {
    sendMessage: message => subject.next(message),
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable()
};

const call = new Subject();
export const callService = {
    init: param => call.next(param),
    status: () => call.asObservable()
};

const callDriver = new Subject();
export const callDriverService = {
    init: param => callDriver.next(param),
    status: () => callDriver.asObservable()
};

const speech = new Subject();
export const speechService = {
    init: param => speech.next(param),
    status: () => speech.asObservable()
};

const speechText = new Subject();
export const speechTextService = {
    init: param => speechText.next(param),
    status: () => speechText.asObservable()
};

const headerSubject = new Subject();
export const headerService$ = {
    showHeader: (data) => headerSubject.next(data),
    soapConfig: (data) => headerSubject.next(data),
    setHospital: (data) => headerSubject.next(data),
    status: () => headerSubject.asObservable()
};

const vonageExpandedScreenOpts = new Subject();
export const vonageExpandedScreenService = {
    init: param => vonageExpandedScreenOpts.next(param),
    status: () => vonageExpandedScreenOpts.asObservable()
};

const selectedVitalGroup = new Subject();
export const selectedVitalGroupService = {
    init: param => selectedVitalGroup.next(param),
    status: () => selectedVitalGroup.asObservable()
};

const gpaleDataSubject = new Subject();
export const gpaleDataService = {
    init: param => gpaleDataSubject.next(param),
    status: () => gpaleDataSubject.asObservable()
};

const lactatingSubject = new Subject();
export const lactatingService = {
    init: param => lactatingSubject.next(param),
    status: () => lactatingSubject.asObservable()
};

const fetusSubject = new Subject();
export const fetusService = {
    init: param => fetusSubject.next(param),
    status: () => fetusSubject.asObservable()
};

const vcOpdEnabledSubject = new Subject();
export const vcOpdEnabledSubjectService = {
    init: param => vcOpdEnabledSubject.next(param),
    status: () => vcOpdEnabledSubject.asObservable()
};

const lmpDataSubject = new Subject();
export const lmpDataService = {
    init: param => lmpDataSubject.next(param),
    status: () => lmpDataSubject.asObservable()
};

const hospitalModalPopup = new Subject();
export const hospitalModalService = {
    init: param => hospitalModalPopup.next(param),
    status: () => hospitalModalPopup.asObservable()
};