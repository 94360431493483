import React, { Component } from 'react';
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import API_SERVICE from '../../utils/apiService';
import {
    LOADER,
    HANDLEVALIDANTMESSAGES,
    LOADER_BLACK,
    REFERENCE_TYPE,
    LOADER_RED,
    PATIENT_DATA,
    VITALS_GRAPH,
    PDF_CONFIG_VITALS_GRAPH,
    PATIENT_VITALS_ALL,
    VISIT_COUNT,
    ISALLOWEDITSOAP,
    ALL_MILESTONES_CHILD,
    MILESTONES_CHILD_SAVE,
    MILESTONES_CHILD_COMPLETE,
    MILESTONES_CHILD_CONTINUE,
    MILESTONES_CHILD_LIST,
    TOGGLE_PRACTICE
} from '../../utils/constant';
import moment from 'moment';
import { Form, Modal, Input, Radio, Select, notification } from 'antd';
import { jQueryLabels, AntSelectLabels, AntClearLabels, AntDisabledLabels, FixedCta, onlynumbers } from '../../utils/jQueryLabels';
import AsideLeft from '../../components/aside/asideleft';
import SubHeader from '../../components/subheader/subheader';
import PaediatricGrowthChart from './paediatrics-growth-chart';
import { headerService$, practiceService, speechService, speechTextService } from '../../utils/rxjs-sharing';
import MyHcSpeechRecognition from '../speech-to-text/speech-recognition';
import SoapFlow from '../../utils/soap';

class PaediatricsDevGrowthComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'devmilestone',
      spin_loading: false,
      chart_data_available: false,
      chartData: {},
      constants: null,
      patient: null,
      patientUrlData: null,
      totalVisit: 0,
      isPracticing: false,
      isPracticingModal: false,
      allowEdit: true,
      age_correction: null,
      age_correction_unit: 'week',
      birth_weight: null,
      birth_weight_unit: 'kg',
      father_height: null,
      mother_height: null,
      target_height_min: null,
      target_height_max: null,
      printPrescription: false,
      chartType: '',
      chartAge: '',
      remarks: '',
      allMilestones: [],
      mileStonesChecked: [],
      savedMilestones: [],
      paediatric_chart_print: false,
      speech: {
        identifier: null,
        state: false
      }
    }
    this.conditionFormRef = React.createRef();
  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return true;
  }

  componentDidMount() {
    const { match: { params }, location: { pathname } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

    if (+isPracticingHospitalId === +hospital_id) {
        this.setState({
            isPracticing: true
        })
    }

    const patientUrlData = {
        hospital_id,
        patient_id,
        qms_token_id: reference_id
    }

    let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
    hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;
    if (!pathname.includes('objective')){
      allowEdit = false;
    }
    
    this.setState({
        sourceOfRerral:this.props?.location?.state?.sourceOfRerral,
        hospital_id,
        patient_id,
        reference_id,
        patientUrlData,
        allowEdit,
        pathname
    }, async () => {
        if (LS_SERVICE.has('hospitalConfigData')) {
            const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
            let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
            headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
            this.handleVisitCount();
            await this.getPatientData();
            this.setSoapFlow();
            await this.handleVitalGraphData();
            await this.getPrintPresciptionData();
            this.getMilestonesPatientSaved();
            jQueryLabels();
            AntSelectLabels();
            FixedCta();
            onlynumbers();
        }
    });
    this.subscription = speechTextService.status().subscribe(data => {
      if (this.state.speech.identifier === 'remarks' && this.state.speech.state) {
        this.setState({ remarks: data.text });
      }
      this.conditionFormRef.current.setFieldsValue({
        remarks: data.text
      });
    });
  }
  
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  async setSoapFlow() {
    const { hospital_id } = this.state;
    SoapFlow.hospital(hospital_id)

    const paediatric_chart_print = SoapFlow.printElement('paediatric', 'child_development');

    this.setState({
      paediatric_chart_print
    });
  }

  getPatientData = async _ => {
    const { match: { params } } = this.props;
    const { reference_id } = params;
    await Axios.get(PATIENT_DATA({ reference_id }))
        .then(success => {
            const patient = {
                ...success.data.data.results,
                qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id
            }
            this.setState({
                patient
            }, async () => {
                if(this.state.patient.age_correction && this.state.patient.age_correction_unit){
                  this.setState({
                    age_correction: String(this.state.patient.age_correction),
                    age_correction_unit: this.state.patient.age_correction_unit,
                  });
                  this.conditionFormRef.current.setFieldsValue({
                    age_correction: String(this.state.patient.age_correction)
                  });
                }

                if(this.state.patient.birth_weight && this.state.patient.birth_weight_unit){
                  this.setState({
                    birth_weight: String(this.state.patient.birth_weight),
                    birth_weight_unit: this.state.patient.birth_weight_unit,
                  });
                  this.conditionFormRef.current.setFieldsValue({
                    birth_weight: String(this.state.patient.birth_weight)
                  });
                }

                if(this.state.patient.mother_height){
                  this.setState({
                    mother_height: String(this.state.patient.mother_height),
                  });
                  this.conditionFormRef.current.setFieldsValue({
                    mother_height: String(this.state.patient.mother_height)
                  });
                }

                if(this.state.patient.father_height){
                  this.setState({
                    father_height: String(this.state.patient.father_height),
                  });
                  this.conditionFormRef.current.setFieldsValue({
                    father_height: String(this.state.patient.father_height)
                  });
                }

                if(this.state.patient.target_height_min){
                  this.setState({
                    target_height_min: String(this.state.patient.target_height_min),
                  });
                  this.conditionFormRef.current.setFieldsValue({
                    target_height_min: String(this.state.patient.target_height_min)
                  });
                }

                if(this.state.patient.target_height_max){
                  this.setState({
                    target_height_max: String(this.state.patient.target_height_max),
                  });
                  this.conditionFormRef.current.setFieldsValue({
                    target_height_max: String(this.state.patient.target_height_max)
                  });
                }

                if(this.state.patient.growth_remarks){
                  this.setState({
                    remarks: String(this.state.patient.growth_remarks),
                  });
                  this.conditionFormRef.current.setFieldsValue({
                    remarks: String(this.state.patient.growth_remarks)
                  });
                }

                let actual_age = this.state.patient.monthsage;
                if(actual_age == 0){
                  let actual_age_days = Math.floor(moment(new Date()).diff(new Date(this.state.patient.dob), 'days', true));
                  actual_age = actual_age_days + (actual_age_days == 1 ? ' Day' : ' Days');
                } else if(actual_age < 12){
                  actual_age = actual_age + (actual_age > 0 ? (actual_age == 1 ? ' Month' : ' Months') : ' Month');
                } else {
                  let actual_age_years = Math.floor(actual_age / 12);
                  let actual_age_months = actual_age % 12;
                  actual_age = actual_age_years + (actual_age_years == 1 ? ' Yr' : ' Yrs') + (actual_age_months > 0 ? (' ' + actual_age_months + (actual_age_months == 1 ? ' Month' : ' Months')) : '');
                }

                let corrected_age = this.state.patient.cmonthsage >= 0 ? this.state.patient.cmonthsage : undefined;
                if(corrected_age != undefined){
                  if(corrected_age == 0){
                    let corrected_age_days = Math.floor(moment(new Date()).diff(new Date(this.state.patient.cdob), 'days', true));
                    corrected_age = corrected_age_days + (corrected_age_days == 1 ? ' Day' : ' Days');
                  } else if(corrected_age < 12){
                    corrected_age = corrected_age + (corrected_age > 0 ? (corrected_age == 1 ? ' Month' : ' Months') : ' Month');
                  } else {
                    let corrected_age_years = Math.floor(corrected_age / 12);
                    let corrected_age_months = corrected_age % 12;
                    corrected_age = corrected_age_years + (corrected_age_years == 1 ? ' Yr' : ' Yrs') + (corrected_age_months > 0 ? (' ' + corrected_age_months + (corrected_age_months == 1 ? ' Month' : ' Months')) : '');
                  }
                }
                await this.setState({
                  actual_age,
                  corrected_age
                })

                if(this.state.patient.cmonthsage != undefined && this.state.patient.cmonthsage < 24 && this.state.patient.cmonthsage >= 0){
                  await this.setState(prevState => {
                    let prevItems = {...prevState.patient};
                    prevItems.monthsage = prevItems.cmonthsage;
                    return {patient: prevItems};
                  });    
                }
            })
        })
        await this.getAllMilestonesChild();
  }

  getPrintPresciptionData = async () => {
    const { qms_token_id } = this.state.patientUrlData;
    const getPrintChartDataPromise = await Axios.get(PDF_CONFIG_VITALS_GRAPH({ reference_type: REFERENCE_TYPE, reference_id: qms_token_id }));

    if (getPrintChartDataPromise.data.status) {
      const { data } = getPrintChartDataPromise;
      this.setState({
        printPrescription: data.data.details !== null ? ((data.data.details.growthchart_age !== null && data.data.details.growthchart_type !== null) ? true : false) : false
      })
    }
  }

  handleVitalGraphData = async _ => {
    const { patient_id } = this.state.patientUrlData;

    const vitalGraphPromise = await Axios.get(PATIENT_VITALS_ALL({ patient_id }))

    if (vitalGraphPromise.data.status) {
        const { data } = vitalGraphPromise;
        this.setState({
            chartData: data.data.details,
            constants: data.data.constants,
            chart_data_available: true
        })
    }
  }

  getAllMilestonesChild = async () => {
    Axios.get(ALL_MILESTONES_CHILD).then(success => {
      if (success?.data?.status == true) {
        this.setState({
          allMilestones: success.data.data
        }, () => {})
      }
    }).catch(err => console.log(err))
  }

  getMilestonesPatientSaved = async () => {
    const { patient } = this.state;
    const { patient_id } = patient;
    const PARAMS = {
      patient_id
    }
    
    await Axios.post(MILESTONES_CHILD_LIST, PARAMS)
    .then(async success => {
      if (success?.data?.status == true) {
        await this.setState({
          savedMilestones: success.data.data
        });
        let milestones = [];
        success.data.data.forEach(async (ele, i) => {
          if(ele.milestone_categories.length > 0){
            ele.milestone_categories.forEach((el, j) => {
              let obj = {age_months: ele.ageMonths, category_id : el.categoryId};
              milestones.push(obj);
            });
            await this.setState({
              mileStonesChecked: milestones
            });
          }
        });
      }
    }).catch(err => console.log(err))
  }

  handleVisitCount = async () => {
    const { patient_id } = this.state.patientUrlData;
    const visitDetailsPromise = await Axios.post(VISIT_COUNT, { patient_id: patient_id });
    if (visitDetailsPromise && visitDetailsPromise.data.visit) {
      this.setState({ totalVisit: visitDetailsPromise.data.visit.count });
    }
  }
  
  handleOnCheckBoxChange = async (e, data) => {
    let findex = this.state.mileStonesChecked.findIndex(x => x.category_id === data.id);

    if (findex === -1){
      await this.setState(prevState => {
        let obj = {
          category_id: data.id,
          age_months: data.age_months
        };
        const newItems = [...prevState.mileStonesChecked, obj];
        return {mileStonesChecked: newItems}
      });
    } else {
      await this.setState(prevState => ({
        mileStonesChecked: prevState.mileStonesChecked.filter((d, i) => i !== findex)
      }));
    }
  }

  printCheckBoxChange = (value) => {
    this.setState({
      printPrescription: !value
    })
  }

  setChartTypeAgeToPrint = (type, age) => {
    this.setState({
      chartType: type,
      chartAge: age
    })
  }

  handleSave = async (e, age) => {
    let oneMileStoneChecked = false;
    let mileStones = [];
    this.state.mileStonesChecked.forEach((ele, i) => {
      if(ele.age_months == age){
        oneMileStoneChecked = true;
      }
      let findex = mileStones.findIndex(x => x.age_months === ele.age_months);
      if(findex === -1){
        let obj = {age_months: ele.age_months, categories: [ele.category_id]}
        mileStones.push(obj);
      } else {
        mileStones[findex].categories.push(ele.category_id);
      }
    });
    this.state.allMilestones.forEach((ele, i) => {
      let findex = mileStones.findIndex(x => x.age_months === ele.age_months);
      if(findex === -1){
        let obj = {age_months: ele.age_months, categories: []}
        mileStones.push(obj);
      }
    });

    if(!oneMileStoneChecked){
      notification.error({
        message: 'Select at least one milestone for this age category.',
        placement: 'topRight'
      });
    } else {
      const { patient, spin_loading, isPracticing } = this.state;
      if (!isPracticing) {
        this.handleModalPopup(null, 'isPracticingModal');
        return;
      }
      const { patient_id, qms_token_id } = patient;
      const PARAMS = {
        milestones_data: mileStones,
        reference_type: REFERENCE_TYPE,
        reference_id: qms_token_id,
        patient_id
      }

      this.setState({
        spin_loading: !spin_loading,
        clicked: 'save'
      })

      await Axios.post(MILESTONES_CHILD_SAVE, PARAMS)
      .then(success => {
        if (success?.data?.status == true) {
          notification.success({
            message: 'Milestones saved successfully',
            placement: 'topRight'
          });
          this.getMilestonesPatientSaved();
          const { spin_loading } = this.state;
          this.setState({
            spin_loading: !spin_loading
          })
        } else {
          notification.error({
            message: success?.data?.message,
            placement: 'topRight'
          });
        }
      }).catch(err => console.log(err))
    }
  }
  
  handleComplete = async (e, age) => {
    const { patient, spin_loading, isPracticing } = this.state;
    if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    }
    const { patient_id } = patient;
    const PARAMS = {
      age_months: age,
      patient_id
    }

    this.setState({
      spin_loading: !spin_loading,
      clicked: 'complete'
    })

    await Axios.post(MILESTONES_CHILD_COMPLETE, PARAMS)
    .then(success => {
      if (success?.data?.status == true) {
        notification.success({
          message: 'Milestones completed successfully',
          placement: 'topRight'
        });
        this.getMilestonesPatientSaved();
        const { spin_loading } = this.state;
        this.setState({
          spin_loading: !spin_loading
        })
      } else {
        notification.error({
          message: success?.data?.message,
          placement: 'topRight'
        });
      }
    }).catch(err => console.log(err))
  }

  handleModalPopup = (e, popup) => {
    if (e !== null)
      e.preventDefault();

    this.setState({
      [popup]: !this.state[popup]
    })
  }

  enablePractice = async e => {
    e.preventDefault();
    practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.hospital_id,
      practice_session: 'start'
    });

    let is_practicing;
    if (practice.data && practice.data.is_practicing === 'Y') {
      is_practicing = true;
      LS_SERVICE.set('practicing', {
        status: true,
        hospital_id: this.state.hospital_id
      });
    } else {
      is_practicing = false;
    }
    this.setState({
        isPracticing: is_practicing,
    }, () => {
        this.handleModalPopup(null, 'isPracticingModal');
    });
  }

  renderClass = (data) => {
    let findex = this.state.savedMilestones.findIndex(x => x.ageMonths === data.age_months);
    if(findex === -1){
      if (this.state.patient.monthsage <= data.age_months) {
        return 'grey';
      } else {
        return 'red';
      }
    } else {
      if (this.state.savedMilestones[findex].status == 'complete' || data.milestones_count == this.state.savedMilestones[findex].milestone_categories.length) {
        return 'green';
      } else if (this.state.savedMilestones[findex].status == 'partial' && this.state.savedMilestones[findex].milestone_categories.length > 0) {
        return 'yellow';
      } else {
        if (this.state.patient.monthsage <= data.age_months) {
          return 'grey';
        } else {
          return 'red';
        }
      }
    }
  }

  renderDisabled = (data) => {
    if(!this.state.allowEdit){
      return true;
    } else {
      let findexparent = this.state.savedMilestones.findIndex(x => x.ageMonths === data.age_months)
      if(findexparent === -1){
        return false;
      } else {
        if(this.state.savedMilestones[findexparent].milestone_categories.length > 0){
          let findex = this.state.savedMilestones[findexparent].milestone_categories.findIndex(y => y.categoryId === data.id);
          if(findex === -1){
            return false;
          } else {
            if(this.state.savedMilestones[findexparent].milestone_categories[findex].referenceId != this.state.patient.qms_token_id){
              return true;
            } else {
              return false;
            }
          }
        } else {
          return false;
        }
      }
    }
  }

  renderCompleteButton = (data) => {
    let classname = 'btn btn-outline-primary px-4 mr-3';
    let findex = this.state.savedMilestones.findIndex(x => x.ageMonths === data.age_months);
    let text = 'COMPLETE';
    let disabled = !this.state.allowEdit || this.state.spin_loading;
    let tick = '';
    if(findex !== -1){
      if (this.state.savedMilestones[findex].status == 'complete'){
        classname = 'btn btn-outline-success px-4 mr-3';
        text = 'COMPLETED';
        disabled = true;
        tick = <span className="float-left check-mark mr-2" style={{height: '0.95rem', width: '0.4rem'}}></span>;
      }
    }

    return <button type="button" onClick={e => this.handleComplete(e, data.age_months)} className={classname} disabled={disabled} >{tick}{text}{this.state.spin_loading && this.state.clicked == 'complete' ? LOADER : ''}</button>
  }

  //for edit input value
  async handleChange(event, field){
    
    /* if(field && field == 'volume_per_unit') {
      const { value } = event.target;
      const reg = /^\d+$/;
      if ((isNaN(value) || !reg.test(value))) {
        event.target.value = '';
      }
    } */

    field ? this.setState({
      [field] : event.target.value  
      }) : this.setState({ 
        [event.target.name] : event.target.value 
      });
      if(field && field == 'father_height') {
        if(this.state.mother_height){
          let target_height;
          let target_height_min;
          let target_height_max;
          if(!isNaN(event.target.value) && event.target.value){
            target_height = (+event.target.value + +this.state.mother_height)/2;
            target_height_min = (target_height - 6.5).toFixed(2);
            target_height_max = (target_height + 6.5).toFixed(2);
          } else {
            target_height_min = null;
            target_height_max = null;
          }
          await this.setState({
            target_height_min: target_height_min,
            target_height_max: target_height_max
          });
          if(this.conditionFormRef.current){
            this.conditionFormRef.current.setFieldsValue({
              target_height_min: target_height_min,
              target_height_max: target_height_max
            });
          }
        }
      }
      if(field && field == 'mother_height') {
        if(this.state.father_height){
          let target_height;
          let target_height_min;
          let target_height_max;
          if(!isNaN(event.target.value) && event.target.value){
            target_height = (+event.target.value + +this.state.father_height)/2;
            target_height_min = (target_height - 6.5).toFixed(2);
            target_height_max = (target_height + 6.5).toFixed(2);
          } else {
            target_height_min = null;
            target_height_max = null;
          }
          await this.setState({
            target_height_min: target_height_min,
            target_height_max: target_height_max
          });
          if(this.conditionFormRef.current){
            this.conditionFormRef.current.setFieldsValue({
              target_height_min: target_height_min,
              target_height_max: target_height_max
            });
          }
        }
      }
  }

  handleSubmit = async (values) => {
    const { patient, spin_loading, isPracticing } = this.state;
    if (!isPracticing) {
      this.handleModalPopup(null, 'isPracticingModal');
      return;
    }
    const { patient_id, qms_token_id } = patient;
    const PARAMS = {
      patient_id,
      reference_id: qms_token_id,
      reference_type: REFERENCE_TYPE
    }
    if(this.state.age_correction){
      PARAMS.age_correction = parseInt(this.state.age_correction);
      PARAMS.age_correction_unit = this.state.age_correction_unit;
    }
    if(this.state.birth_weight){
      PARAMS.birth_weight = parseFloat(this.state.birth_weight);
      PARAMS.birth_weight_unit = this.state.birth_weight_unit;
    }

    if(this.state.currentTab == 'growthchart'){

      if(this.state.father_height && patient.monthsage > 60){
        PARAMS.father_height = parseFloat(this.state.father_height);
      } else {
        PARAMS.father_height = null;
      }
      if(this.state.mother_height && patient.monthsage > 60){
        PARAMS.mother_height = parseFloat(this.state.mother_height);
      } else {
        PARAMS.mother_height = null;
      }
      if(this.state.target_height_min && patient.monthsage > 60){
        PARAMS.target_height_min = parseFloat(this.state.target_height_min);
      } else {
        PARAMS.target_height_min = null;
      }
      if(this.state.target_height_max && patient.monthsage > 60){
        PARAMS.target_height_max = parseFloat(this.state.target_height_max);
      } else {
        PARAMS.target_height_max = null;
      }
      if(this.state.printPrescription && this.state.paediatric_chart_print){
        PARAMS.print_prescription = 'yes';
        PARAMS.chart_type = this.state.chartType;
        PARAMS.chart_age = this.state.chartAge;
      } else {
        PARAMS.print_prescription = 'no';
        PARAMS.chart_type = null;
        PARAMS.chart_age = null;
      }
      if(this.state.remarks){
        PARAMS.remarks = this.state.remarks;
      } else {
        PARAMS.remarks = null;
      }
    } else {
      let mileStones = [];
      this.state.mileStonesChecked.forEach((ele, i) => {
        let findex = mileStones.findIndex(x => x.age_months === ele.age_months);
        if(findex === -1){
          let obj = {age_months: ele.age_months, categories: [ele.category_id]}
          mileStones.push(obj);
        } else {
          mileStones[findex].categories.push(ele.category_id);
        }
      });
      this.state.allMilestones.forEach((ele, i) => {
        let findex = mileStones.findIndex(x => x.age_months === ele.age_months);
        if(findex === -1){
          let obj = {age_months: ele.age_months, categories: []}
          mileStones.push(obj);
        }
      });
      
      PARAMS.milestones_data = mileStones;
      await this.setState({
        clicked: 'continue',
        chart_data_available: false
      });
    }

    this.setState({
      spin_loading: !spin_loading,
      allMilestones: []
    });

    await Axios.post(MILESTONES_CHILD_CONTINUE, PARAMS)
    .then(async success => {
      if (success?.data?.status == true) {
        /* notification.success({
          message: 'Milestones completed successfully',
          placement: 'topRight'
        }); */
        await this.getPatientData();
        const { spin_loading } = this.state;
        this.setState({
          spin_loading: !spin_loading
        });
        //this.handleTab('growthchart');
        if(this.state.currentTab != 'growthchart'){
          await this.setState({
            chart_data_available: true
          });
          this.getMilestonesPatientSaved();
          this.setState({
            currentTab: 'growthchart'
          });
        } else {
          this.handlePaediatricContinue();
        }
      } else {
        notification.error({
          message: success?.data?.message,
          placement: 'topRight'
        });
      }
    }).catch(err => console.log(err))
  }

  handleContinue = () => {
    this.setState({
      currentTab: 'growthchart'
    });
  }

  handlePaediatricContinue = () => {
    const { patient, patientUrlData, sourceOfRerral } = this.state;
    const { patient_id, qms_token_id, hospital_id } = patientUrlData;

    if (sourceOfRerral === 'viewPrescription') {
      this.props.history.push({
        pathname: `/patient/todays-visit/view-prescription/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
        state: { patient }
      })
    } else {
      const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
      const pageListArr = LS_SERVICE.get('goToSoapPatientPageList');
      let index = pageListArr.indexOf('child_development');
      let vaccChartIndex = pageListArr.indexOf('vaccination_chart');
      if(vaccChartIndex == -1){
          index++;
      } else {
          index = index + 2;
      }
      if (index < pageListArr.length && !isTodayVisitArr.includes(qms_token_id)) {
          let soapurl = LS_SERVICE.get('goToSoapPatientUrl')[index].url;
          soapurl = soapurl.replace("HOSPITALID", hospital_id);
          soapurl = soapurl.replace("REFID", qms_token_id);
          soapurl = soapurl.replace("REFTYPE", REFERENCE_TYPE);
          soapurl = soapurl.replace("PATID", patient_id);
          this.props.history.push({
              pathname: soapurl,
              state: { patient }
          })
      } else {
          this.props.history.push({
              pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${REFERENCE_TYPE}/${qms_token_id}`,
              state: { patient }
          })
      }
    }
  }  

  handleTab = (tab) => async (e)  => {
    e.preventDefault();
    let { currentTab } = this.state;

    currentTab = tab;

    await this.setState({
      currentTab
    });
  }

  initSpeech = async (info) => {
    let initialText = '';
    if (info.identifier === 'remarks') {
      initialText = this.state.remarks != undefined ? this.state.remarks : '';
    }

    if (info.identifier === this.state.speech.identifier) {
      this.setState({
        speech: {
          identifier: info.identifier,
          state: !this.state.speech.state,
          initialText: initialText
        }
      }, () => {
        speechService.init(this.state.speech);
      });
    } else {
      this.setState({
        speech: {
          identifier: this.state.speech.identifier,
          state: false,
          initialText: ''
        }
      }, () => {
        speechService.init(this.state.speech);
        setTimeout(() => {
          this.setState({
            speech: {
              identifier: info.identifier,
              state: true,
              initialText: initialText
            }
          }, () => {
            speechService.init(this.state.speech);
          });

        }, 1000);
      });
    }
  }

  render() {

    const { patient, allMilestones, mileStonesChecked, savedMilestones } = this.state;

    return (
      <>
        {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

        <MyHcSpeechRecognition />

        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row emrfrm">
              {patient != undefined && patient.patient_id != undefined && patient.patient_id != null && this.state.pathname.includes('objective') ?
                <SubHeader patient={patient} />
                : null}
              
              <div className="col-12">
                <h1 className="page-title">Child Development and Growth</h1>
              </div>
              <div className="col-lg-12">
                <ul className="nav nav-pills justify-content-center myhealthcare-tabs">
                  <li className="nav-item">
                      <a href="#developmental-milestone-tab" className={`nav-link ${this.state.currentTab === 'devmilestone' ? 'active' : ''}`} onClick={this.handleTab('devmilestone')} data-toggle="tab">Developmental Milestones</a>
                  </li>
                  <li className="nav-item">
                      <a href="#growth-charts-tab" className={`nav-link ${this.state.currentTab === 'growthchart' ? 'active' : ''}`} onClick={this.handleTab('growthchart')} data-toggle="tab">Growth Charts</a>
                  </li>
                </ul>
                <Form
                ref={this.conditionFormRef}
                validateMessages={HANDLEVALIDANTMESSAGES}
                onFinish={this.handleSubmit}
                layout="vertical" 
                className="emrfrm mt-4">
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <div className="row">
                        <div className="col-7 pr-0 ">
                            <div className="form-group input-group">
                            <div className={this.state.age_correction ? 'antInputItem hasdata' :'antInputItem'} >
                              <Form.Item name="age_correction" label="Age Correction" id="age_correction"
                                onChange={(e) => this.handleChange(e, 'age_correction')}
                                rules={[
                                  {pattern: /^\d+$/, message: 'Not a valid number!'},
                                  {max: 2, message: 'Max 2 digit.'}
                                ]}
                              >
                                <Input className="border-right-radius-0" disabled={!this.state.allowEdit} defaultValue={this.state.age_correction} />
                              </Form.Item>
                              </div>
                              </div>
                              </div>
                              <div className="col-5 pl-0">
                              <select disabled={!this.state.allowEdit} value={this.state.age_correction_unit} onChange={(e) => this.handleChange(e, 'age_correction_unit')} name="age_correction_unit" id="age_correction_unit" className="form-control bg-grey border-left-radius-0">
                                <option value='week'>Weeks</option>
                                <option value='month'>Months</option>
                              </select>
                            
                            
                          
                        </div>
                      </div>
                      
                      {/* <div className="form-group input-group">
                        <input type="text" className="form-control onlynumbers" id="age_correction" placeholder="Age Correction" />
                        <select className="input-group-addon">
                          <option>Weeks</option>
                          <option>Months</option>
                        </select>
                        <label htmlFor="age_correction">Age Correction</label>
                      </div> */}
                    </div>
                    {this.state.actual_age != undefined ? <div className="col-12 col-md-6"><div className="corrected_age">Actual Age : {this.state.actual_age} {this.state.corrected_age != undefined ? (<div style={{display: 'initial', float: 'right'}}>{" Corrected Age : "}<span>{this.state.corrected_age }</span></div>) : '' }</div></div> : ''}

                    {/* {this.state.actual_age != undefined ? (<div className="col-12 col-md-6 pt-2 text-dark"><b>Actual Age</b> {this.state.actual_age} {this.state.corrected_age != undefined ? (<span><b> | {"Corrected Age "}</b> {this.state.corrected_age}</span>) : ''} </div>) : ''} */}
                    <div className="col-12 col-md-3 ml-auto">
                      <div className="row">
                          <div className="col-8 pr-0">
                                <div className="form-group input-group">
                                <div className={this.state.birth_weight ? 'antInputItem hasdata' :'antInputItem'} >
                                  <Form.Item name="birth_weight" label="Birth Weight" id="birth_weight"
                                    onChange={(e) => this.handleChange(e, 'birth_weight')}
                                    rules={[
                                      /* {required: true, message: 'Please fill the required field.'}, */
                                      {pattern: /^\d{1,5}(\.\d{1,2})?$/, message: 'Not allowed!'},
                                      /* {max: 8, message: 'Max 8 chars.'} */
                                    ]}
                                  >
                                    <Input className="border-right-radius-0"  disabled={!this.state.allowEdit} defaultValue={this.state.birth_weight} />
                                  </Form.Item>
                                </div>
                                
                              </div>
                          </div>
                          <div className="col-4 pl-0">
                                  <select disabled={!this.state.allowEdit} value={this.state.birth_weight_unit} onChange={(e) => this.handleChange(e, 'birth_weight_unit')} name="birth_weight_unit" id="birth_weight_unit" className="input-group-addon form-control bg-grey border-left-radius-0">
                                    <option value='gm'>Gms</option>
                                    <option value='kg'>Kgs</option>
                                  </select>
                          </div>
                      </div>
                      
                      {/* <div className="form-group input-group">
                        <input type="text" className="form-control onlynumbers" id="birth_weight" placeholder="Birth Weight" />
                        <select className="input-group-addon">
                          <option>gm</option>
                          <option>kg</option>
                        </select>
                        <label htmlFor="birth_weight">Birth Weight</label>
                      </div> */}
                    </div>
                  </div>
                  <div className="tab-content">
                    <div style={{paddingBottom: '2%'}} className={`tab-pane fade ${this.state.currentTab === 'devmilestone' ? 'show active' : ''}`} id="developmental-milestone-tab" role="tabpanel">

                      {(!allMilestones || allMilestones.length === 0) ? <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>{LOADER_RED}</div> :
                      <>
                        <div className="milestone_tab_panel">
                          <ul className="milestone_legend">
                            <li className="current_age"><span></span> Current Age</li>
                            <li className="delayed_milestones"><span></span> Delayed Milestones</li>
                          </ul>
                          <ul className="nav nav-tabs ">
                            {allMilestones.map((data, i) => (
                              <li key={i}><a href={`#tab_${i + 1}`} data-toggle="tab" className={((patient.monthsage >= data.age_months) && (!allMilestones[i + 1] || (patient.monthsage < allMilestones[i + 1].age_months)) || (i == 0 && patient.monthsage < allMilestones[0].age_months)) ? 'active current_age' : ''}>{data.age_label}</a></li>
                            ))}
                          </ul>
                          <div className="tab-content">
                            {allMilestones.map((data, i) => (
                              <div key={i} id={`tab_${i + 1}`} className={((patient.monthsage >= data.age_months) && (!allMilestones[i + 1] || (patient.monthsage < allMilestones[i + 1].age_months)) || (i == 0 && patient.monthsage < allMilestones[0].age_months)) ? 'tab-pane active' : 'tab-pane'}>
                                <div className="row">
                                  {data.milestones.map((d, j) => (
                                    <div key={j} className={`col-6 ${j % 2 == 0 ? "pr-0" : "pl-0"}`}>
                                      <div className={`panel-body-block ${j % 2 == 0 ? "" : "pl-3"}`}>
                                        <strong>{d.category}:</strong>
                                        <ul>
                                          {d.categories.map((dt, k) => (
                                            <li key={k}>
                                              <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" className="custom-control-input" id={`age_${i}_${j}_${k}`}
                                                  name={`age_${i}_${j}_${k}`}
                                                  disabled={this.renderDisabled(dt)}
                                                  value={dt.id}
                                                  checked={mileStonesChecked.findIndex(x => x.category_id === dt.id) !== -1}
                                                  onChange={e => this.handleOnCheckBoxChange(e, dt)} />
                                                <label className="custom-control-label" htmlFor={`age_${i}_${j}_${k}`}>{dt.category}</label>
                                              </div>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                          </div>
                          
                        </div>
                        <span><strong> As per CDC guidelines.</strong></span>
                        <div className="row fixed-cta">
                          <div className="col-12 mt-3 mb-4 text-center">
                            {(this.state.pathname && this.state.pathname.includes('objective')) ? this.state.allowEdit ?
                              <button type="submit" className="btn btn-primary px-5" disabled={!this.state.allowEdit || this.state.spin_loading}>CONTINUE{this.state.spin_loading && this.state.clicked == 'continue' ? LOADER : ''}</button>
                              : <button type="button" className="btn btn-primary px-5" onClick={this.handleContinue}>CONTINUE</button> : null}
                          </div>
                        </div>
                        
                      </>}
                      {/* <div className="accordion development panel-group" id="accordion">
                        {(!allMilestones || allMilestones.length === 0) ? <div className={`w-100 align-items-center justify-content-center mt-4 d-flex`}>{LOADER_RED}</div> : 
                          <>
                          {allMilestones.map((data, i) => (
                            <div key={i} className={`panel ${this.renderClass(data)}`}>
                              <h4 className="panel-heading">
                                <a className={`d-block text-center ${(patient.monthsage >= data.age_months) && (!allMilestones[i+1] || (patient.monthsage < allMilestones[i+1].age_months)) ? '' : 'collapsed'}`} role="button" data-toggle="collapse" href={`#collapse_${i+1}`} aria-expanded={(patient.monthsage >= data.age_months) && (!allMilestones[i+1] || (patient.monthsage < allMilestones[i+1].age_months)) ? 'true' : 'false'} aria-controls={`collapse_${i+1}`}>
                                  <span>Age: {data.age_label}</span>
                                </a>
                              </h4>
                              
                                <div id={`collapse_${i+1}`} className={(patient.monthsage >= data.age_months) && (!allMilestones[i+1] || (patient.monthsage < allMilestones[i+1].age_months)) ? 'collapse in show' : 'collapse'} role="tabpanel" data-parent="#accordion" aria-labelledby={`collapse_${i+1}`}>
                                  <div className="panel-body">
                                    <div className="row">
                                    {data.milestones.map((d,j) => (
                                      <div key={j} className={`col-6 ${j%2 == 0 ? "pr-0" : "pl-0"}`}>
                                        <div className={`panel-body-block ${j%2 == 0 ? "" : "pl-3"}`}>
                                          <strong>{d.category}:</strong>
                                          <ul>
                                            {d.categories.map((dt, k) => (
                                              <li key={k}>
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                  <input type="checkbox" className="custom-control-input" id={`age_${i}_${j}_${k}`} 
                                                  name={`age_${i}_${j}_${k}`} 
                                                  disabled={this.renderDisabled(dt)}
                                                  value={dt.id} 
                                                  checked={mileStonesChecked.findIndex(x => x.category_id === dt.id) !== -1} 
                                                  onChange={e => this.handleOnCheckBoxChange(e, dt)} />
                                                  <label className="custom-control-label" htmlFor={`age_${i}_${j}_${k}`}>{dt.category}</label>
                                                </div>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    ))}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 mt-3 mb-2 text-right">
                                      {this.renderCompleteButton(data)}
                                      <button type="button" onClick={e => this.handleSave(e, data.age_months)} className="btn btn-primary px-4" disabled={!this.state.allowEdit || this.state.spin_loading}>SAVE{this.state.spin_loading && this.state.clicked == 'save' ? LOADER : ''}</button>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          ))}
                          <div className="row">
                            <div className="col-12 mt-3 mb-4 text-center">
                              {(this.state.pathname && this.state.pathname.includes('objective')) ? this.state.allowEdit ? 
                              <button type="submit" className="btn btn-primary px-5" disabled={!this.state.allowEdit || this.state.spin_loading}>CONTINUE{this.state.spin_loading && this.state.clicked == 'continue' ? LOADER : ''}</button> 
                              : <button type="button" className="btn btn-primary px-5" onClick={this.handleContinue}>CONTINUE</button> : null }
                            </div>
                          </div>
                          </>
                        }
                      </div> */}
                      
                    </div>
                   
                    <div className={`tab-pane fade ${this.state.currentTab === 'growthchart' ? 'show active' : ''}`} id="growth-charts-tab" role="tabpanel">
                        {this.state.chart_data_available && <PaediatricGrowthChart patient={this.state.patient} patientUrlData={this.state.patientUrlData} chartData={this.state.chartData} constants={this.state.constants} allowEdit={this.state.allowEdit} father_height={this.state.father_height} mother_height={this.state.mother_height} target_height_min={this.state.target_height_min} target_height_max={this.state.target_height_max} remarks={this.state.remarks} printPrescription={this.state.printPrescription} printCheckBoxChange={this.printCheckBoxChange} setChartTypeAgeToPrint={this.setChartTypeAgeToPrint} parentHandleChange={(e, field) => this.handleChange(e, field)} spin_loading={this.state.spin_loading} handlePaediatricContinue={this.handlePaediatricContinue} pathname={this.state.pathname} paediatric_chart_print={this.state.paediatric_chart_print} initSpeech={(info) => this.initSpeech(info)} speech={this.state.speech} />}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        {/** || isPracticing Modal */}
        <Modal
            title={false}
            closable={false}
            visible={this.state.isPracticingModal}
            footer={false}
        >
            <div className="row">
                <div className="col-12 my-3 text-center">
                    <h6>Do you want to Start Practice?</h6>
                </div>
            </div>
            <div className="modal-footer justify-content-center">
                <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
                <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
            </div>
        </Modal>
      </>
    )
  }

}

export default PaediatricsDevGrowthComponent;